import fetcher from '~/utils/fetcher';
import config from '~/config';

function service () {
  const endpoint = `${config.API_VERSION}/broadcasts-quota`;

  return {
    /**
     * Get quota stat
     */
    get () {
      return fetcher.get(endpoint);
    },
  };
}

export default {};
export const BroadcastsQuotaService = service();
