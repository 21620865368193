import fetcher from '~/utils/fetcher';
import config from '~/config';

// role service
function service () {
  const endpoint = 'roles';
  const { API_VERSION } = config;

  return {
    /**
     * Find roles
     *
     * @param {any} params
     */
    find (params) {
      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },
  };
}

export default {};
export const RoleService = service();
