import _ from 'lodash';
import permissions from '~/config/permissions';
import config from '~/config';

export const joinPublic = (path) => `${config.PUBLIC_URL}${path}`;

const getApiPrefix = (url) => {
  // console.log('getApiPrefix', url)
  if (_.includes([ 'auth' ], url)) {
    return `${process.env.REACT_APP_SERVICE}`;
  }
  return `${process.env.REACT_APP_SERVICE}/${process.env.REACT_APP_API}`;
};

export const fetchJson = (url, options = {}) => fetch(url, options)
  .then((response) => response.json())
  .catch((error) => error);

export const fetchApi = (url, method = 'GET', data = {}, headers = {}) => {
  const options = {
    method,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      ...headers,
    },
  };
  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    if (headers !== false) {
      options.headers = {
        'Content-Type': 'application/json',
        ...options.headers,
      };
    }
    options.body = data;
  }
  return fetchJson(
    `${getApiPrefix(url)}/${url}`,
    options,
  );
};

export const toObject = (items) => _.keyBy(items, 'id');

export const sortCollection = (items) => _.orderBy(items, [ 'created_at' ], [ 'desc' ]);

export const stripHtml = (html) => {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

export const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel = true) => {
  // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

  let CSV = '';
  // Set Report title in first row or line

  // CSV += ReportTitle + "\r\n\n";

  // This condition will generate the Label/Header
  if (ShowLabel) {
    let row = '';

    // This loop will extract the label from 1st index of on array
    for (const index in arrData[0]) {
      // Now convert each value to string and comma-seprated
      row += `${index},`;
    }

    row = row.slice(0, -1);

    // append Label row with line break
    CSV += `${row}\r\n`;
  }

  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = '';

    // 2nd loop will extract each column and convert it in string comma-seprated
    for (const index in arrData[i]) {
      row += `"${_.get(arrData, `${i}.${index}`, '')}",`;
    }

    row.slice(0, row.length - 1);

    // add a line break after each row
    CSV += `${row}\r\n`;
  }

  if (CSV === '') {
    alert('Invalid data');
    return;
  }

  // Generate a file name
  // this will remove the blank-spaces from the title and replace it with an underscore
  const fileName = ReportTitle.replace(/ /g, '_');
  // Initialize file format you want csv or xls
  const uri = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(
    CSV,
  )}`;

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  // this trick will generate a temp <a /> tag
  const link = document.createElement('a');
  link.href = uri;

  // set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden';
  link.download = `${fileName}.csv`;

  // this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getOnlyKeyPermissions = () => {
  const result = [];
  const recursive = (permissions) => {
    _.map(permissions, (permission) => {
      result.push(permission.key);
      if (permission.children) recursive(permission.children);
    });
    return result;
  };
  recursive(permissions);
  return result;
};

export const authHasPermission = (authPermissions, permissions = getOnlyKeyPermissions()) => {
  if (_.isEmpty(authPermissions)) return false;

  // claim for super admin
  if (authPermissions.includes('*')) return true;

  return authPermissions.some((permission) => permissions.includes(permission));
};

export const convertSelectedFiles = (value) => (_.isArray(value)
  ? _.isObject(_.get(value, '0'))
    ? _.map(value, (v) => _.get(v, 'id'))
    : value
  : value);

export const convertData = (oldValue, values, formItems, languages) => {
  const convetedData = _.reduce(
    values,
    (result, value, key) => {
      const formItem = _.find(formItems, { field: key });
      if (formItem) {
        const { multiLanguage, inputType } = formItem;
        if (inputType === 'repeat') {
          return {
            ...result,
            [key]: _.compact(
              _.map(value, (v, i) => {
                if (!v) return null;
                return convertData(
                  _.get(oldValue, `${key}.${i}`),
                  v,
                  formItem.formItems,
                  languages,
                );
              }),
            ),
          };
        }
        if (multiLanguage === true) {
          return {
            ...result,
            [key]: {
              ..._.get(oldValue, key),
              [languages.active]: value,
            },
          };
        }
      }
      return {
        ...result,
        [key]: value,
      };
    },
    {},
  );
  return convetedData;
};

export const translateData = (data, t, formItems) => _.reduce(
  data,
  (result, value, key) => {
    const formItem = _.find(formItems, { field: key });
    if (formItem) {
      const { multiLanguage, inputType } = formItem;
      if (inputType === 'repeat') {
        return {
          ...result,
          [key]: _.map(value, (v, i) => translateData(
            _.get(data, `${key}.${i}`),
            t,
            formItem.formItems,
          )),
        };
      }
      if (multiLanguage) {
        return {
          ...result,
          [key]: t(value),
        };
      }
    }
    return {
      ...result,
      [key]: value,
    };
  },
  {},
);

export const mapCategoriesLevel = (d) => {
  const findChildren = (d, v) => {
    const options = {
      ...v,
      title: _.get(v, 'name'),
      value: _.get(v, 'id'),
      key: _.get(v, 'id'),
    };
    const parent = _.filter(d, { parent_id: _.get(v, 'id') });
    if (!_.isEmpty(parent)) { options.children = _.map(_.sortBy(parent, [ 'sort' ]), (p) => findChildren(d, p)); }
    return options;
  };
  const firstLevel = _.filter(d, { parent_id: 0 });
  return _.map(_.sortBy(firstLevel, [ 'sort' ]), (v) => findChildren(d, v));
};
