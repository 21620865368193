const {
  REACT_APP_PROJECT_ID,
  PUBLIC_URL,
  REACT_APP_CHAT_SERVICE,
  REACT_APP_CHANNEL_ID,
  REACT_APP_ONE_ON_ONE_CHAT_LINK,
} = process.env;

const projects = {
  terminal21: {
    favicon: `${PUBLIC_URL}/assets/favicon/${REACT_APP_PROJECT_ID}/favicon.ico`,
    logo: `${PUBLIC_URL}/assets/logo/${REACT_APP_PROJECT_ID}/logo.png`,
    title: 'Terminal 21',
  },
  fashionisland: {
    favicon: `${PUBLIC_URL}/assets/favicon/${REACT_APP_PROJECT_ID}/favicon.ico`,
    logo: `${PUBLIC_URL}/assets/logo/${REACT_APP_PROJECT_ID}/logo.png`,
    title: 'Fashion Island x The Promenade',
  },
};

/**
 * Get env
 *
 * @param {string} target
 * @param {string} defaultValue
 * @return {string}
 */
function get (target, { defaultValue, prefix } = {}) {
  return process.env[`${prefix ?? 'REACT_APP_'}${target}`] ?? defaultValue ?? '';
}

export default {
  LOGO_URL: `${PUBLIC_URL}/assets/logo/${REACT_APP_PROJECT_ID}/logo.png`,
  ONE_ON_ONE_CHAT_LINK: REACT_APP_ONE_ON_ONE_CHAT_LINK,
  CHANNEL_ID: REACT_APP_CHANNEL_ID,
  CHAT_SERVICE: REACT_APP_CHAT_SERVICE,
  HELMET_CONFIG: projects[REACT_APP_PROJECT_ID],
  PUBLIC_URL,
  SERVICE: get('SERVICE'),
  API_VERSION: get('API'),
  PROJECT_ICON: get('PROJECT_ICON'),
  PROJECT_NAME: get('PROJECT_NAME'),
  STAT_SERVICE: get('STAT_SERVICE'),
};
