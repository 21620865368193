import * as ActionType from './actionType';
import { getMediasApi } from '../services/medias';

const getMedias = () => (dispatch) => dispatchMedias(dispatch);

const dispatchMedias = (dispatch) => getMediasApi().then((data) => {
  dispatch({
    type: ActionType.RECEIVE_MEDIAS,
    data,
  });
  return data;
});

export { getMedias, dispatchMedias };
