/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '~/config/Layout';
import { PrivateRoute } from '~/components';
import { Login } from '~/pages';
import { AuthAction } from '~/actions/auth';

/**
 * Router
 *
 * @param {object} props
 * @param {Array<any>} props.routes
 * @return {JSX.Element}
 */
function Router ({ routes }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    const { check } = AuthAction;

    dispatch(check(token));
  }, []);

  if (!auth.checked) return <div>Loading...</div>;
  if (!auth.isLogin) return <Login />;

  return (
    <BrowserRouter>
      <Route
        render={({ location }) => (
          <Switch location={location}>
            {routes.map((route) => {
              const R = route.privateRoute ? PrivateRoute : Route;

              return (
                <R
                  {...route}
                  key={route.path}
                  component={(props) => <Layout {...route} {...props} />}
                />
              );
            })}
          </Switch>
        )}
      />
    </BrowserRouter>
  );
}

Router.defaultProps = {};
Router.propTypes = { routes: PropTypes.arrayOf(PropTypes.shape()).isRequired };

export default Router;
