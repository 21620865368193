import React from 'react';
import { Layout, Menu, Button, Switch, Row, Col, Icon } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Logo, LanguageSwitcher } from '../components';
import { withRedux } from '../hoc';
import { AuthAction } from '~/actions/auth';
import { authHasPermission } from '../utils';
import menus from './menus';

import packageJson from '../../package.json';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const THEME = 'dark';

const mapStateToProps = (state) => ({
  auth: _.get(state, 'auth.data'),
});

const actionToProps = { logout: AuthAction.signout };

class MyLayout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      theme: window.localStorage.getItem('theme') || THEME,
    };
  }

  renderMenus = () => {
    const { auth } = this.props;
    const getMenu = (menus) => _.compact(
      _.map(menus, (menu) => {
        const { key, title, link, icon, children, target, permissions } = menu;
        if (!authHasPermission(_.get(auth, 'claim'), permissions)) return null;
        if (children) {
          return (
            <SubMenu
              key={key}
              title={(
                <span>
                  {
                    target !== undefined ? (
                      <a href={link} target={target}>
                        {icon && <Icon type={icon} />}
                        <span className="nav-text">{title}</span>
                      </a>
                    ) : (
                      <Link to={link} target={target}>
                        {icon && <Icon type={icon} />}
                        <span className="nav-text">{title}</span>
                      </Link>
                    )
                  }
                </span>
                )}
            >
              {getMenu(children)}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={key}>
            {
              target !== undefined ? (
                <a href={link} target={target}>
                  {icon && <Icon type={icon} />}
                  <span className="nav-text">{title}</span>
                </a>
              ) : (
                <Link to={link} target={target}>
                  {icon && <Icon type={icon} />}
                  <span className="nav-text">{title}</span>
                </Link>
              )
            }
          </Menu.Item>
        );
      }),
    );
    return getMenu(menus);
  };

  render () {
    const { theme } = this.state;
    const { subMenuActiveKey, menuActiveKey, component: Component, auth } = this.props;

    return (
      <Layout>
        <Sider
          theme={theme}
          style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }}
        >
          <Menu
            theme={theme}
            mode="inline"
            selectable={false}
            style={{ flex: 0 }}
          >
            <Menu.ItemGroup key="group-1">
              <div
                className="logo-block"
                style={{ height: 60, overflow: 'hidden' }}
              >
                <Logo />
              </div>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="group-2" title={`Profile`}>
              <Menu.Item key="profile">
                <div
                  className="user-block"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className="username"
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {auth.account.username}
                  </div>
                  <Button onClick={() => this.props.actions.logout()}>
                    Logout
                  </Button>
                </div>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
          <Menu
            theme={theme}
            mode="inline"
            defaultOpenKeys={subMenuActiveKey}
            defaultSelectedKeys={menuActiveKey}
            style={{ flex: 1 }}
          >
            <Menu.ItemGroup key="group-1" title="Menu">
              {this.renderMenus()}
            </Menu.ItemGroup>
          </Menu>
          <Menu
            theme={theme}
            mode="inline"
            selectable={false}
            style={{ flex: 0 }}
          >
            <Menu.Divider />
            <Menu.Item key="theme">
              <Row type="flex" justify="space-between">
                <Col>Theme: </Col>
                <Col>
                  <Switch
                    checked={this.state.theme === 'dark'}
                    onChange={(value) => {
                      const theme = value ? 'dark' : 'light';
                      this.setState({ theme });
                      window.localStorage.setItem('theme', theme);
                    }}
                    checkedChildren="Dark"
                    unCheckedChildren="Light"
                  />
                </Col>
              </Row>
            </Menu.Item>
            <Menu.Item key="meta">
              {`${packageJson.name} v${packageJson.version}`}
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: 200 }}>
          <Content style={{ backgroundColor: '#ffffff' }}>
            <Component {...this.props} />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(MyLayout);
