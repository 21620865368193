import React, { Fragment, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Divider,
  Button,
  Form,
  Card,
  Popover,
  Popconfirm,
  Upload,
  Input,
  Icon,
  Spin,
  notification,
} from 'antd';

import config from '~/config';

import { PageTitle } from '~/components';
import { GreetingMessagesService } from '~/services/greeting-messages';

import * as ResponseMessage from '../lib/response-message-parser';
import { InitResponseMessageGroups, ResponseMessageReducer } from '../lib/local-reducer';
import * as LocalAction from '../lib/local-action-types';

import { SupportMediaType, PreviewMediaMessageModal } from '../components';
import LinkMessage from '../../../components/card-message/components/link-message'

import { TierAction } from '~/actions/tier';

import './style.less';

const constructImageMapMessage = () => ({
  type: 'imagemap',
  baseUrl: '',
  altText: '',
  baseSize: {
    width: 0,
    height: 0,
  },
  actions: [
    {
      type: 'uri',
      linkUri: '',
      area: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    },
  ],
});

const messageFactory = ({ id, uuid, order, tierId, type, data }) => {
  let meta = { type };
  if (type === 'text') {
    meta.text = data.text;
  } else if (type === 'image' || type === 'video') {
    meta.originalContentUrl = data.originalContentUrl;
    meta.previewImageUrl = data.previewImageUrl;
  } else if (type === 'imagemap') {
    meta = data;
  }
  return {
    id,
    uuid,
    sequence: order,
    tierId: Number(tierId),
    meta,
  };
};

const getTierById = (tiers, tierId) => tiers.find((tier) => `${tier.id}` === `${tierId}`) || { title: { en: 'Loading...' } };
/**
 * Create and update rich menu
 *
 * @return {JSX.Element}
 */
function SaveGreetingMessages ({ form }) {
  const [ previewVisible, setPreviewVisible ] = useState();
  const [ uploading, setUploading ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ deleteMessageIds, setDeleteMessageIds ] = useState([]);

  const [ responseMessageGroups, dispatch ] = useReducer(ResponseMessageReducer, InitResponseMessageGroups);

  const storeDispatch = useDispatch();
  const { tierId } = useParams();
  const history = useHistory();
  const { tiers = [], error, loading } = useSelector((state) => state.service);
  const tier = getTierById(tiers, tierId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    let notify = { message: '', description: '' };

    const firstKey = Object.keys(responseMessageGroups)[0];
    const firstGroup = responseMessageGroups[firstKey];

    const newMessages = [];
    const existingMessages = [];

    let messageSequence = _.random(10000);
    _.forEach(firstGroup, (message) => {
      if (!_.includes(deleteMessageIds, message.id)) {
        if (message.id) {
          existingMessages.push(messageFactory({
            ...message,
            order: messageSequence,
            tierId: Number(tierId),
          }));
        } else {
          newMessages.push(messageFactory({
            ...message,
            order: messageSequence,
            tierId: Number(tierId),
          }));
        }
        messageSequence += 1;
      }
    });

    try {
      // remove deleted messages
      if (deleteMessageIds.length > 0) {
        /* eslint-disable */
        for (let i = 0; i < deleteMessageIds.length; i += 1) {
          await GreetingMessagesService.update(deleteMessageIds[i], {
            sequence: _.random(10000000),
          });
          await GreetingMessagesService.remove(deleteMessageIds[i]);
        }
      }
      if (newMessages.length > 0) {
        await GreetingMessagesService.create(newMessages);
      }
      /* eslint-disable */
      for (let i = 0; i < existingMessages.length; i += 1) {
        const existingMessage = existingMessages[i];
        // const targetMessage = _.find(firstGroup, item => item.uuid === existingMessage.uuid)
        // console.log('existingMessage:targetMessage', targetMessage)
        await GreetingMessagesService.update(existingMessage.id, {
          sequence: existingMessage.sequence,
          meta: existingMessage.meta,
        });
      }
      /* eslint-enable */
      notify = {
        message: 'Update successfully',
        description: 'Your new greeting message was update successfully.',
      };
      notification.success(notify);
      history.push('/greeting-messages');
      /* eslint-enable */
    } catch (err) {
      console.error(err);
      notify = {
        message: 'Something went wrong.',
        description: 'Please contact your support.',
      };
      notification.error(notify);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (error) notification.error({ message: 'Oops! something went wrong.', description: error });
  }, [ error ]);

  useEffect(() => {
    const init = async () => {
      storeDispatch(TierAction.find());
      const { data } = await GreetingMessagesService.find({ $populate: 'tier', tierId });
      if (data.length > 0) {
        dispatch({
          type: LocalAction.INIT_GROUP,
          payload: { items: _.sortBy(data, 'sequence') },
          meta: {
            tierId,
          },
        });
      }
    };
    if (tierId) {
      init();
    }
  }, []);

  // use with 'image' and 'video' message
  const handleOnMediaMessageChange = (info, key, groupKey) => {
    const { file } = info;

    if (file.status === 'uploading') setUploading(true);
    if (file.status === 'done') {
      const link = file.response[0].src;

      setUploading(false);
      dispatch({
        type: LocalAction.ON_CHANGE,
        payload: { originalContentUrl: link, previewImageUrl: link },
        meta: { key, groupKey },
      });
    }
  };

  // support 'text' message type
  const renderTextMessage = (message, key, groupKey) => (
    <React.Fragment>
      <Input.TextArea
        rows={4}
        value={message.data.text}
        placeholder="Enter text"
        style={{ resize: 'none' }}
        onChange={(e) => dispatch({
          type: LocalAction.ON_CHANGE,
          payload: { text: e.target.value },
          meta: { key, groupKey },
        })}
      />
      <br />
      <br />
      <div>
        <Button
          type="primary"
          onClick={() => dispatch({
            type: LocalAction.ADD_USER_DISPLAY_NAME,
            meta: { key, groupKey },
          })}
        >
          {'User\'s display name'}
        </Button>
      </div>
    </React.Fragment>
  );

  // support 'image' and 'video' message type
  const renderMediaMessage = (ans, key, groupKey) => {
    const { type, data } = ans;
    const { previewImageUrl } = data;

    const defaultFileList = [];
    if (previewImageUrl) defaultFileList.push({ uid: '1', name: 'image.png', status: 'done', url: previewImageUrl });

    return (
      <React.Fragment>
        <Upload
          name="file"
          key={`file-${key}`}
          accept={`${type}/*`}
          listType="picture-card"
          defaultFileList={defaultFileList}
          headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
          action={`${config.SERVICE}/${config.API_VERSION}/medias`}
          onPreview={() => setPreviewVisible(key)}
          onChange={(info) => handleOnMediaMessageChange(info, key, groupKey)}
          onRemove={() => dispatch({
            type: LocalAction.ON_CHANGE,
            payload: { originalContentUrl: '', previewImageUrl: '' },
            meta: { key, groupKey },
          })}
        >
          {_.isEmpty(previewImageUrl) && (
            <Fragment>
              <Icon type={uploading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">{uploading ? 'Uploading...' : 'Upload'}</div>
            </Fragment>
          )}
        </Upload>

        {!_.isEmpty(previewImageUrl) && (
          <PreviewMediaMessageModal
            src={previewImageUrl}
            type={type}
            visible={previewVisible === key}
            onClose={() => setPreviewVisible(undefined)}
          />
        )}

        {type === 'image' && <SupportMediaType format="JPG, JPEG, PNG" size="1 MB" width="472px" />}
        {type === 'video' && <SupportMediaType format="MP4" size="10 MB" />}
      </React.Fragment>
    );
  };

  /**
   * on media message removed handler
   *
   * @return {void}
   */
  const handleOnImageMapLinkChanged = (value, linkUri, key, groupKey) => {
    dispatch({
      type: LocalAction.ON_CHANGE,
      payload: {
        ...value,
        altText: linkUri,
        actions: [
          {
            type: 'uri',
            linkUri,
            area: value.actions[0].area,
          },
        ],
      },
      meta: { key, groupKey },
    });
  };

  /**
   * on media message removed handler
   *
   * @return {void}
   */
  const handleOnImageMapMediaChanged = (value, { file }, key, groupKey) => {
    switch (file.status) {
      case 'done': {
        const link = file.response[0].src;
        if (link) {
          const img = new Image();
          img.onload = () => {
            const { width, height } = img;
            dispatch({
              type: LocalAction.ON_CHANGE,
              payload: {
                ...value,
                baseUrl: `${link}?_ignored=`,
                baseSize: {
                  width,
                  height,
                },
                actions: [
                  {
                    type: 'uri',
                    linkUri: value.actions[0].linkUri,
                    area: {
                      x: 0,
                      y: 0,
                      width,
                      height,
                    },
                  },
                ],
              },
              meta: { key, groupKey },
            });
          };
          img.src = link;
        }
        return '';
      }
      default:
        // do not thing
        break;
    }
  };

  // support 'imagemap' message type
  const renderImageMapMessage = (ans, key, groupKey) => {
    const { type, data } = ans;
    const { baseUrl } = data;

    const defaultFileList = [];
    if (baseUrl) defaultFileList.push({ uid: '1', name: 'image.png', status: 'done', url: baseUrl });

    return (
      <React.Fragment>
        <Upload
          name="file"
          key={`file-${key}`}
          accept={`${type}/*`}
          listType="picture-card"
          defaultFileList={defaultFileList}
          headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
          action={`${config.SERVICE}/${config.API_VERSION}/medias`}
          onPreview={() => setPreviewVisible(key)}
          onChange={(info) => handleOnImageMapMediaChanged(data, info, key, groupKey)}
          onRemove={() => dispatch({
            type: LocalAction.ON_CHANGE,
            payload: {
              ...data,
              baseUrl: '',
              altText: '',
              baseSize: {
                width: 0,
                height: 0,
              },
              actions: [],
            },
            meta: { key, groupKey },
          })}
        >
          {_.isEmpty(baseUrl) && (
            <Fragment>
              <Icon type={uploading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">{uploading ? 'Uploading...' : 'Upload'}</div>
            </Fragment>
          )}
        </Upload>
        {!_.isEmpty(baseUrl) && (
          <PreviewMediaMessageModal
            src={baseUrl}
            type={type}
            visible={previewVisible === key}
            onClose={() => setPreviewVisible(undefined)}
          />
        )}
        <SupportMediaType
          format="JPG, JPEG, PNG"
          width="1040px"
          size="10 MB"
        />
        <br />
        <LinkMessage
          value={data.actions[0].linkUri}
          onChange={(linkUri) => handleOnImageMapLinkChanged(data, linkUri, key, groupKey)}
        />
      </React.Fragment>
    );
  };

  const renderResponseMessage = (message, key, groupKey) => {
    const { id, type } = message;
    const keys = Object.keys(responseMessageGroups[groupKey]);

    const oneMessageLeft = keys.length === 1;
    const firstOfList = keys.indexOf(key) === 0;
    const lastOfList = keys.indexOf(key) === keys.length - 1;

    return (
      <Form.Item key={key}>
        <Card
          type="inner"
          extra={(
            <Button.Group>
              <Popover content="Move Up">
                <Button
                  icon="up"
                  type="link"
                  disabled={oneMessageLeft || firstOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'up' } })}
                />
              </Popover>
              <Popover content="Move Down">
                <Button
                  icon="down"
                  type="link"
                  disabled={oneMessageLeft || lastOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'down' } })}
                />
              </Popover>
              <Popconfirm
                placement="top"
                title="Delete this message?"
                onConfirm={() => {
                  if (id) setDeleteMessageIds([ ...deleteMessageIds, id ]);
                  dispatch({ type: LocalAction.REMOVE_MESSAGE, meta: { key, groupKey } })
                }}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneMessageLeft}
              >
                <Button disabled={oneMessageLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
          title={(
            <Button.Group>
              <Popover content="Text">
                <Button
                  disabled={type !== 'text' && !ResponseMessage.isEmpty(message)}
                  type={type === 'text' ? 'primary' : 'link'}
                  icon="message"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'text', data: { text: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Image">
                <Button
                  disabled={type !== 'image' && !ResponseMessage.isEmpty(message)}
                  type={type === 'image' ? 'primary' : 'link'}
                  icon="picture"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'image', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Image Map">
                <Button
                  disabled={type !== 'imagemap' && !ResponseMessage.isEmpty(message)}
                  type={type === 'imagemap' ? 'primary' : 'link'}
                  icon="file-image"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'imagemap', data: constructImageMapMessage() },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Video">
                <Button
                  disabled={type !== 'video' && !ResponseMessage.isEmpty(message)}
                  type={type === 'video' ? 'primary' : 'link'}
                  icon="video-camera"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'video', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
            </Button.Group>
          )}
        >
          {type === 'text' && renderTextMessage(message, key, groupKey)}
          {(type === 'image' || type === 'video') && renderMediaMessage(message, key, groupKey)}
          {(type === 'imagemap') && renderImageMapMessage(message, key, groupKey)}
        </Card>
      </Form.Item>
    );
  };

  const renderAnswerForm = ([ groupKey, ansGroup ]) => {
    // console.log('renderAnswerForm', groupKey, ansGroup);
    const entriesGroup = Object.entries(ansGroup);
    const oneGroupLeft = Object.keys(responseMessageGroups).length === 1;
    const childrenOfGroup1 = responseMessageGroups[Object.keys(responseMessageGroups)[0]];

    return (
      <Form.Item key={groupKey}>
        <Card
          loading={loading}
          title={`Response Message Group (${Object.keys(childrenOfGroup1).length})`}
          extra={(
            <Button.Group>
              <Popconfirm
                placement="top"
                title="Delete this response message group?"
                onConfirm={() => dispatch({ type: 'remove-group', meta: { groupKey } })}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneGroupLeft}
              >
                <Button disabled={oneGroupLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
        >
          {entriesGroup.map(([ key, message ]) => renderResponseMessage(message, key, groupKey))}
          {entriesGroup.length < 5 && (
            <Button
              type="dashed"
              size="large"
              block
              onClick={() => dispatch({ type: LocalAction.ADD_MESSAGE, meta: { groupKey } })}
            >
              Add More Message
            </Button>
          )}
        </Card>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={loading || submitting} wrapperClassName="save-rich-menu-page">
      <div className="page-title-container">
        <PageTitle
          title="Greeting Messages"
          extra={(
            <div className="button-group">
              <Button type="primary" key="save" htmlType="submit" form="form">Save</Button>
            </div>
          )}
        />
      </div>

      <div className="page-title-container">
        <h2>{`Tier: ${_.capitalize(tier.title.en)}`}</h2>
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <Form
          id="form"
          layout="horizontal"
          onSubmit={handleSubmit}
          colon={false}
          hideRequiredMark
        >
          {Object.entries(responseMessageGroups).map(renderAnswerForm)}
          <Divider />
        </Form>
      </div>
    </Spin>
  );
}

SaveGreetingMessages.propTypes = {
  form: PropTypes.shape().isRequired,
};

SaveGreetingMessages.defaultProps = {};

export default Form.create()(SaveGreetingMessages);
