import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { withRedux } from '../../hoc';

const mapStateToProps = (state) => ({
  isLogin: _.get(state, 'auth.isLogin'),
});

const actionToProps = {};

function PrivateRoute ({ component: Component, ...rest }) {
  const { isLogin } = rest;
  return (
    <Route
      {...rest}
      render={(props) => (isLogin ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      ))}
    />
  );
}

export default withRedux(mapStateToProps, actionToProps)(PrivateRoute);
