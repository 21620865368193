import { useEffect, useRef } from 'react';

/**
 * Use did update effect hooks
 *
 * @param {React.EffectCallback} effect
 * @param {React.DependencyList} deps
 * @return {void}
 */
function useDidUpdateEffect (effect, deps = []) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      effect();
    } else {
      didMount.current = true;
    }
  }, deps);
}

export default useDidUpdateEffect;
