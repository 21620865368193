import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import { Divider, Button, Table, DatePicker, Row, Col } from 'antd';

import { PageTitle } from '~/components';
import { JSONToCSVConvertor } from '~/utils';
import { usePreloadServices } from '~/hooks';
import dayjs from '~/utils/dayjs';

import './style.less';

const todayMoment = moment();

const defaultFilter = {
  start: moment('2020-10-01'),
  end: todayMoment,
};
const formatDateTime = 'YYYY-MM-DD HH:mm:ss';

/**
 * Rich menu list page
 *
 * @return {JSX.Element}
 */
function InsightSummary ({ preloadServices }) {
  const { isLoaded, responses } = usePreloadServices(preloadServices);
  const { insights = [], members = [] } = responses;
  const [ useData, setUsedata ] = useState([]);
  const [ filter, setFilter ] = useState(defaultFilter);

  const getMemberByLineId = (lineId) => {
    const member = _.find(members, (eachMember) => eachMember.line === lineId);
    return member;
  };

  // const groupBy = (list, keyGetter) => {
  //   const allList = [];
  //   list.forEach((item) => {
  //     const key = keyGetter(item);
  //     allList.push(key);
  //   });
  //   return _.uniq(allList);
  // };

  const groupByWithValue = (list, key, contentKey) => {
    let allList = {};
    const allData = [];
    const filters = _.groupBy(list, (eachList) => eachList[`${key}`]);
    const filterGroup = _.groupBy(filters[`${contentKey}`], (eachFilter) => eachFilter.content);
    const sizeOfContent = _.size(filters[`${contentKey}`]);
    _.forEach(filterGroup, (value, eachKey) => {
      allList = {
        ...allList,
        [`${eachKey}`]: value.length,
      };
      allData.push({
        name: contentKey,
        key: eachKey,
        dataCount: value.length,
      });
    });
    return {
      name: contentKey,
      countAll: sizeOfContent,
      content: allData,
    };
  };

  // 0: "richmenu_tracking"
  // 1: "event"
  // 2: "promotion"
  // 3: "service"
  // 4: "undefined"
  // 5: "thaichana"
  const listofCampaingRichMenu = groupByWithValue(useData, 'campaign', 'richmenu_tracking');
  const listofCampaingEvent = groupByWithValue(useData, 'campaign', 'event');
  const listofCampaingPromotion = groupByWithValue(useData, 'campaign', 'promotion');
  const listofCampaingService = groupByWithValue(useData, 'campaign', 'service');
  const listofCampaingThaichana = groupByWithValue(useData, 'campaign', 'thaichana');

  const getMemberAge = (member) => {
    if (!member || _.isEmpty(member.birthdate)) return 'Unknown';
    const nowMoment = moment();
    const memberBirthDateMoment = moment(member.birthdate, 'YYYY-MM-DD');
    const age = nowMoment.diff(memberBirthDateMoment, 'years'); // calculates patient's age in years
    return age;
  };

  /**
   * Column setting of antd table
   *
   * @constant
   */
  // const columns = [
  //   {
  //     title: 'User',
  //     dataIndex: 'subject',
  //     key: 'subject',
  //     render: (subject) => {
  //       let member = {};
  //       let link = '';
  //       if (subject) {
  //         member = getMemberByLineId(subject);
  //         if (member) {
  //           link = `members/save/${member.id}`;
  //         }
  //       }
  //       if (_.isEmpty(member)) return 'Anonymous';
  //       return (
  //         <Link to={link}>{`${member?.firstname} ${member?.lastname}`}</Link>
  //       );
  //     },
  //   },
  //   {
  //     title: 'Gender',
  //     dataIndex: 'subject',
  //     key: 'gender',
  //     render: (subject) => {
  //       const member = getMemberByLineId(subject);
  //       return member?.gender;
  //     },
  //   },
  //   {
  //     title: 'Age',
  //     dataIndex: 'subject',
  //     key: 'age',
  //     render: (subject) => {
  //       const member = getMemberByLineId(subject);
  //       return getMemberAge(member);
  //     },
  //   },
  //   {
  //     title: 'source',
  //     dataIndex: 'source',
  //     key: 'source',
  //   },
  //   {
  //     title: 'medium',
  //     dataIndex: 'medium',
  //     key: 'medium',
  //   },
  //   {
  //     title: 'campaign',
  //     dataIndex: 'campaign',
  //     key: 'campaign',
  //   },
  //   {
  //     title: 'content',
  //     dataIndex: 'content',
  //     key: 'content',
  //   },
  //   {
  //     title: 'Timestamp',
  //     dataIndex: 'createdAt',
  //     key: 'createdAt',
  //     render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm'),
  //   },
  // ];

  const columnRich = [
    {
      title: 'Branch',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Count',
      dataIndex: 'dataCount',
      key: 'dataCount',
    },
  ];

  // const handleExportCsv = () => {
  //   const dataToExcel = _.map(insights, (item) => {
  //     const { subject, createdAt } = item;
  //     const dataToExport = _.omit(item, [ '_id', '__v', 'subject', 'createdAt', 'updatedAt' ]);
  //     const member = getMemberByLineId(subject);
  //     return {
  //       ...dataToExport,
  //       fullname: `${member?.firstname} ${member?.lastname}`,
  //       gender: member?.gender,
  //       age: getMemberAge(member),
  //       timestamp: moment(createdAt).format('YYYY-MM-DD HH:mm'),
  //     };
  //   });
  //   JSONToCSVConvertor(dataToExcel, 'insights', true);
  // };

  const handleExportCsv = () => {
    const allData = [
      ...listofCampaingRichMenu.content,
      ...listofCampaingEvent.content,
      ...listofCampaingPromotion.content,
      ...listofCampaingService.content,
      ...listofCampaingThaichana.content,
    ];
    const dataToExcel = _.map(allData, (item) => {
      const { name, key, dataCount } = item;
      return {
        Menu: name,
        Branch: key,
        Count: dataCount,
      };
    });
    JSONToCSVConvertor(dataToExcel, 'CountData', true);
  };

  const sortByDescInsights = _.reverse(_.clone(insights));

  const getFilterByDateRange = () => {
    const { start, end } = filter;
    const filteredData = _.chain(sortByDescInsights)
      .filter((insight) => {
        const createdAt = dayjs(insight.createdAt);
        const isSameOrAfterStart = createdAt.isSameOrAfter(dayjs(start.startOf('day').format(formatDateTime)));
        const isSameOrBeforeEnd = createdAt.isSameOrBefore(dayjs(end.endOf('day').format(formatDateTime)));
        return isSameOrAfterStart && isSameOrBeforeEnd;
      }).groupBy((insight) => insight.medium).value();
    // console.log('filteredData', filteredData);
    return filteredData;
  };

  const handleClearSearch = () => {
    setFilter(defaultFilter);
  };

  const handleDatePickerChange = (key, date) => {
    setFilter({
      ...filter,
      [key]: date,
    });
  };

  const handleSearch = () => {
    const data = _.filter(useData, (eachData) => {
      const nowDate = moment(eachData.createdAt).format('YYYY-MM-DD');
      const startDate = moment(filter.start).format('YYYY-MM-DD');
      const endDate = moment(filter.end).format('YYYY-MM-DD');
      const inRange = moment(nowDate).isBetween(startDate, endDate);
      return inRange;
    });
    setUsedata(data);
  };

  useEffect(() => {
  }, []);

  useEffect(() => {
    setUsedata(insights);
  }, [ insights ]);

  const filteredInsights = getFilterByDateRange();

  return (
    <div className="rich-menu-page">
      <div className="page-title-container">
        <PageTitle
          title="Insights"
          extra={(
            <div>
              <Button type="primary" onClick={handleExportCsv}>Export CSV</Button>
            </div>
          )}
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-title-container">
        <Row type="flex" justify="end">
          <Col span={12} offset={12}>
            <div className="schedule-action">
              <DatePicker
                onChange={handleDatePickerChange.bind(this, 'start')}
                value={filter.start}
                style={{ marginRight: 10 }}
              />
              <div className="to">~</div>
              <DatePicker
                onChange={handleDatePickerChange.bind(this, 'end')}
                style={{ marginRight: 10 }}
                value={filter.end}
              />
              <Button onClick={handleSearch} type="primary" icon="search" />
              <Button onClick={handleClearSearch} className="cls-button" type="secondary">Clear</Button>
            </div>
          </Col>
        </Row>
      </div>

      <div className="pd-20">
        <div>{`Total Rich Menu : ${listofCampaingRichMenu.countAll}`}</div>
        <br />
        <Table
          dataSource={listofCampaingRichMenu.content}
          columns={columnRich}
          loading={!isLoaded}
          showHeader
          bordered
          rowKey={(item) => item._id} // eslint-disable-line
        />
      </div>
      <div className="pd-20">
        <div>{`Total Event : ${listofCampaingEvent.countAll}`}</div>
        <br />
        <Table
          dataSource={listofCampaingEvent.content}
          columns={columnRich}
          loading={!isLoaded}
          showHeader
          bordered
          rowKey={(item) => item._id} // eslint-disable-line
        />
      </div>
      <div className="pd-20">
        <div>{`Total Promotion : ${listofCampaingPromotion.countAll}`}</div>
        <br />
        <Table
          dataSource={listofCampaingPromotion.content}
          columns={columnRich}
          loading={!isLoaded}
          showHeader
          bordered
          rowKey={(item) => item._id} // eslint-disable-line
        />
      </div>
      <div className="pd-20">
        <div>{`Total Service : ${listofCampaingService.countAll}`}</div>
        <br />
        <Table
          dataSource={listofCampaingService.content}
          columns={columnRich}
          loading={!isLoaded}
          showHeader
          bordered
          rowKey={(item) => item._id} // eslint-disable-line
        />
      </div>
      <div className="pd-20">
        <div>{`Total Thaichana : ${listofCampaingThaichana.countAll}`}</div>
        <br />
        <Table
          dataSource={listofCampaingThaichana.content}
          columns={columnRich}
          loading={!isLoaded}
          showHeader
          bordered
          rowKey={(item) => item._id} // eslint-disable-line
        />
      </div>
    </div>
  );
}

InsightSummary.propTypes = {
  preloadServices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    service: PropTypes.shape({}).isRequired,
  })).isRequired,
};

export default InsightSummary;
