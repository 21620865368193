import React from 'react';

import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';

import config from '~/config';
import Router from '~/router';
import routes from '~/config/routes';
import configureStore from '~/store/configureStore';

import './assets/css/index.less';

/**
 * Application root
 *
 * @return {JSX.Element}
 */
function App () {
  const store = configureStore();
  const { HELMET_CONFIG } = config;

  return (
    <Provider store={store} key="provider">
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={HELMET_CONFIG.favicon} type="image/x-icon" />
        <link rel="icon" href={HELMET_CONFIG.favicon} type="image/x-icon" />
        <title>{HELMET_CONFIG.title}</title>
      </Helmet>

      <Router routes={routes} />
    </Provider>
  );
}

export default App;
