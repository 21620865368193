import React, { Component } from 'react';
import { Typography } from 'antd';

import './style.less';

const { Title } = Typography;

export default class Dashboard extends Component {
  render () {
    return (
      <div className="app-screen">
        <Title>
          Welcome to {` ${process.env.REACT_APP_WEBSITE_NAME}`}
        </Title>
      </div>
    );
  }
}
