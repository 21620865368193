import { IntentService } from '~/services/intents';
import * as Type from '~/actions/actionType';

// Fallback intent action
function action () {
  const { getFallback } = IntentService;

  return {
    /**
     * Get fallback intent
     *
     * @return {Promise<void>}
     */
    get () {
      return async (dispatch) => {
        dispatch({ type: Type.GET_FALLBACK_INTENT });

        try {
          const { data } = await getFallback();

          dispatch({ type: Type.GET_FALLBACK_INTENT_SUCCESS, payload: data.shift() });
        } catch (error) {
          dispatch({ type: Type.GET_FALLBACK_INTENT_ERROR, payload: error });
        }
      };
    },
  };
}

export default {};
export const FallbackIntentAction = action();
