import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Typography, Icon, Input, Button, Popconfirm, Form, Tooltip, Divider, Modal, notification } from 'antd';

import { PageTitle } from '~/components';
import { QuestionAction } from '~/actions/questions';

import './style.less';


/**
 * Default form data
 *
 * @constant
 * @private
 */
const defaultFormData = {
  title: { en: '' },
  choices: [
    {
      title: { en: '' },
    },
  ],
};

/**
 * Create and update intent screen
 *
 * @param {{ form: any }} props
 * @return {JSX.Element}
 */
function SaveQuestion ({ form }) {
  const [ formData, setFormData ] = useState(defaultFormData);

  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.service);

  const history = useHistory();

  const { id: slug } = useParams();
  const isEditing = !_.isEmpty(slug);

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, value) => {
      try {
        if (err) throw err;

        let msg = {};
        if (!isEditing) {
          await dispatch(QuestionAction.create(value));

          msg = {
            message: 'Created successfully',
            description: 'Your new question was created successfully',
          };
        } else {
          await dispatch(QuestionAction.update(slug, value));

          msg = {
            message: 'Edited successfully',
            description: 'Your question was edited successfully.',
          };
        }

        notification.success(msg);
        history.push('/questions');
      } catch (er) {
        notification.error({ message: 'Oops, something went wrong', description: er });
      }
    });
  };

  const handleRemoveQuestion = () => {
    Modal.confirm({
      title: 'Are you absolutely sure?',
      content: 'This action cannot be undone. This will permanently remove this questions and its choices.',
      okText: 'Remove',
      okType: 'danger',
      async onOk () {
        await dispatch(QuestionAction.remove(slug));

        notification.success({ message: 'Removed successfully', description: 'Your question was removed successfully.' });
        history.push('/questions');
      },
    });
  };

  const handleAddAnswer = () => {
    const title = { en: '' };
    setFormData({
      ...formData,
      choices: [ ...formData.choices, { title }],
    });
  };

  const renderAnswerHelpText = (index, errText) => {
    const handleRemoveAnswer = () => {
      setFormData((state) => {
        const choices = [ ...state.choices ];
        choices.splice(index, 1);

        return { ...state, choices };
      });
    };

    return (
      <span>
        <Typography.Text
          type="danger"
          style={{ cursor: 'pointer', marginRight: 10 }}
          onClick={handleRemoveAnswer}
          underline
        >
          Remove
        </Typography.Text>
        {errText}
      </span>
    );
  };

  useEffect(() => {
    if (error) notification.error({ message: 'Oops, something went wrong', description: error });
  }, [ error ]);

  useEffect(() => {
    const init = () => {
      dispatch(async (d) => {
        const question = await QuestionAction.get(slug, { $populate: [ 'choices' ] })(d);

        setFormData(question);
      });
    };

    if (slug) init();
  }, [ slug ]);

  return (
    <div className="new-question-page">
      <Spin spinning={loading} tip="loading...">
        <div className="page-title-container">
          <PageTitle
            title={isEditing ? 'Edit Question' : 'New Question'}
            extra={(
              <Fragment>
                {isEditing && (
                  <Tooltip title="Delete">
                    <Button type="danger" onClick={handleRemoveQuestion} style={{ marginRight: 10 }}>
                      Delete
                    </Button>
                  </Tooltip>
                )}
                <Button
                  key="save"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  form="form"
                >
                  Save
                </Button>
              </Fragment>
            )}
          />
        </div>

        <Divider className="is-marginless" />

        <div className="page-content-container">
          <Form
            id="form"
            onSubmit={handleSubmit}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            hideRequiredMark
          >
            <Form.Item label={<Typography.Title level={3}>Question (En)</Typography.Title>}>
              {form.getFieldDecorator('title.en', {
                initialValue: _.get(formData, [ 'title', 'en' ]),
                rules: [
                  { required: true, message: 'Please enter question title en' },
                ],
              })(<Input size="large" placeholder="Enter Title (En)" />)}
            </Form.Item>
            <Form.Item label={<Typography.Title level={3}>Question (Th)</Typography.Title>}>
              {form.getFieldDecorator('title.th', {
                initialValue: _.get(formData, [ 'title', 'th' ]),
                rules: [
                  { required: true, message: 'Please enter question title th' },
                ],
              })(<Input size="large" placeholder="Enter Title (Th)" />)}
            </Form.Item>
            <Form.Item className="is-marginless" label={<Typography.Title level={3}>Answer</Typography.Title>}>
              {
                formData.choices.map((choice, index) => (
                  <Fragment key={index.toString()}>
                    {/* hidden input */}
                    {form.getFieldDecorator(`choices[${index}].id`, { initialValue: choice.id })(<Input type="hidden" />)}

                    <Form.Item
                      help={renderAnswerHelpText(index, form.getFieldError(`choices[${index}].title.en`))}
                    >
                      {form.getFieldDecorator(`choices[${index}].title.en`, {
                        initialValue: choice.title.en,
                        rules: [{ required: true, message: 'Please enter question title' }],
                      })(<Input size="large" className="answer-item" placeholder="Enter answer" />)}
                    </Form.Item>
                  </Fragment>
                ))
              }
            </Form.Item>

            <Form.Item>
              <Button type="dashed" size="large" onClick={handleAddAnswer} block>
                <Icon type="plus" />
                Add Answer
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
}

SaveQuestion.propTypes = {
  form: PropTypes.shape().isRequired,
};

SaveQuestion.defaultProps = {};

export default Form.create()(SaveQuestion);
