import React, { PureComponent } from 'react';

export default class extends PureComponent {
  static propTypes = {
  }

  static defaultProps = {
  }

  state = {
  }

  // ======================================================
  // Commit Phase
  // Can work with DOM, run side effects, schedule updates.
  // ======================================================
  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }

  componentWillUnmount = () => {
  }

  // ======================================================
  // Pre-commit Phase
  // Can read the DOM
  // ======================================================
  getSnapshotBeforeUpdate = (prevProps, prevState) => ({})

  // ======================================================
  // Render Phase
  // Pure and no side effects. May be paused, aborted or restarted by React.
  // ======================================================
  static getDerivedStateFromProps (props, state) {
    return {};
  }

  // ======================================================
  // Private Methods
  // ======================================================
  renderExtra = () => {
    const { extra } = this.props;

    if (typeof extra === 'string') return <div><p>{extra}</p></div>;
    return extra;
  }

  render () {
    const { title, subtitle } = this.props;

    return (
      <div style={{ marginBottom: 30, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2 style={{ marginBottom: 2 }}>{title}</h2>
          <small style={{ margin: 0 }}>{subtitle}</small>
        </div>
        <div className="extra-wrapper">
          {this.renderExtra()}
        </div>
      </div>
    );
  }
}
