import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import { Divider, Button, Table } from 'antd';

import { PageTitle } from '~/components';
import { JSONToCSVConvertor } from '~/utils';
import { usePreloadServices } from '~/hooks';

import './style.less';

/**
 * Rich menu list page
 *
 * @return {JSX.Element}
 */
function Insight ({ preloadServices }) {
  const { isLoaded, responses } = usePreloadServices(preloadServices);
  const { insights = [], members = [] } = responses;
  const getMemberByLineId = (lineId) => {
    const member = _.find(members, (eachMember) => eachMember.line === lineId);
    return member;
  };

  const getMemberAge = (member) => {
    if (!member || _.isEmpty(member.birthdate)) return 'Unknown';
    const nowMoment = moment();
    const memberBirthDateMoment = moment(member.birthdate, 'YYYY-MM-DD');
    const age = nowMoment.diff(memberBirthDateMoment, 'years'); // calculates patient's age in years
    return age;
  };

  /**
   * Column setting of antd table
   *
   * @constant
   */
  const columns = [
    {
      title: 'User',
      dataIndex: 'subject',
      key: 'subject',
      render: (subject) => {
        const member = getMemberByLineId(subject);
        const link = `members/save/${member.id}`;
        if (_.isEmpty(member)) return 'Anonymous';
        return (
          <Link to={link}>{`${member?.firstname} ${member?.lastname}`}</Link>
        );
      },
    },
    {
      title: 'Gender',
      dataIndex: 'subject',
      key: 'gender',
      render: (subject) => {
        const member = getMemberByLineId(subject);
        return member?.gender;
      },
    },
    {
      title: 'Age',
      dataIndex: 'subject',
      key: 'age',
      render: (subject) => {
        const member = getMemberByLineId(subject);
        return getMemberAge(member);
      },
    },
    {
      title: 'source',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'medium',
      dataIndex: 'medium',
      key: 'medium',
    },
    {
      title: 'campaign',
      dataIndex: 'campaign',
      key: 'campaign',
    },
    {
      title: 'content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  useEffect(() => {
  }, []);

  const handleExportCsv = () => {
    const dataToExcel = _.map(insights, (item) => {
      const { subject, createdAt } = item;
      const dataToExport = _.omit(item, [ '_id', '__v', 'subject', 'createdAt', 'updatedAt' ]);
      const member = getMemberByLineId(subject);
      return {
        ...dataToExport,
        fullname: `${member?.firstname} ${member?.lastname}`,
        gender: member?.gender,
        age: getMemberAge(member),
        timestamp: moment(createdAt).format('YYYY-MM-DD HH:mm'),
      };
    });
    JSONToCSVConvertor(dataToExcel, 'insights', true);
  };

  const sortByDescInsights = _.reverse(_.clone(insights));

  return (
    <div className="rich-menu-page">
      <div className="page-title-container">
        <PageTitle
          title="Insights"
          extra={(
            <div>
              <Button type="primary" onClick={handleExportCsv}>Export CSV</Button>
            </div>
          )}
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <div>{`Total insight (${insights.length}) events`}</div>
        <br />
        <Table
          dataSource={sortByDescInsights}
          columns={columns}
          loading={!isLoaded}
          showHeader
          bordered
          rowKey={(item) => item._id} // eslint-disable-line
        />
      </div>
    </div>
  );
}

Insight.propTypes = {
  preloadServices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    service: PropTypes.shape({}).isRequired,
  })).isRequired,
};

export default Insight;
