import fetcher from '~/utils/fetcher';
import config from '~/config';

// training service
function service () {
  const endpoint = 'trainings';
  const { API_VERSION } = config;

  return {
    /**
     * Find trainings
     *
     * @param {any} params - url search params
     */
    find (params) {
      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },

    /**
     * Update fallback trainings
     *
     * @param {any} params - url search params
     */
    update (id, data, params) {
      return fetcher.patch(`${API_VERSION}/${endpoint}/${id}`, data, { params });
    },

    /**
     * Delete fallback trainings
     *
     * @param {any} params - url search params
     */
    remove (id, data, params) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/${id}`, data, { params });
    },
  };
}

export default {};
export const TrainingService = service();
