import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import _ from 'lodash';
import { Logo, BackgroundImage } from '../../components';
import { AuthAction } from '~/actions/auth';
import { withRedux } from '../../hoc';
import './style.less';

const background = `${process.env.PUBLIC_URL}/assets/images/background.jpg`;

const FormItem = Form.Item;

const mapStateToProps = (state) => ({
  isLogin: _.get(state, 'auth.isLogin'),
});

const actionToProps = {
  login: AuthAction.signin,
};

class Login extends Component {
  constructor (props) {
    super(props);
    this.state = {
      submitting: false,
      errorMessage: null,
    };
  }

  login = async (uid, password) => {
    try {
      if (this.state.submitting) return;

      this.setState({ submitting: true, errorMessage: null });

      await this.props.actions.login(uid, password);

      this.setState({ submitting: false, errorMessage: null });
    } catch (error) {
      this.setState({ submitting: false, errorMessage: error.message });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { uid, password } = values;
        this.login(uid, password);
      }
    });
  };

  render () {
    const { submitting, errorMessage } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className="login-page">
        <BackgroundImage image={background} />
        <div className="login-container">
          <div className="container">
            <div className="login-form-block">
              <div className="logo">
                <Logo />
              </div>
              <div className="app-name">
                {process.env.REACT_APP_WEBSITE_NAME}
                {' '}
                Backoffice
              </div>
              {errorMessage && <Alert message={errorMessage} type="error" showIcon style={{ marginBottom: 15 }} />}
              <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem>
                  {getFieldDecorator('uid', {
                    rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <Input
                      autoFocus
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Username"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" htmlType="submit" loading={submitting} className="login-form-button" block>
                    {submitting ? 'Logging...' : 'Log in'}
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(withRedux(mapStateToProps, actionToProps)(Login));
