import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';

import config from '../../config';

const { HELMET_CONFIG } = config;

// const logo = `${process.env.PUBLIC_URL}/assets/images/logo.svg`;
// const logo = `${process.env.PUBLIC_URL}/assets/images/logo.png`;

export default class Logo extends PureComponent {
  render () {
    return <Styled src={HELMET_CONFIG.logo} alt="" />;
  }
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Styled = styled.img`
  /* animation: ${rotate} infinite 20s linear; */
`;
