import React, { useEffect, useState, useRef, Fragment } from 'react';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Table, Divider, Tooltip, Icon, Modal, notification } from 'antd';

import { PageTitle, List } from '~/components';
import { useDidUpdateEffect, usePaginate } from '~/hooks';
import { ServiceAction } from '~/actions/services';

import { EmptyList } from './components';
import './style.less';


/**
 * Services page
 *
 * @return {JSX.Element}
 */
function Services () {
  const [ filter ] = useState('');
  const timeoutId = useRef();

  const { services = [], error, loading } = useSelector((state) => state.service);
  const { pagination, isVisited, setTotal, setCurrent } = usePaginate();
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * Fetch
   *
   * @param {any} opts
   * @param {'find' | 'fetch'} func
   * @return {void}
   */
  const fetch = (opts, func = 'find') => {
    dispatch(ServiceAction[func]({ ...opts, $select: [ 'id', 'title', 'description' ] }));
  };

  const handleTableChange = (paginationChange) => {
    const { current } = paginationChange;

    setCurrent(current);
    if (!isVisited(current)) fetch({ ...pagination.query, $page: current }, 'fetch');
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: 'Are you absolutely sure?',
      content: `This action CANNOT be undone. This will permanently remove '${record.title.en}'.`,
      okText: 'Remove',
      okType: 'danger',
      async onOk () {
        await dispatch(ServiceAction.remove(record.id));

        notification.success({ message: 'Removed successfully', description: 'Your service was removed successfully.' });
        fetch(pagination.query);
      },
    });
  };

  /**
   * Column setting of table
   */
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      render (title) {
        return (
          <Fragment>
            <p className="is-marginless">{title}</p>
          </Fragment>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render (description) {
        return (
          <Fragment>
            <p className="is-marginless">{description}</p>
          </Fragment>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 100,
      render (record) {
        return (
          <Fragment>
            <Tooltip title="Edit">
              <Icon type="edit" onClick={() => history.push(`/services/edit/${record.id}`)} />
            </Tooltip>

            <Divider type="vertical" />

            <Tooltip title="Delete">
              <Icon style={{ color: 'red' }} type="delete" onClick={() => handleDelete(record)} />
            </Tooltip>
          </Fragment>
        );
      },
    },
  ];

  useDidUpdateEffect(() => {
    setTotal(services.total);
  }, [ services ]);

  useEffect(() => {
    if (error) {
      notification.error({ message: 'Oops! something went wrong.', description: error });
      console.error(error);
    }
  }, [ error ]);

  useEffect(() => {
    fetch(pagination.query);
  }, []);

  return (
    <div className="question-page">
      <div className="page-title-container">
        <PageTitle
          title="Services"
          extra={(
            <Link to="/services/create">
              <Button type="primary">Create New</Button>
            </Link>
          )}
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <Table
          rowKey={((item) => item.id)}
          dataSource={services.data}
          loading={loading}
          columns={columns}
          showHeader={false}
          pagination={pagination.plain}
          onChange={handleTableChange}
          locale={{ emptyText: <EmptyList filter={filter} timeoutId={timeoutId} /> }}
          bordered
        />
      </div>
    </div>
  );
}

export default Services;
