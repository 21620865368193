import React, { PureComponent } from 'react';
import { Form, Input, InputNumber, Select, DatePicker, TreeSelect, Switch } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { TextEditor, Repeat, MediaPopup, MyTree, InputHidden } from '..';

const { TextArea } = Input;

export default class InputToFormItem extends PureComponent {
  render () {
    const { formItems, form, preloadServices } = this.props;
    const { getFieldDecorator } = form;
    return (
      <React.Fragment>
        {_.map(formItems, (formItem, key) => {
          const {
            field,
            label,
            fieldDecorator,
            inputType,
            inputOption = {
              onChange: () => null,
            },
            defaultValue = null,
            render,
          } = formItem;

          let inputComponent = <Input {...inputOption} />;
          let initialValue = defaultValue;
          let valuePropName = 'value';

          const handleChangeInput = (e) => {
            if (inputOption.onChange) inputOption.onChange(e.target.value, this.props);
          };

          switch (inputType) {
            case 'plain-text':
              const renderer = render ? render(initialValue, field, preloadServices) : initialValue || field[valuePropName];
              inputComponent = <span style={{ fontWeight: 700 }} className="ant-form-text">{renderer}</span>;
              break;
            case 'text':
              inputComponent = <Input {...inputOption} onChange={handleChangeInput} />;
              break;
            case 'number':
              inputComponent = <InputNumber {...inputOption} />;
              break;
            case 'wysiwyg':
              if (_.get(inputOption, 'driver') === 'react-draft-wysiwyg') valuePropName = 'initialContentState';
              inputComponent = <TextEditor {...inputOption} />;
              break;
            case 'select':
              const { mode } = inputOption;
              if (mode === 'tags' || mode === 'multiple') initialValue = initialValue || [];
              inputComponent = (
                <Select
                  showSearch
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  {...inputOption}
                />
              );
              break;
            case 'tree':
              valuePropName = 'defaultCheckedKeys';
              inputComponent = <MyTree checkable defaultExpandAll {...inputOption} />;
              break;
            case 'tree-select':
              inputComponent = (
                <TreeSelect
                  allowClear
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeDefaultExpandAll
                  {...inputOption}
                />
              );
              break;
            case 'switch':
              valuePropName = 'checked';
              inputComponent = <Switch {...inputOption} />;
              break;
            case 'date-picker':
              if (initialValue) initialValue = moment(initialValue);
              inputComponent = (
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  placeholder={null}
                  style={{ width: '100%', minWidth: 'initial' }}
                  {...inputOption}
                />
              );
              break;
            case 'repeat':
              return <Repeat key={key} {...this.props} {...formItem} />;
            case 'media':
              inputComponent = <MediaPopup {...formItem} {...inputOption} />;
              break;
            case 'password':
              inputComponent = <Input.Password {...inputOption} />;
              break;
            case 'hidden':
              inputComponent = <InputHidden />;
              break;
            default:
              break;
          }
          return (
            <Form.Item key={key} label={label}>
              {getFieldDecorator(field, {
                ...fieldDecorator,
                initialValue,
                valuePropName,
              })(inputComponent)}
            </Form.Item>
          );
        })}
      </React.Fragment>
    );
  }
}
