import fetcher from '~/utils/fetcher';
import config from '~/config';

// question service
function service () {
  const endpoint = 'questions';
  const { API_VERSION } = config;

  return {
    /**
     * Find questions
     *
     * @param {any} params
     */
    find (params) {
      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },

    /**
     * Get question
     *
     * @param {string | number} id
     */
    get (id, params) {
      return fetcher.get(`${API_VERSION}/${endpoint}/${id}`, { params });
    },

    /**
     * Create new question
     *
     * @param {any} data - question and choices data
     */
    create (data) {
      return fetcher.post(`${API_VERSION}/${endpoint}`, data);
    },

    /**
     * Update question
     *
     * @param {string | number} id
     * @param {any} data
     * @param {any} params
     */
    update (id, data, params) {
      return fetcher.put(`${API_VERSION}/${endpoint}/${id}`, data, { params });
    },

    /**
     * Remove question
     *
     * @param {string | number} id
     */
    remove (id) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/${id}`);
    },
  };
}

export default {};
export const QuestionService = service();
