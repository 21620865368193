import { RichMenuService } from '~/services/rich-menu';
import * as type from '~/actions/actionType';


// Rich menu action
function action () {
  const { find, get, update, remove } = RichMenuService;

  return {
    /**
     * Find rich menu
     *
     * @param {any} dispatch
     * @return {Promise<void>}
     */
    find (search) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_RICH_MENU });

        try {
          const { data } = await find({ $populate: [ 'image', 'tier' ], ...search });

          dispatch({ type: type.FETCH_RICH_MENU_SUCCESS, payload: data });
        } catch (error) {
          dispatch({ type: type.FETCH_RICH_MENU_ERROR, payload: error });
        }
      };
    },

    /**
     * Get existing rich menu
     *
     * @param {string | number} id
     * @param {any} params
     */
    get (id, params) {
      return async (dispatch) => {
        dispatch({ type: type.GET_RICH_MENU });

        try {
          const { data } = await get(id, params);

          dispatch({ type: type.GET_RICH_MENU_SUCCESS });
          return data;
        } catch (error) {
          dispatch({ type: type.GET_RICH_MENU_ERROR, payload: error });
          throw error;
        }
      };
    },

    /**
     * Update existing rich menu
     *
     * @param {string | number} id
     * @param {any} data
     * @param {any} params
     */
    update (id, data, params) {
      return async (dispatch) => {
        dispatch({ type: type.UPDATE_RICH_MENU });

        try {
          await update(id, data, params);

          dispatch({ type: type.UPDATE_RICH_MENU_SUCCESS });
        } catch (error) {
          dispatch({ type: type.UPDATE_RICH_MENU_ERROR, payload: error });
        }
      };
    },

    /**
     * Remove existing rich menu
     *
     * @param {string| number} id
     * @param {any} params
     */
    remove (id, params) {
      return async (dispatch) => {
        dispatch({ type: type.REMOVE_RICH_MENU });

        try {
          await remove(id, params);

          dispatch({ type: type.REMOVE_RICH_MENU_SUCCESS });
        } catch (error) {
          dispatch({ type: type.REMOVE_RICH_MENU_ERROR, payload: error });
        }
      };
    },
  };
}

export default {};
export const RichMenuAction = action();
