import fetcher from '~/utils/fetcher';
import config from '~/config';

// rich menu service
function service () {
  const endpoint = 'rich-menu';
  const { API_VERSION } = config;

  return {
    /**
     * Find richmenu
     *
     * @param {object} params
     */
    find (params) {
      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },

    /**
     * Get existing rich menu
     *
     * @param {string | number} id
     * @param {any} params
     */
    get (id, params) {
      return fetcher.get(`${API_VERSION}/${endpoint}/${id}`, { params });
    },

    /**
     * Create new rich menu
     *
     * @param {any} data
     * @param {any} params
     */
    create (data, params) {
      return fetcher.post(`${API_VERSION}/${endpoint}`, data, { params });
    },

    /**
     * Update existing rich menu (PUT)
     *
     * @param {string | number} id
     * @param {any} data
     * @param {any} params
     */
    update (id, data, params) {
      return fetcher.put(`${API_VERSION}/${endpoint}/${id}`, data, { params });
    },

    /**
     * Remove existing rich menu
     *
     * @param {string | number} id - rich menu id (primary key)
     * @param {any} params
     */
    remove (id, params) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/${id}`, { params });
    },

    /**
     * Remove existing rich menu
     *
     * @param {string | number} id - rich menu id (primary key)
     * @param {any} params
     */
    setDefault (data) {
      return fetcher.post(`${API_VERSION}/${endpoint}/default`, data);
    },

    /**
     * Remove existing rich menu
     *
     * @param {string | number} id - rich menu id (primary key)
     * @param {any} params
     */
    removeDefault (data) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/default`, data);
    },
  };
}

export default {};
export const RichMenuService = service();
