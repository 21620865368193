export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const IS_LOGIN = 'IS_LOGIN';
export const LOGIN_CHECKED = 'LOGIN_CHECKED';
export const RECEIVE_AUTH = 'RECEIVE_AUTH';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';
export const RECEIVE_LANGUAGES = 'RECEIVE_LANGUAGES';
export const RECEIVE_MEDIAS = 'RECEIVE_MEDIAS';

// action for rich menu reducer
export const FETCH_RICH_MENU = '@@rich-menu/FETCH';
export const FETCH_RICH_MENU_SUCCESS = '@@rich-menu/FETCH_SUCCESS';
export const FETCH_RICH_MENU_ERROR = '@@rich-menu/FETCH_ERROR';
export const GET_RICH_MENU = '@@rich-menu/GET';
export const GET_RICH_MENU_SUCCESS = '@@rich-menu/GET_SUCCESS';
export const GET_RICH_MENU_ERROR = '@@rich-menu/GET_ERROR';
export const UPDATE_RICH_MENU = '@@rich-menu/UPDATE';
export const UPDATE_RICH_MENU_SUCCESS = '@@rich-menu/UPDATE_SUCCESS';
export const UPDATE_RICH_MENU_ERROR = '@@rich-menu/UPDATE_ERROR';
export const REMOVE_RICH_MENU = '@@rich-menu/REMOVE';
export const REMOVE_RICH_MENU_SUCCESS = '@@rich-menu/REMOVE_SUCCESS';
export const REMOVE_RICH_MENU_ERROR = '@@rich-menu/REMOVE_ERROR';

// action for tier reducer
export const FETCH_TIER = '@@tier/FETCH';
export const FETCH_TIER_SUCCESS = '@@tier/FETCH_SUCCESS';
export const FETCH_TIER_ERROR = '@@tier/FETCH_ERROR';

// action for question reducer
export const FETCH_QUESTION = '@@question/FETCH';
export const FETCH_QUESTION_SUCCESS = '@@question/FETCH_SUCCESS';
export const FETCH_QUESTION_ERROR = '@@question/FETCH_ERROR';
export const GET_QUESTION = '@@question/GET';
export const GET_QUESTION_SUCCESS = '@@question/GET_SUCCESS';
export const GET_QUESTION_ERROR = '@@question/GET_ERROR';
export const CREATE_QUESTION = '@@question/CREATE';
export const CREATE_QUESTION_SUCCESS = '@@question/CREATE_SUCCESS';
export const CREATE_QUESTION_ERROR = '@@question/CREATE_ERROR';
export const UPDATE_QUESTION = '@@question/UPDATE';
export const UPDATE_QUESTION_SUCCESS = '@@question/UPDATE_SUCCESS';
export const UPDATE_QUESTION_ERROR = '@@question/UPDATE_ERROR';
export const REMOVE_QUESTION = '@@question/REMOVE';
export const REMOVE_QUESTION_SUCCESS = '@@question/REMOVE_SUCCESS';
export const REMOVE_QUESTION_ERROR = '@@question/REMOVE_ERROR';
export const FETCH_QUESTION_PAGE = '@@question-page/FETCH';
export const FETCH_QUESTION_PAGE_SUCCESS = '@@question-page/FETCH_SUCCESS';
export const FETCH_QUESTION_PAGE_ERROR = '@@question-page/FETCH_ERROR';

// action for fallback intent
export const GET_FALLBACK_INTENT = '@@fallback-intent/GET';
export const GET_FALLBACK_INTENT_SUCCESS = '@@fallback-intent/GET_SUCCESS';
export const GET_FALLBACK_INTENT_ERROR = '@@fallback-intent/GET_ERROR';

// action for tier reducer
export const FETCH_GREETING_MESSAGES = '@@greeting-messages/FETCH';
export const FETCH_GREETING_MESSAGES_SUCCESS = '@@greeting-messages/FETCH_SUCCESS';
export const FETCH_GREETING_MESSAGES_ERROR = '@@greeting-messages/FETCH_ERROR';
export const UPDATE_GREETING_MESSAGES = '@@greeting-messages/UPDATE';
export const UPDATE_GREETING_MESSAGES_SUCCESS = '@@greeting-messages/UPDATE_SUCCESS';
export const UPDATE_GREETING_MESSAGES_ERROR = '@@greeting-messages/UPDATE_ERROR';
export const REMOVE_GREETING_MESSAGES = '@@greeting-messages/REMOVE';
export const REMOVE_GREETING_MESSAGES_SUCCESS = '@@greeting-messages/REMOVE_SUCCESS';
export const REMOVE_GREETING_MESSAGES_ERROR = '@@greeting-messages/REMOVE_ERROR';

// action for chat reducer
export const FETCH_CHAT_HISTORY = '@@chats/CHAT_HISTORY';
export const FETCH_CHAT_HISTORY_SUCCESS = '@@chats/CHAT_HISTORY_SUCCESS';
export const FETCH_CHAT_HISTORY_ERROR = '@@chats/CHAT_HISTORY_ERROR';

// action for training reducer
export const FETCH_TRAINING = '@@chats/TRAINING';
export const FETCH_TRAINING_SUCCESS = '@@chats/TRAINING_SUCCESS';
export const FETCH_TRAINING_ERROR = '@@chats/TRAINING_ERROR';

// action for services
export const FETCH_SERVICE = '@@service-page/FETCH';
export const FETCH_SERVICE_SUCCESS = '@@service-page/FETCH_SUCCESS';
export const FETCH_SERVICE_ERROR = '@@service-page/FETCH_ERROR';
export const GET_SERVICE = '@@service-page/FETCH';
export const GET_SERVICE_SUCCESS = '@@service-page/GET_SUCCESS';
export const GET_SERVICE_ERROR = '@@service-page/GET_ERROR';
export const CREATE_SERVICE = '@@service-page/FETCH';
export const CREATE_SERVICE_SUCCESS = '@@service-page/CREATE_SUCCESS';
export const CREATE_SERVICE_ERROR = '@@service-page/CREATE_ERROR';
export const UPDATE_SERVICE = '@@service-page/FETCH';
export const UPDATE_SERVICE_SUCCESS = '@@service-page/UPDATE_SUCCESS';
export const UPDATE_SERVICE_ERROR = '@@service-page/UPDATE_ERROR';
export const REMOVE_SERVICE = '@@service-page/FETCH';
export const REMOVE_SERVICE_SUCCESS = '@@service-page/REMOVE_SUCCESS';
export const REMOVE_SERVICE_ERROR = '@@service-page/REMOVE_ERROR';
