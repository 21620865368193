import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


/**
 * Support media type
 *
 * @param {Object} props
 * @param {string} props.format
 * @param {string} props.size
 * @return {JSX.Element}
 */
function SupportMediaType ({ format, size, width }) {
  return (
    <Fragment>
      {format && (
        <div>
          <small>
            {`File formats: `}
            {format}
          </small>
        </div>
      )}

      {width && (
        <div>
          <small>
            {`Width: `}
            {' '}
            {width}
          </small>
        </div>
      )}

      {size && (
        <div>
          <small>
            {`Max file size: `}
            {size}
          </small>
        </div>
      )}
    </Fragment>
  );
}

SupportMediaType.propTypes = {
  format: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
};

SupportMediaType.defaultProps = {
  format: '',
  size: '',
  width: '',
};

export default SupportMediaType;
