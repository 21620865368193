import fetcher from '~/utils/fetcher';

// auth service
function service () {
  const endpoint = '/auth';

  return {
    /**
     * Sign in
     *
     * @description request to invoke access token
     * @param {string} username
     * @param {string} password
     */
    signin (username, password) {
      return fetcher.post(endpoint, { strategy: 'local', username, password });
    },

    /**
     * Check authenticate by provide token
     *
     * @description check the current token is valid or not
     * @param {string} accessToken - access token in form of jwt
     */
    check (accessToken) {
      return fetcher.post(endpoint, { strategy: 'jwt', accessToken });
    },

    /**
     * Revoke token
     *
     * @description call to revoke the current token
     */
    revoke () {
      return fetcher.post(`${endpoint}/revoke`);
    },
  };
}

export default {};
export const AuthService = service();
