import _ from 'lodash';

import { TrainingService } from '~/services/trainings';

import * as type from '~/actions/actionType';

// Question action
function action () {
  const { find, update, remove } = TrainingService;
  return {
    /**
     * Find questions
     *
     * @return {(dispatch: any) => Promise<void>}
     */
    find () {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_TRAINING });
        try {
          const { data } = await find({ intentId: null, '$sort[id]': -1 });
          dispatch({ type: type.FETCH_TRAINING_SUCCESS, payload: data });
          return data;
        } catch (error) {
          dispatch({ type: type.FETCH_TRAINING_ERROR, payload: error });
          throw error;
        }
      };
    },
    update (id, data) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_TRAINING });
        try {
          await update(id, data);
          return data;
        } catch (error) {
          dispatch({ type: type.FETCH_TRAINING_ERROR, payload: error });
          throw error;
        }
      };
    },
    remove (id) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_TRAINING });
        try {
          await remove(id);
        } catch (error) {
          dispatch({ type: type.FETCH_TRAINING_ERROR, payload: error });
          throw error;
        }
      };
    },
  };
}

export default {};
export const TrainingAction = action();
