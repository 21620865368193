import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form, Select, Checkbox, Row, Col } from 'antd';

/**
 * Criteria modal component
 *
 * @param {object} props
 * @param {{}} props.criterias
 * @param {boolean} props.visible
 * @param {function} props.onCancel
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 */
function CriteriaModal ({ form, criterias, visible, onCancel, onSubmit }) {
  const transformed = useMemo(() => Object.entries(criterias).reduce((result, [ key, value ]) => {

    if (key === 'properties') {
      const transformedProperties = Object.entries(value).map(([ pKey, pValue ]) => ({ id: pKey, ...pValue }));

      return [ ...result, ...transformedProperties ];
    }

    return [
      ...result,
      { id: key, ...value },
    ];
  }, []), [ criterias ]);

  /**
   * Handle on ok
   *
   * @return {{ [string]: string[] }}
   */
  const handleOnOk = () => {
    const values = form.getFieldValue('values');
    const criteria = transformed[form.getFieldValue('criteria')];

    onSubmit({ [criteria.id]: values });

    form.resetFields();
  };

  return (
    <Modal
      title="Targeting criteria"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOnOk}
    >
      <Form>
        <Form.Item>
          {
            form.getFieldDecorator('criteria', {
              initialValue: 0,
            })(
              <Select>
                {transformed.map(({ title }, index) => <Select.Option key={title} value={index}>{title}</Select.Option>)}
              </Select>,
            )
          }
        </Form.Item>

        <Form.Item>
          {
            form.getFieldDecorator('values', {
              initialValue: [],
            })(
              <Checkbox.Group>
                <Row>
                  {
                    (transformed[form.getFieldValue('criteria')]?.values ?? []).map(({ value, label }) => (
                      <Col span={24} key={value}>
                        <Checkbox value={value}>{label}</Checkbox>
                      </Col>
                    ))
                  }
                </Row>
              </Checkbox.Group>,
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}

CriteriaModal.propTypes = {
  form: PropTypes.shape().isRequired,
  criterias: PropTypes.shape(),
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

CriteriaModal.defaultProps = {
  criterias: {},
  visible: false,
  onCancel () {},
  onSubmit () {},
};

export default Form.create()(CriteriaModal);
