export default [
  {
    title: 'All',
    key: 'all',
    selectable: false,
    children: [
      {
        title: 'Locations',
        key: 'locations',
        selectable: false,
        children: [
          { title: 'View', key: 'locations.view', selectable: false },
          { title: 'Add', key: 'locations.add', selectable: false },
          { title: 'Edit', key: 'locations.edit', selectable: false },
          { title: 'Delete', key: 'locations.delete', selectable: false },
        ],
      },
      {
        title: 'Banners',
        key: 'banners',
        selectable: false,
        children: [
          { title: 'View', key: 'banners.view', selectable: false },
          { title: 'Add', key: 'banners.add', selectable: false },
          { title: 'Edit', key: 'banners.edit', selectable: false },
          { title: 'Delete', key: 'banners.delete', selectable: false },
        ],
      },
      {
        title: 'Coupon',
        key: 'coupon',
        selectable: false,
        children: [
          {
            title: 'Categories',
            key: 'coupon.categories',
            selectable: false,
            children: [
              {
                title: 'View',
                key: 'coupon.categories.view',
                selectable: false,
              },
              { title: 'Add', key: 'coupon.categories.add', selectable: false },
              {
                title: 'Edit',
                key: 'coupon.categories.edit',
                selectable: false,
              },
              {
                title: 'Delete',
                key: 'coupon.categories.delete',
                selectable: false,
              },
            ],
          },
          {
            title: 'Coupons',
            key: 'coupon.coupons',
            selectable: false,
            children: [
              { title: 'View', key: 'coupon.coupons.view', selectable: false },
              { title: 'Add', key: 'coupon.coupons.add', selectable: false },
              { title: 'Edit', key: 'coupon.coupons.edit', selectable: false },
              {
                title: 'Delete',
                key: 'coupon.coupons.delete',
                selectable: false,
              },
            ],
          },
        ],
      },
      {
        title: 'Snacks',
        key: 'snacks',
        selectable: false,
        children: [
          { title: 'View', key: 'snacks.view', selectable: false },
          { title: 'Add', key: 'snacks.add', selectable: false },
          { title: 'Edit', key: 'snacks.edit', selectable: false },
          { title: 'Delete', key: 'snacks.delete', selectable: false },
        ],
      },
      {
        title: 'User',
        key: 'user',
        selectable: false,
        children: [
          {
            title: 'Users',
            key: 'user.users',
            selectable: false,
            children: [
              { title: 'View', key: 'user.users.view', selectable: false },
              { title: 'Add', key: 'user.users.add', selectable: false },
              { title: 'Edit', key: 'user.users.edit', selectable: false },
              { title: 'Delete', key: 'user.users.delete', selectable: false },
            ],
          },
          {
            title: 'Roles',
            key: 'user.roles',
            selectable: false,
            children: [
              { title: 'View', key: 'user.roles.view', selectable: false },
              { title: 'Add', key: 'user.roles.add', selectable: false },
              { title: 'Edit', key: 'user.roles.edit', selectable: false },
              { title: 'Delete', key: 'user.roles.delete', selectable: false },
            ],
          },
        ],
      },
      {
        title: 'Setting',
        key: 'setting',
        selectable: false,
        children: [
          {
            title: 'Language',
            key: 'setting.language',
            selectable: false,
            children: [
              {
                title: 'View',
                key: 'setting.language.view',
                selectable: false,
              },
              { title: 'Add', key: 'setting.language.add', selectable: false },
              {
                title: 'Edit',
                key: 'setting.language.edit',
                selectable: false,
              },
              {
                title: 'Delete',
                key: 'setting.language.delete',
                selectable: false,
              },
            ],
          },
        ],
      },
    ],
  },
];
