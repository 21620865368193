import fetcher from '~/utils/fetcher';
import config from '~/config';

// staff service
function service () {
  const endpoint = 'accounts';
  const { API_VERSION } = config;

  return {
    /**
     * find new staff
     *
     * @param {any} staff - request body
     * @param {any} params
     */
    find (slug, params) {
      return fetcher.get(`${API_VERSION}/${endpoint}/${slug}`, { params });
    },
    /**
     * Create new staff
     *
     * @param {any} staff - request body
     * @param {any} params
     */
    create (staff, params) {
      return fetcher.post(`${API_VERSION}/${endpoint}`, staff, { params });
    },
    /**
     * update staff
     *
     * @param {any} staff - request body
     * @param {any} params
     */
    update (slug, data) {
      return fetcher.patch(`${API_VERSION}/${endpoint}/${slug}`, data);
    },
    /**
     * remove staff
     *
     * @param {any} staff - request body
     * @param {any} params
     */
    remove (slug) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/${slug}`);
    },
  };
}

export default {};
export const StaffService = service();
