import { TierService } from '~/services/tier';
import { FETCH_TIER, FETCH_TIER_SUCCESS, FETCH_TIER_ERROR } from '~/actions/actionType';

// Tier action
function action () {
  const { find, get } = TierService;

  return {
    /**
     * Find tiers
     *
     * @return {Promise<void>}
     */
    find () {
      return async (dispatch) => {
        dispatch({ type: FETCH_TIER });

        try {
          const { data } = await find();

          dispatch({ type: FETCH_TIER_SUCCESS, payload: data });
        } catch (error) {
          dispatch({ type: FETCH_TIER_ERROR, payload: error });
        }
      };
    },
    /**
     * Find tiers
     *
     * @return {Promise<void>}
     */
    get (tierId) {
      return async (dispatch) => {
        dispatch({ type: FETCH_TIER });
        try {
          const { data } = await get(tierId);
          dispatch({ type: FETCH_TIER_SUCCESS, payload: data });
        } catch (error) {
          dispatch({ type: FETCH_TIER_ERROR, payload: error });
        }
      };
    },
  };
}

export default {};
export const TierAction = action();
