import * as ActionType from '~/actions/actionType';

const initState = {
  services: [],
  trainings: [],
  chats: [],
  richMenus: [],
  tiers: [],
  questions: [],
  fallbackIntent: {},
  loading: false,
  error: undefined,
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.FETCH_RICH_MENU:
    case ActionType.FETCH_TIER:
    case ActionType.REMOVE_RICH_MENU:
    case ActionType.GET_RICH_MENU:
    case ActionType.UPDATE_RICH_MENU:
    case ActionType.FETCH_QUESTION:
    case ActionType.FETCH_QUESTION_PAGE:
    case ActionType.GET_FALLBACK_INTENT:
    case ActionType.CREATE_QUESTION:
    case ActionType.GET_QUESTION:
    case ActionType.UPDATE_QUESTION:
    case ActionType.REMOVE_QUESTION:
    case ActionType.FETCH_CHAT_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case ActionType.FETCH_RICH_MENU_ERROR:
    case ActionType.FETCH_TIER_ERROR:
    case ActionType.REMOVE_RICH_MENU_ERROR:
    case ActionType.GET_RICH_MENU_ERROR:
    case ActionType.UPDATE_RICH_MENU_ERROR:
    case ActionType.FETCH_QUESTION_ERROR:
    case ActionType.FETCH_QUESTION_PAGE_ERROR:
    case ActionType.GET_FALLBACK_INTENT_ERROR:
    case ActionType.CREATE_QUESTION_ERROR:
    case ActionType.GET_QUESTION_ERROR:
    case ActionType.UPDATE_QUESTION_ERROR:
    case ActionType.REMOVE_QUESTION_ERROR:
    case ActionType.FETCH_CHAT_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case ActionType.REMOVE_RICH_MENU_SUCCESS:
    case ActionType.GET_RICH_MENU_SUCCESS:
    case ActionType.UPDATE_RICH_MENU_SUCCESS:
    case ActionType.CREATE_QUESTION_SUCCESS:
    case ActionType.GET_QUESTION_SUCCESS:
    case ActionType.UPDATE_QUESTION_SUCCESS:
    case ActionType.REMOVE_QUESTION_SUCCESS:
    case ActionType.GET_SERVICE_SUCCESS:
    case ActionType.UPDATE_SERVICE_SUCCESS:
    case ActionType.REMOVE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionType.FETCH_QUESTION_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: {
          ...payload,
          data: [ ...state.questions.data, ...payload.data ],
        },
      };

    case ActionType.FETCH_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: payload,
      };

    case ActionType.GET_FALLBACK_INTENT_SUCCESS:
      return {
        ...state,
        fallbackIntent: payload,
        loading: false,
      };

    case ActionType.FETCH_RICH_MENU_SUCCESS:
      return {
        ...state,
        richMenus: payload,
        loading: false,
      };

    case ActionType.FETCH_TIER_SUCCESS:
      return {
        ...state,
        tiers: payload,
        loading: false,
      };

    case ActionType.FETCH_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        chats: payload,
        loading: false,
      };
    case ActionType.FETCH_TRAINING_SUCCESS:
      return {
        ...state,
        trainings: payload,
        loading: false,
      };

    case ActionType.FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        services: payload,
        loading: false,
      };

    default:
      return state;
  }
};
