import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tag, DatePicker, Divider, Button, Table, Icon, notification, Tooltip, Popconfirm, message } from 'antd';

import { PageTitle } from '~/components';

import { TrainingAction } from '~/actions/trainings';

import { JSONToCSVConvertor } from '~/utils';

import './style.less';

/**
 * Rich menu list page
 *
 * @return {JSX.Element}
 */
function Training () {
  const [ stateLoading, setStateLoading ] = useState(false);
  const { trainings, error, loading } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const deleteItem = async (id) => {
    if (stateLoading) return;
    setStateLoading(true);
    try {
      dispatch(TrainingAction.remove(id));
      message.success('Delete success.');
      setTimeout(() => {
        dispatch(TrainingAction.find());
      }, 500);
    } catch (err) {
      console.error('error', err);
      message.success('Delete Failed.');
    }
    setStateLoading(false);
  };

  const getActionButtons = (id, record) => {
    const keyword = record.message;
    const actionButtons = [];
    actionButtons.push(
      <Link to={`intents/create?trainingId=${id}&keyword=${keyword}`}>
        <Tooltip title="Approve">
          <Icon type="plus-circle" />
        </Tooltip>
      </Link>,
    );
    actionButtons.push(
      <Popconfirm
        title="Are you sure delete this task?"
        onConfirm={() => deleteItem(id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Delete">
          <Icon type="delete" />
        </Tooltip>
      </Popconfirm>,
    );
    return actionButtons;
  };

  /**
   * Column setting of antd table
   *
   * @constant
   */
  const columns = [
    {
      title: 'time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'trained',
      dataIndex: 'intentId',
      key: 'intentId',
      render: (intentId, record) => {
        if (intentId !== null) {
          return (
            <Tag color="green" key={record.id}>
              Trained
            </Tag>
          );
        }
        return (
          <Tag color="red" key={record.id}>
            Pending
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (id, record) => {
        const actionButtons = getActionButtons(id, record);
        return (
          <ul className="actions-btn">
            {_.map(actionButtons, (actionBtn, index) => <li key={index}>{actionBtn}</li>)}
          </ul>
        );
      },
    },
  ];

  useEffect(() => {
    if (error) notification.error({ message: 'Oops, something went wrong.', description: error.message });
  }, [ error ]);

  useEffect(() => {
    dispatch(TrainingAction.find());
  }, []);

  const handleExportCsv = () => {
     const dataToExcel = _.map(trainings, (chat) => ({
      time: moment(chat.createdAt).format('YYYY-MM-DD HH:mm'),
      message: chat.message,
      trained: chat.intentId !== null,
    }));
    // console.log('dataToExcel', trainings);
    JSONToCSVConvertor(dataToExcel, 'trainings', true);
  };

  const getFilterItems = () => trainings.filter((training) => training.intentId === null);

  return (
    <div className="rich-menu-page">
      <div className="page-title-container">
        <PageTitle
          title="Trainings"
          extra={<Button type="primary" onClick={handleExportCsv}>Export CSV</Button>}
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <div>{`Total trainings (${trainings.length}) messages`}</div>
        <br />
        <Table
          dataSource={getFilterItems()}
          columns={columns}
          loading={loading}
          showHeader
          bordered
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
}

export default Training;
