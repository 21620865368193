// initial state of message form reducer
const constructTextMessage = (text = '') => ({ type: 'text', text });
const constructImageMapMessage = () => ({
  type: 'imagemap',
  baseUrl: '',
  altText: '',
  baseSize: {
    width: 0,
    height: 0,
  },
  actions: [
    {
      type: 'uri',
      linkUri: '',
      area: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    },
  ],
});

const initialState = [ constructTextMessage() ];

/**
 * Message form reducer
 *
 * @param {any} state - reducer state
 * @param {{ payload: any, type: string }} action
 */
function reducer (state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      return payload;

    case 'ADD_MESSAGE':
      // limit message to 5
      // learn more https://developers.line.biz/en/reference/messaging-api/#send-multicast-message
      if (state.length < 5) return [ ...state, constructTextMessage() ];

      return state;

    case 'REMOVE_MESSAGE': {
      const newState = [ ...state ];

      newState.splice(payload.index, 1);
      return newState;
    }

    case 'CHANGE_MESSAGE': {
      const newState = [ ...state ];
      const target = state[payload.index];

      let message = payload.data;
      switch (message.type) {
        default:
        case 'text':
        case 'image':
        case 'video':
          break;
        case 'imagemap':
          message = payload.data;
          break;
      }
      newState.splice(payload.index, 1, { ...target, ...payload.data });
      return newState;
    }

    case 'CHANGE_MESSAGE_TYPE': {
      const newState = [ ...state ];

      switch (payload.type) {
        case 'text': {
          const target = newState[payload.index];
          const { text } = target;

          newState.splice(payload.index, 1, { type: payload.type, text });
          break;
        }

        case 'image':
        case 'video': {
          const target = newState[payload.index];
          const { originalContentUrl, previewImageUrl } = target;

          newState.splice(payload.index, 1, {
            type: payload.type,
            originalContentUrl: originalContentUrl || '',
            previewImageUrl: previewImageUrl || '',
          });
          break;
        }

        case 'imagemap': {
          newState.splice(payload.index, 1, constructImageMapMessage());
          break;
        }

        default:
          throw new Error(
            "only 'text', 'image', 'imagemap' and 'video' are accpetable.",
          );
      }

      return newState;
    }

    case 'MOVE_MESSAGE': {
      const newState = [ ...state ];

      switch (payload.direction) {
        case 'up': {
          const target = newState[payload.index];
          const prev = newState[payload.index - 1];

          // move the target message up
          newState.splice(payload.index - 1, 1, target);

          // move the prev message down to the current index
          newState.splice(payload.index, 1, prev);
          break;
        }

        case 'down': {
          const target = newState[payload.index];
          const next = newState[payload.index + 1];

          // move the target message down
          newState.splice(payload.index + 1, 1, target);

          // move the prev message up to the current index
          newState.splice(payload.index, 1, next);
          break;
        }

        default:
          throw new Error(
            `${payload.dirction} is not expected in direction property only 'up' and 'down' are acceptable.`,
          );
      }

      return newState;
    }

    default:
      return state;
  }
}

export { initialState, reducer };
