import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ConstructMessage } from './construct-message';
import * as LocalAction from './local-action-types';


/**
 * Initial value of response message group
 *
 * @constant
 * @private
 */
export const InitResponseMessageGroups = {
  [uuidv4()]: {
    [uuidv4()]: ConstructMessage('text', 0, { text: '' }),
  },
};

/**
 * Response reducer
 *
 * @param {any} state
 * @param {{ type: string, payload: any }} action
 * @return {any}
 */
export function ResponseMessageReducer (state, action) {
  const { type, payload, meta } = action;

  switch (type) {
    case LocalAction.ADD_GROUP:
      return {
        ...state,
        [uuidv4()]: {
          [uuidv4()]: ConstructMessage('text', 0, { text: '' }),
        },
      };

    case LocalAction.REMOVE_GROUP: {
      const { groupKey } = meta;

      return _.omit(state, groupKey);
    }

    case LocalAction.ADD_MESSAGE: {
      const { groupKey } = meta;

      const targetGroup = state[groupKey];
      const entries = Object.keys(targetGroup);

      return {
        ...state,
        [groupKey]: {
          ...targetGroup,
          [uuidv4()]: ConstructMessage('text', entries.length, { text: '' }),
        },
      };
    }

    case 'remove-answer': {
      const { key, groupKey } = meta;

      return _.omit(state, `${groupKey}.${key}`);
    }

    case LocalAction.SWAP:
      const { key, groupKey, direction } = meta;

      const targetGroup = state[groupKey];
      const messageKeys = Object.keys(targetGroup);
      const messageValues = Object.values(targetGroup);
      const targetIndex = messageKeys.indexOf(key);

      const swapPosition = direction === 'up' ? targetIndex - 1 : targetIndex + 1;
      const messageThatRequsetToSwap = messageValues[targetIndex];
      const messageThatWillBeSwapped = messageValues[swapPosition];

      const prepareTargetGroupForSorting = {
        [groupKey]: Object.entries(targetGroup).reduce((result, [ currentKey, currentValue ]) => ({
          ...result,
          [currentKey]: { ...currentValue, order: messageKeys.indexOf(currentKey) },
          ...(currentKey === key && { [currentKey]: { ...messageThatRequsetToSwap, order: swapPosition } }),
          ...(currentKey === messageKeys[swapPosition] && { [currentKey]: { ...messageThatWillBeSwapped, order: targetIndex } }),
        }), {}),
      };

      const sort = _.sortBy(prepareTargetGroupForSorting[groupKey], (message) => message.order);

      return { ...state, [groupKey]: sort };

    case LocalAction.CHANGE_MESSAGE_TYPE: {
      const { type, data } = payload;
      const { key, groupKey } = meta;

      const tergetGroup = state[groupKey];
      const targetMessage = tergetGroup[key];

      return {
        ...state,
        [groupKey]: {
          ...tergetGroup,
          [key]: { ...targetMessage, type, data },
        },
      };
    }

    case LocalAction.ON_CHANGE: {
      const { key, groupKey } = meta;

      const targetGroup = state[groupKey];
      const targetMessage = state[groupKey][key];

      return {
        ...state,
        [groupKey]: {
          ...targetGroup,
          [key]: {
            ...targetMessage,
            data: payload,
          },
        },
      };
    }

    case LocalAction.SET:
      return payload;

    default:
      throw new Error();
  }
}
