import React, { Component } from 'react';
import ReactDraftWysiwyg from './react-draft-wysiwyg';
import TinymceReact from './tinymce-react';


export default class TextEditor extends Component {
  static defaultProps = {
    driver: 'tinymce-react',
  };

  render () {
    const { driver } = this.props;
    if (driver === 'react-draft-wysiwyg') return <ReactDraftWysiwyg {...this.props} />;
    if (driver === 'tinymce-react') return <TinymceReact {...this.props} />;
  }
}
