import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Card, Tooltip, Icon } from 'antd';


/**
 * Target estimate component
 *
 * @param {object} props
 * @param {string | number} props.recipients - the actual number of recipient
 * @return {JSX.Element}
 */
function TargetEstimate ({ recipients }) {
  return (
    <Card
      type="inner"
      headStyle={{ textAlign: 'center' }}
      bodyStyle={{ textAlign: 'center' }}
      title={(
        <Fragment>
          <span style={{ marginRight: 5 }}>
            Target recipients
          </span>

          <Tooltip
            title={(
              <p style={{ textAlign: 'center', margin: 0 }}>The actual number of recipients to sending the broadcast message.</p>
            )}
          >
            <Icon type="question-circle" />
          </Tooltip>
        </Fragment>
      )}
    >
      {`${recipients} persons`}
    </Card>
  );
}

TargetEstimate.propTypes = {
  recipients: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};

TargetEstimate.defaultProps = {
  recipients: 0,
};

export default TargetEstimate;
