import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Empty, Button } from 'antd';


/**
 * Empty list component
 *
 * @param {Object} props
 * @param {string} [props.filter='']
 * @param {{ current: any }} [props.timeoutId={ current: null }]
 * @return {JSX.Element}
 */
function EmptyList ({ filter, timeoutId }) {
  return (
    <Empty
      imageStyle={{ height: 80 }}
      description={
        filter || timeoutId.current ? (
          <span>No result found</span>
        ) : (
          <span>
            You don&rsquo;t have any service. Create your first service to response
            your customer automatically.
            {' '}
            <Link to="/services/create">Create now</Link>
          </span>
        )
      }
    >
      {!filter && !timeoutId.current && (
        <Link to="/services/create">
          <Button type="primary">Create New Service</Button>
        </Link>
      )}
    </Empty>
  );
}

EmptyList.propTypes = {
  filter: PropTypes.string,
  timeoutId: PropTypes.shape(),
};

EmptyList.defaultProps = {
  filter: '',
  timeoutId: { current: null },
};

export default EmptyList;
