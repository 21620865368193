import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

/**
 * Text message component
 *
 * @param {object} props
 * @param {string} props.value
 * @param {number} props.rows
 * @param {number} props.maxLength
 * @param {function} props.onChange
 * @return {JSX.Element}
 */
function LinkMessage ({ value, onChange }) {

  const [ text, setText ] = useState(value);

  useEffect(() => {
    if (value !== text) setText(value);
  }, [ value ]);

  const onTextChange = (e) => setText(e.target.value);
  const onBlur = () => onChange(text);

  return (
    <Fragment>
      <Input
        placeholder="Enter URL"
        className="is-radiusless"
        onChange={onTextChange}
        onBlur={onBlur}
        value={text}
      />
    </Fragment>
  );
}

LinkMessage.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

LinkMessage.defaultProps = {
  value: '',
  onChange () {},
};

export default LinkMessage;
