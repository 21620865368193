export const SET = 'set';

export const ADD_MESSAGE = 'add-message';
export const REMOVE_MESSAGE = 'remove-answer';
export const CHANGE_MESSAGE_TYPE = 'change-message-type';
export const ON_CHANGE = 'on-change';
export const SWAP = 'swap';

export const ADD_GROUP = 'add-group';
export const REMOVE_GROUP = 'remove-group';

export const SET_RAW_DATA = 'SET_RAW_DATA';

export const INIT_GROUP = 'INIT_GROUP';

export const ADD_USER_DISPLAY_NAME = 'ADD_USER_DISPLAY_NAME';