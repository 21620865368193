import { fetchApi } from '~/utils';
import fetcher from '~/utils/fetcher';
import config from '~/config';

// intent service
function service () {
  const { API_VERSION } = config;

  return {
    /**
     * Find intents
     *
     * @param {any} params - url search params
     */
    find (params) {
      return fetcher.get(`${API_VERSION}/intents`, { params });
    },

    /**
     * Get existing intent
     *
     * @param {string | number} id - intent id
     * @param {any} params - url search params
     */
    get (id, params) {
      return fetcher.get(`${API_VERSION}/intents/${id}`, { params });
    },

    /**
     * Get fallback intents
     *
     * @param {any} params - url search params
     */
    getFallback (params) {
      return fetcher.get(`${API_VERSION}/intents`, {
        params: { ...params, type: 'fallback-intents' },
      });
    },
  };
}

/**
 * Find intents
 *
 * @param {any} query
 * @return {Promise<any>}
 */
function findIntents (query) {
  return fetchApi(`intents?${query}`, 'GET');
}

/**
 * Get specific intent
 *
 * @param {string | number} id
 * @param {any} query
 * @return {Promise<any>}
 */
function getIntent (id, query) {
  const q = new URLSearchParams(query);

  return fetchApi(`intents/${id}?${q.toString()}`, 'GET');
}

/**
 * Create intents
 *
 * @param {any} data
 * @param {any} query
 * @return {Promise<any>}
 */
function createIntent (data, query) {
  const q = new URLSearchParams(query);

  return fetchApi(`intents?${q.toString()}`, 'POST', data);
}

/**
 * Update intents
 *
 * @param {string | number} id
 * @param {any} data
 * @param {any} query
 * @return {Promise<any>}
 */
function updateIntent (id, data, query) {
  const q = new URLSearchParams(query);

  return fetchApi(`intents/${id}?${q.toString()}`, 'PATCH', data);
}

/**
 * Delete intents
 *
 * @param {string | number} id
 * @param {any} query
 * @return {Promise<any>}
 */
function deleteIntent (id, query) {
  const q = new URLSearchParams(query);

  return fetchApi(`intents/${id}?${q.toString()}`, 'DELETE');
}

export const IntentService = service();
export {
  findIntents,
  getIntent,
  createIntent,
  updateIntent,
  deleteIntent,
};
