import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { DatePicker, TimePicker } from 'antd';

import dayjs from '~/utils/dayjs';

/**
 * Date time picker component
 *
 * @param {object} props
 * @param {{ date: string, time: string }} props.format
 * @param {{ date: string, time: string }} props.placeholder
 * @param {string} props.value
 * @param {{ date: function }} props.disabled
 * @param {function} props.onChange
 * @return {JSX.Element}
 */
function DateTimePicker ({ format, placeholder, value, disabled, onChange }) {
  const [ date, setDate ] = useState(dayjs().format(format.date));
  const [ time, setTime ] = useState(dayjs().format(format.time));

  /**
   * on date changed event handler
   *
   * @param {any} _ - moment object
   * @param {string} dateStr - date string
   * @return {void}
   */
  const handleOnDateChanged = (_, dateStr) => {
    setDate(dateStr);

    onChange(`${dateStr} ${time}`);
  };

  /**
   * on time changed event handler
   *
   * @param {any} _ - moment object
   * @param {string} timeStr - time string
   * @return {void}
   */
  const handleOnTimeChanged = (_, timeStr) => {
    setTime(timeStr);

    onChange(`${date} ${timeStr}`);
  };

  return (
    <Fragment>
      <DatePicker
        style={{ marginRight: 10 }}
        format={format.date}
        placeholder={placeholder.date}
        disabledDate={disabled.date}
        onChange={handleOnDateChanged}
        value={value ? moment(value, `${format.date} ${format.time}`) : undefined}
      />

      <TimePicker
        format={format.time}
        placeholder={placeholder.time}
        onChange={handleOnTimeChanged}
        value={value ? moment(value, `${format.date} ${format.time}`) : undefined}
      />
    </Fragment>
  );
}

DateTimePicker.propTypes = {
  format: PropTypes.shape({ date: PropTypes.string, time: PropTypes.string }),
  placeholder: PropTypes.shape({ date: PropTypes.string, time: PropTypes.string }),
  value: PropTypes.string,
  disabled: PropTypes.shape({ date: PropTypes.func }),
  onChange: PropTypes.func,
};

DateTimePicker.defaultProps = {
  format: { date: 'DD/MM/YYYY', time: 'HH:mm' },
  placeholder: {},
  value: '',
  disabled: { date () {} },
  onChange () {},
};

export default DateTimePicker;
