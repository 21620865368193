import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { Link } from 'react-router-dom';
import { DatePicker, Tabs, Button, Row, Col, Table, Progress, Typography, Popconfirm, Tooltip, Icon, message } from 'antd';

import dayjs from '~/utils/dayjs';
import { PageTitle } from '~/components';

import { BroadcastService } from '~/services/broadcasts';
import { BroadcastsQuotaService } from '~/services/broadcasts-quota';
import { withRedux } from '~/hoc';

import { QuestionAction } from '~/actions/questions';

import './style.less';

const defaultFilter = {
  start: null,
  end: null,
};

const mapStateToProps = (state) => ({
  questions: _.get(state, 'service.questions', []),
});

const actionToProps = {
  find: QuestionAction.find,
};

class Broadcast extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      tabActive: 'schedule',
      draftBroadcasts: [],
      pendingBroadcasts: [],
      sentBroadcasts: [],
      failedBroadcasts: [],
      filteredDraftBroadcasts: [],
      filteredPendingBroadcasts: [],
      filteredSentBroadcasts: [],
      filteredFailedBroadcasts: [],
      filter: defaultFilter,
      quota: { sent: 0, limit: 0 },
    };
  }

  // ======================================================
  // Commit Phase
  // Can work with DOM, run side effects, schedule updates.
  // ======================================================
  componentDidMount = async () => {
    this.fetchData();
    this.fetchQuestion();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {};

  componentWillUnmount = () => {};

  // ======================================================
  // Pre-commit Phase
  // Can read the DOM
  // ======================================================
  getSnapshotBeforeUpdate = (prevProps, prevState) => ({});

  // ======================================================
  // Render Phase
  // Pure and no side effects. May be paused, aborted or restarted by React.
  // ======================================================
  static getDerivedStateFromProps (props, state) {
    return {};
  }

  // ======================================================
  // Private Methods
  // ======================================================
  /**
   * Fetch
   *
   * @param {any} opts
   * @param {'find' | 'fetch'} func
   * @return {void}
   */
  fetchQuestion = () => {
    const { actions } = this.props;
    actions.find({ $select: [ 'id', 'title' ], '$sort[id]': 1 });
  };

  fetchData = async () => {
    const { data: draftBroadcastsResponse } = await BroadcastService.findByStatus('draft');
    const { data: pendingBroadcastsResponse } = await BroadcastService.findByStatus('pending');
    const { data: sentBroadcastsResponse } = await BroadcastService.findByStatus('sent');
    const { data: quota } = await BroadcastsQuotaService.get();

    const failedBroadcasts = [];
    this.setState({
      draftBroadcasts: draftBroadcastsResponse, // _.get(draftBroadcastsResponse, 'data', []),
      pendingBroadcasts: pendingBroadcastsResponse, // _.get(pendingBroadcastsResponse, 'data', []),
      sentBroadcasts: sentBroadcastsResponse, // _.get(sentBroadcastsResponse, 'data', []),
      failedBroadcasts,
      filteredDraftBroadcasts: draftBroadcastsResponse, // _.get(draftBroadcastsResponse, 'data', []),
      filteredPendingBroadcasts: pendingBroadcastsResponse, // _.get(pendingBroadcastsResponse, 'data', []),
      filteredSentBroadcasts: sentBroadcastsResponse, // _.get(sentBroadcastsResponse, 'data', []),
      filteredFailedBroadcasts: failedBroadcasts,
      quota,
    });
  }

  getEmptyDataText = () => {
    const { tabActive } = this.state;

    switch (tabActive) {
      case 'schedule':
        return 'You don\'t have any broadcasts scheduled right now. To create a new broadcast, click "Create new" in the top right.';
      case 'draft':
        return 'You don\'t have any drafts saved right now. To create a draft broadcast, click "Create new" in the top right.';
      case 'sent':
        return 'No sent broadcasts yet!';
      case 'failed':
        return 'No failed broadcasts yet!';
      default:
        break;
    }
  }

  sendDraftBroadcast = async (id) => {
    try {
      this.setState({ loading: true });

      await BroadcastService.publish(id);
      await this.fetchData();

      message.success('Send draft broadcast successful');
    } catch (error) {
      message.error(error.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  getTableColumn = () => {
    const { tabActive } = this.state;
    const columns = [
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
      },
      {
        title: 'Target',
        dataIndex: 'target',
        key: 'target',
      },
      {
        title: 'Recipients',
        dataIndex: 'recipients',
        key: 'recipients',
      },
      {
        title: 'Criteria',
        dataIndex: 'criteria',
        key: 'criteria',
      },
      {
        title: 'Broadcast time',
        key: 'time',
        dataIndex: 'time',
        render: (text) => <span>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
      },
      {
        title: 'Created At',
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (text) => <span>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
      },
    ];
    if (_.includes([ 'schedule', 'draft' ], tabActive)) {
      columns.push({
        key: 'id',
        dataIndex: 'id',
        render: (id) => (
          <div className="button-group">
            {tabActive === 'draft' && (
              <Fragment>
                <Popconfirm
                  title="Send the draft broadcast?"
                  onConfirm={() => this.sendDraftBroadcast(id)}
                  okText="Send"
                  cancelText="No"
                >
                  <Button type="primary">Send</Button>
                </Popconfirm>

                <Tooltip title="Update">
                  <Link to={`/broadcasts/edit/${id}`}>
                    <Button type="primary" icon="edit" />
                  </Link>
                </Tooltip>
              </Fragment>
            )}

            <Popconfirm
              title="Are you sure delete this broadcast?"
              onConfirm={() => this.handleDelete(id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="danger" icon="delete" />
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      });
    }
    return columns;
  }

  onChangeTab = (key) => {
    this.setState({
      tabActive: key,
    });
  }

  handleDatePickerChange = (key, date) => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        [key]: date,
      },
    });
  }

  filterByDateRange = (broadcast) => {
    const { filter } = this.state;
    const { start, end } = filter;

    if (start === null && end === null) return true;

    const broadcastMoment = dayjs(broadcast.broadcastTime);

    if (start !== null && end === null) {
      const isSameOrAfterStart = broadcastMoment.isSameOrAfter(dayjs(start.startOf('day').format('YYYY-MM-DD HH:mm:ss')));
      return isSameOrAfterStart;
    } if (start === null && end !== null) {
      const isSameOrBeforeEnd = broadcastMoment.isSameOrBefore(dayjs(end.endOf('day').format('YYYY-MM-DD HH:mm:ss')));
      return isSameOrBeforeEnd;
    }

    const isSameOrAfterStart = broadcastMoment.isSameOrAfter(dayjs(start.startOf('day').format('YYYY-MM-DD HH:mm:ss')));
    const isSameOrBeforeEnd = broadcastMoment.isSameOrBefore(dayjs(end.endOf('day').format('YYYY-MM-DD HH:mm:ss')));
    return isSameOrAfterStart && isSameOrBeforeEnd;
  }

  handleDelete = async (id) => {
    let msg = '';

    try {
      await BroadcastService.remove(id);
      msg = 'Broadcast was deleted successfully.';
      message.success(msg);
      this.fetchData();
    } catch (err) {
      console.error(err);
      msg = 'Something went wrong.';
      message.error(msg);
    }
  }

  handleSearch = () => {
    const {
      draftBroadcasts,
      pendingBroadcasts,
      sentBroadcasts,
      failedBroadcasts,
    } = this.state;

    this.setState({
      filteredDraftBroadcasts: _.filter(draftBroadcasts, this.filterByDateRange),
      filteredPendingBroadcasts: _.filter(pendingBroadcasts, this.filterByDateRange),
      filteredSentBroadcasts: _.filter(sentBroadcasts, this.filterByDateRange),
      filteredFailedBroadcasts: _.filter(failedBroadcasts, this.filterByDateRange),
    });
  }

  handleClearSearch = () => {
    this.setState({
      filter: defaultFilter,
    }, () => {
      this.handleSearch();
    });
  }

  renderTab = (tabKey) => {
    const { questions } = this.props;
    // const { filter } = this.state;
    // const { start, end } = filter;
    const {
      filteredDraftBroadcasts,
      filteredPendingBroadcasts,
      filteredSentBroadcasts,
      filteredFailedBroadcasts,
      loading,
    } = this.state;
    let rawArray = [];
    switch (tabKey) {
      case 'schedule':
        rawArray = filteredPendingBroadcasts;
        break;
      case 'draft':
        rawArray = filteredDraftBroadcasts;
        break;
      case 'sent':
        rawArray = filteredSentBroadcasts;
        break;
      case 'failed':
        rawArray = filteredFailedBroadcasts;
        break;
      default:
        break;
    }

    const dataSource = _.chain(rawArray).sortBy((item) => item.id * -1).map((item) => ({
      id: item.id,
      message: _.chain(item).get('messages', []).map((message) => {
        if (message.type === 'text') return <p>{message.text}</p>;
        if (message.type === 'image') return <img style={{ maxWidth: 50, width: '100%', height: 'auto' }} src={message.originalContentUrl} alt="" />;
        if (message.type === 'video') return <video style={{ maxWidth: 50, width: '100%', height: 'auto' }} src={message.originalContentUrl} alt="" />;
        if (message.type === 'imagemap') {
            return (
              <a target="_blank" rel="noreferrer" href={message.actions[0].linkUri}>
                <img style={{ maxWidth: 50, width: '100%', height: 'auto' }} src={message.baseUrl} alt="" />
              </a>
            );
        }
      }).value(),
      target: 'Targeting',
      recipients: _.get(item, 'totalReceiver', 0),
      criteria: _.chain(item).get('criterias', {}).reduce((acc, values, key) => {
        if (!_.isEmpty(values)) {
          if (key === 'gender' || key === 'age') {
            return [
              ...acc,
              `${key}: ${_.join(values, ' / ')}`,
            ];
          }
          // convert choice id into question
          const targetQuestions = _.compact(_.map((values, (choiceId) => _.find(questions, (question) => _.includes(question.choices.map('id'), choiceId)))))

          const properties = _.reduce(targetQuestions, (acc2, choices, questionId) => {
            const question = _.find(questions, (q) => `${q.id}` === `${questionId}`);
            const textChoices = choices.map((choiceId) => {
              const targetChoice = _.find(question.choices || [], (questionChoice) => `${questionChoice.id}` === `${choiceId}`);
              return targetChoice && targetChoice.title.en;
            });
            return [
              ...acc2,
              `${question.title.en}: ${textChoices.join(' / ')}`,
            ];
          }, []);
          return [
            ...acc,
            ...properties,
          ];
        }
        return acc;
      }, [])
        .join('\n')
        .value(),
      time: _.get(item, 'broadcastTime', ''),
      createdAt: _.get(item, 'createdAt', ''),
    })).value();

    return (
      <div>
        <Row>
          <Col span={12} offset={12}>
            <div className="schedule-action">
              <DatePicker onChange={this.handleDatePickerChange.bind(this, 'start')} style={{ marginRight: 10 }} />
              <div className="to">~</div>
              <DatePicker onChange={this.handleDatePickerChange.bind(this, 'end')} style={{ marginRight: 10 }} />
              <Button onClick={this.handleSearch} type="primary" icon="search" />
              <Button onClick={this.handleClearSearch} className="cls-button" type="secondary">Clear</Button>
            </div>
          </Col>
        </Row>
        <br />
        <Table
          loading={loading}
          columns={this.getTableColumn()}
          dataSource={dataSource}
          locale={{
            emptyText: this.getEmptyDataText(),
          }}
          rowKey={({ id }) => id}
        />
      </div>
    );
  }

  render () {
    const { quota } = this.state;

    return (
      <div className="app-screen">
        <PageTitle
          title="Broadcasts"
          subtitle="Create, edit, and send messages."
          extra={(
            <Link to="/broadcasts/create">
              <Button type="primary">Create new</Button>
            </Link>
          )}
        />

        <div>
          <Typography.Text>Messages sent this month</Typography.Text>
          <br />
          <Typography.Text>{`Free messages sent: ${quota.sent}/${quota.limit}`}</Typography.Text>
          <Progress percent={(quota.sent / quota.limit) * 100} showInfo={false} />
        </div>
        <br />
        <Tabs animated={false} defaultActiveKey="schedule" onChange={this.onChangeTab}>
          <Tabs.TabPane tab="Scheduled" key="schedule">
            {this.renderTab('schedule')}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Drafts" key="draft">
            {this.renderTab('draft')}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Sent" key="sent">
            {this.renderTab('sent')}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Failed" key="failed">
            {this.renderTab('failed')}
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

Broadcast.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.shape({
    find: PropTypes.func.isRequired,
  }).isRequired,
};
Broadcast.defaultProps = {};

export default withRedux(mapStateToProps, actionToProps)(Broadcast);
