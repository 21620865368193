import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DatePicker, Divider, Button, Table, Tag, Icon, Input, notification } from 'antd';

import { PageTitle } from '~/components';

import { ChatAction } from '~/actions/chats';

import { JSONToCSVConvertor } from '~/utils';

import './style.less';

const dateFormat = 'DD/MM/YYYY';
const dateTimeFormat = 'YYYY-MM-DD HH:mm';
const { RangePicker } = DatePicker;

/**
 * Rich menu list page
 *
 * @return {JSX.Element}
 */
function RichMenuList () {
  const { chats, error, loading } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const [ dateStartMoment, setDateStartMoment ] = useState(moment().subtract(7, 'days'));
  const [ dateEndMoment, setDateEndMoment ] = useState(moment());
  /**
   * Column setting of antd table
   *
   * @constant
   */
  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 180,
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Type',
      dataIndex: 'event.type',
      key: 'event.type',
      width: 100,
    },
    {
      title: 'Response',
      dataIndex: 'event',
      key: 'event',
      width: 350,
      render: (event) => {
        switch (event?.type) {
          default:
            return event?.type || '';
          case 'text':
            return event?.text || '';
          case 'flex': {
            return event?.altText || '';
          }
        }
      },
    },
    {
      title: 'Post by',
      dataIndex: 'displayName',
      key: 'displayName',
      width: 150,
    },
    {
      title: 'Request by',
      dataIndex: 'cusDisplayName',
      key: 'cusDisplayName',
      width: 150,
    },
  ];

  useEffect(() => {
    if (error) notification.error({ message: 'Oops, something went wrong.', description: error.message });
  }, [ error ]);

  useEffect(() => {
    const dateStart = dateStartMoment.startOf('day').format(dateTimeFormat);
    const dateEnd = dateEndMoment.endOf('day').format(dateTimeFormat);
    dispatch(ChatAction.find({ dateStart, dateEnd }));
  }, [ dateStartMoment.format(dateTimeFormat), dateEndMoment.format(dateTimeFormat) ]);

  const handleExportCsv = () => {
    const dateStart = dateStartMoment.format(dateTimeFormat);
    const dateEnd = dateEndMoment.format(dateTimeFormat);
    const dataToExcel = _.map(chats, (chat) => {
      let response = '';
      switch (chat?.event?.type) {
        default:
          response = chat?.event?.type || '';
          break;
        case 'text':
          response = chat?.event?.text || '';
          break;
        case 'flex': {
          response = chat?.event?.altText || '';
          break;
        }
      }
      return {
        Timestamp: moment(chat.createdDate).format('YYYY-MM-DD HH:mm'),
        Type: chat.event?.type,
        'Response': response,
        'Post by': chat?.displayName,
        'Request by': chat?.cusDisplayName,
      };
    });
    JSONToCSVConvertor(dataToExcel, `chat_history_${dateStart}_${dateEnd}`, true);
  };

  const onChange = (value, dateString) => {
    const [ changedDateStartMoment, changedDateEndMoment ] = value;
    setDateStartMoment(changedDateStartMoment);
    setDateEndMoment(changedDateEndMoment);
  };

  return (
    <div className="rich-menu-page">
      <div className="page-title-container">
        <PageTitle
          title="Chats"
          extra={(
            <div>
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                defaultValue={[ dateStartMoment, dateEndMoment ]}
                placeholder={[ 'Start Time', 'End Time' ]}
                onChange={onChange}
                format={dateFormat}
              />
              <Button style={{ marginLeft: 10 }} type="primary" onClick={handleExportCsv}>Export CSV</Button>
            </div>
          )}
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <div>{`Total chats (${chats.length}) messages`}</div>
        <br />
        <Table
          dataSource={chats}
          columns={columns}
          loading={loading}
          showHeader
          bordered
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
}

export default RichMenuList;
