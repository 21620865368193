import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash.isempty';
import compact from 'lodash.compact';
import { Form } from 'antd';

import { List } from '~/components';

import { CriteriaCard } from './components';

/**
 * Criteria list component
 *
 * @param {object} props
 * @param {object[]} props.criterias
 * @param {function} props.onRemove
 * @return {JSX.Element}
 */
function CriteriaList ({ criterias, values, onRemove }) {
  /**
   * Basic criteria transform
   *
   * @param {object[]} result - result of transformed data
   * @param {'age' | 'gender'} target - criteria property target
   */
  const basicCriteriaTransform = (result, target) => {
    const displayValues = criterias[target].values.map((item) => {
      if (values[target].includes(item.value)) return item.label;

      return '';
    });

    result.push({
      id: target,
      title: criterias[target].title,
      values: compact(displayValues),
    });
  };

  /**
   * Properties criteria transform
   *
   * @param {object[]} result - result of transformed data
   * @param {object} properties - 'properties' property of criteria
   */
  const propertiesCriteriaTransform = (result, properties) => {
    Object.entries(properties).forEach(([ key, answers ]) => {
      const displayValue = answers.map((answer) => {
        const targetValue = criterias.properties[key].values.find((value) => answer === value.value);
        if (targetValue) return targetValue.label;
        return '';
      });
      result.push({
        id: key,
        title: criterias.properties[key].title,
        values: compact(displayValue),
      });
    });
  };

  const transformed = useMemo(() => {
    const result = [];

    if (!isEmpty(values.age)) basicCriteriaTransform(result, 'age');
    if (!isEmpty(values.gender)) basicCriteriaTransform(result, 'gender');
    if (!isEmpty(values.properties)) propertiesCriteriaTransform(result, values.properties);

    return result;
  }, [ values ]);

  return (
    <List
      container={Form.Item}
      items={transformed}
      keyExtractor={(item) => item.id}
      renderSeparator={() => <div style={{ height: 15 }} />}
      render={(item) => (
        <CriteriaCard
          id={item.id}
          title={item.title}
          values={item.values}
          onRemove={onRemove}
        />
      )}
    />
  );
}

CriteriaList.propTypes = {
  criterias: PropTypes.shape(),
  values: PropTypes.shape(),
  onRemove: PropTypes.func,
};

CriteriaList.defaultProps = {
  criterias: {},
  values: {},
  onRemove () {},
};

export default CriteriaList;
