import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Dashboard,
  Layout,
  Broadcast,
  Intents,
  RichMenu,
  Question,
  GreetingMessages,
  Chats,
  Training,
  Service,
  Staff,
  Insight,
  InsightSummary,
} from '~/pages';
import resources from './resources';

export default [
  {
    exact: true,
    path: '/',
    component: Dashboard,
    privateRoute: true,
  },
  {
    exact: true,
    path: '/members',
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'members' ],
    title: 'Members',
    ...resources.members,
  },
  {
    exact: true,
    path: '/members/save/:id?',
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'members' ],
    title: 'Member',
    ...resources.members,
    redirectPath: '/members',
    isView: true,
  },
  {
    exact: true,
    path: '/broadcasts',
    component: Broadcast.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'broadcasts' ],
    title: 'Broadcasts',
    ...resources.broadcasts,
  },
  {
    exact: true,
    path: '/broadcasts/create',
    component: Broadcast.Save,
    privateRoute: true,
    title: 'Broadcast',
    ...resources.broadcasts,
  },
  {
    exact: true,
    path: '/broadcasts/edit/:id',
    component: Broadcast.Save,
    privateRoute: true,
    title: 'Broadcast',
    ...resources.broadcasts,
  },
  {
    exact: true,
    path: '/intents',
    component: Intents.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'intents' ],
    title: 'Intents',
    ...resources.intents,
  },
  {
    exact: true,
    path: '/intents/create',
    component: Intents.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'intents' ],
    title: 'Intents',
    ...resources.intents,
  },
  {
    exact: true,
    path: '/intents/edit/:id',
    component: Intents.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'intents' ],
    title: 'Edit intent',
  },
  {
    exact: true,
    path: '/intents/fallback',
    component: Intents.Fallback,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'intents' ],
    title: 'Fallback intent',
  },
  {
    exact: true,
    path: '/trainings',
    component: Training.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'trainings' ],
    title: 'Training',
  },
  {
    exact: true,
    path: '/rich-menus',
    component: RichMenu.List,
    privateRoute: true,
    subMenuActiveKey: [ 'rich-menus-List' ],
    menuActivekey: [ 'rich-menus' ],
    title: 'Rich Menu',
  },
  {
    exact: true,
    path: '/rich-menus/create',
    component: RichMenu.Create,
    privateRoute: true,
    subMenuActiveKey: [ 'rich-menus-create' ],
    menuActivekey: [ 'rich-menus' ],
    title: 'Create new rich menu',
  },
  {
    exact: true,
    path: '/rich-menus/edit/:id',
    component: RichMenu.Create,
    privateRoute: true,
    subMenuActiveKey: [ 'rich-menus-edit' ],
    menuActivekey: [ 'rich-menus' ],
    title: 'Edit rich menu',
  },
  {
    exact: true,
    path: '/greeting-messages',
    component: GreetingMessages.List,
    privateRoute: true,
    subMenuActiveKey: [ 'greeting-messages-list' ],
    menuActivekey: [ 'greeting-messages' ],
    title: 'Greetings',
  },
  {
    exact: true,
    path: '/greeting-messages/tier/:tierId',
    component: GreetingMessages.Save,
    privateRoute: true,
    subMenuActiveKey: [ 'greeting-messages-tier-messages-list' ],
    menuActivekey: [ 'greeting-messages' ],
    title: 'Create Greetings',
  },
  {
    exact: true,
    path: '/questions',
    component: Question.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'questions' ],
    title: 'questions',
    // ...resources.questions,
  },
  {
    exact: true,
    path: '/questions/create',
    component: Question.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'questions' ],
    title: 'Create questions',
    ...resources.questions,
  },
  {
    exact: true,
    path: '/questions/edit/:id',
    component: Question.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'questions' ],
    title: 'Edit questions',
  },
  {
    exact: true,
    path: '/chats',
    component: Chats.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'chats' ],
    title: 'Chats',
  },
  {
    exact: true,
    path: '/services',
    component: Service.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'services' ],
    title: 'services',
  },
  {
    exact: true,
    path: '/services/create',
    component: Service.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'services' ],
    title: 'Create services',
    ...resources.services,
  },
  {
    exact: true,
    path: '/services/edit/:id',
    component: Service.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'services' ],
    title: 'Edit services',
  },
  {
    exact: true,
    path: '/insight',
    component: Insight.List,
    privateRoute: true,
    subMenuActiveKey: [ 'insight' ],
    menuActiveKey: [ 'insight' ],
    title: 'Insight',
    ...resources.insight,
  },
  {
    exact: true,
    path: '/insight-summary',
    component: InsightSummary.List,
    privateRoute: true,
    subMenuActiveKey: [ 'insight-summary' ],
    menuActiveKey: [ 'insight-summary' ],
    title: 'Insight Summary',
    ...resources.insight,
  },
  {
    exact: true,
    path: '/staff',
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: [ 'staff-list' ],
    menuActiveKey: [ 'staff', 'staff-list' ],
    title: 'Staff list',
    ...resources.staff,
  },
  {
    exact: true,
    path: '/staff/save/:id',
    component: Staff.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [ 'staff' ],
    title: 'Staff',
    ...resources.staff,
    redirectPath: '/staff',
  },
  {
    exact: true,
    path: '/staff/create',
    component: Staff.Save,
    privateRoute: true,
    subMenuActiveKey: [ 'new-staff' ],
    menuActiveKey: [ 'staff', 'new-staff' ],
  },
  {
    component: () => <Redirect to={{ pathname: '/' }} />,
  },
];
