import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

// for clear console.log in production

if (process.env.NODE_ENV === 'production') {
  console.log = function () {}; // eslint-disable-line
  console.debug = function () {}; // eslint-disable-line
  console.error = function () {}; // eslint-disable-line
  console.warn = function () {}; // eslint-disable-line
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
