import * as ActionType from '~/actions/actionType';
import fetcher from '~/utils/fetcher';
import { AuthService } from '~/services/auth';

// Auth action
function action () {
  const { signin, check, revoke } = AuthService;

  return {
    /**
     * signin action
     *
     * @param {string} username
     * @param {string} password
     * @return {(dispatch: any) => void}
     */
    signin (username, password) {
      return async (dispatch) => {
        const { data } = await signin(username, password);

        window.localStorage.setItem('token', data.accessToken);
        fetcher.defaults.headers.Authorization = `Bearer ${data.accessToken}`;

        dispatch({ type: ActionType.RECEIVE_AUTH, data });
        dispatch({ type: ActionType.IS_LOGIN, isLogin: true });
      };
    },

    /**
     * Sign out
     *
     * @return {(dispatch: any) => void}
     */
    signout () {
      return async (dispatch) => {
        await revoke();

        window.localStorage.removeItem('token');
        delete fetcher.defaults.headers.Authorization;

        dispatch({ type: ActionType.IS_LOGIN, isLogin: false });
      };
    },

    /**
     * Authentication check
     *
     * @param {string} token - access token
     * @return {(dispatch: any) => void}
     */
    check (token) {
      return async (dispatch) => {
        try {
          const { data } = await check(token);

          dispatch({ type: ActionType.RECEIVE_AUTH, data });
          dispatch({ type: ActionType.IS_LOGIN, isLogin: true });
        } finally {
          dispatch({ type: ActionType.LOGIN_CHECKED, checked: true });
        }
      };
    },
  };
}

export default {};
export const AuthAction = action();
