import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { Card, Icon, Avatar } from 'antd';

import config from '~/config';
import { List } from '~/components';

import './styles.less';

/**
 * Preview message component
 *
 * @param {object} props
 * @param {object[]} props.messages
 * @return {JSX.Element}
 */
function PreviewMessage ({ messages }) {
  const [ expanded, setExpanded ] = useState(true);

  /**
   * Render preview message
   *
   * @return {JSX.Element}
   */
  const render = (message) => (
    <div className="message-container">
      <div className="chat-avatar">
        <Avatar size={48} src={config.PROJECT_ICON} />
      </div>

      <div className="chat-content">
        <h5 className="chat-name">{config.PROJECT_NAME}</h5>

        {message.type === 'text' && (
          <p className="message is-bubble">{message.text}</p>
        )}

        {message.type === 'image' && (
          <div className="message is-rounded">
            <img src={message.originalContentUrl} alt={message.originalContentUrl} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={cx(
        'preview-message',
        { 'is-expanded': expanded },
      )}
    >
      <Card
        className="preview-card"
        headStyle={{ backgroundColor: '#353a40' }}
        title={(
          <span
            className="header"
            role="button"
            onClick={() => setExpanded((prev) => !prev)}
            tabIndex={0}
            onKeyPress={() => {}}
          >
            <Icon style={{ marginRight: 5 }} type={expanded ? 'caret-down' : 'caret-up'} />
            Preview
          </span>
        )}
      >
        <List
          items={messages}
          keyExtractor={(_, index) => index.toString()}
          render={render}
        />
      </Card>
    </div>
  );
}

PreviewMessage.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()),
};

PreviewMessage.defaultProps = {
  messages: [],
};

export default PreviewMessage;
