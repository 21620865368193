import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { Collapse, Select, Form, Input, Tooltip, Icon, PageHeader, Radio } from 'antd';

import './style.less';

const types = [
  { value: 'uri', title: 'Link' },
  { value: 'message', title: 'Text' },
  { value: 'postback', title: 'Postback' },
];

const textMessageMaxLength = 50;
const uriLabelMaxLength = 50;

/**
 * Action form component
 *
 * @param {React.RefAttributes} ref
 * @param {Object} props
 * @param {any} props.form
 * @param {any} props.template
 * @param {any} props.formData - initial value
 * @return {JSX.Element}
 */
const ActionForm = forwardRef(({ form, template, formData }, ref) => {
  const [ activePanel, setActivePanel ] = useState([ 0 ]);
  const [ collapse, setCollapse ] = useState('expand');

  const { id: slug } = useParams();
  const isEditing = !_.isEmpty(slug);

  const alphabet = [ 'a', 'b', 'c', 'd', 'e', 'f' ];

  const handleCollapseChange = ({ target }) => {
    setCollapse(target.value);

    switch (target.value) {
      case 'expand': {
        const key = template.areas.map((item, index) => index.toString());
        return setActivePanel(key);
      }

      default:
      case 'collapse':
        return setActivePanel([]);
    }
  };

  // use with message action
  const renderTextAction = (index) => (
    <Form.Item
      wrapperCol={{ span: 18, offset: 6 }}
      help={form.getFieldError(`content.areas[${index}].action.text`) || `${(form.getFieldValue(`content.areas[${index}].action.text`) || '').length} / ${textMessageMaxLength}`}
    >
      {
        form.getFieldDecorator(`content.areas[${index}].action.text`, {
          ...(isEditing && { initialValue: formData.content.areas[index] ? formData.content.areas[index].action.text : undefined }),
          rules: [
            { required: true, message: 'Please enter text' },
            { max: textMessageMaxLength, message: `${(form.getFieldValue(`content.areas[${index}].action.text`) || '').length} / ${textMessageMaxLength}` },
          ],
        })(
          <Input.TextArea
            rows={4}
            placeholder="Enter any chat keywords or other text here (maximum 50 characters)."
            style={{ resize: 'none', borderRadius: 0 }}
          />,
        )
      }
    </Form.Item>
  );

  // use with uri action
  const renderLinkAction = (index) => (
    <Fragment>
      <Form.Item
        wrapperCol={{ span: 18, offset: 6 }}
      >
        {
          form.getFieldDecorator(`content.areas[${index}].action.uri`, {
            ...(isEditing && { initialValue: formData.content.areas[index] ? formData.content.areas[index].action.uri : undefined }),
            rules: [
              { required: true, message: 'Please enter URL' },
              { type: 'url', message: 'Hmm...that doesn\'t look like a URL.' },
            ],
          })(
            <Input placeholder="Enter URL" />,
          )
        }
      </Form.Item>

      <Form.Item
        label={(
          <span>
            Action Label &nbsp;
            <Tooltip title={<p style={{ textAlign: 'center', margin: 0 }}>The action label is shown on unsupported devices and read by text-to-speech services. (optional)</p>}>
              <Icon type="question-circle" style={{ color: '#999' }} />
            </Tooltip>
          </span>
        )}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        help={form.getFieldError(`content.areas[${index}].action.label`) || `${(form.getFieldValue(`content.areas[${index}].action.label`) || '').length} / ${uriLabelMaxLength}`}
      >
        {
          form.getFieldDecorator(`content.areas[${index}].action.label`, {
            ...(isEditing && { initialValue: formData.content.areas[index] ? formData.content.areas[index].action.label : undefined }),
            rules: [
              { max: uriLabelMaxLength, message: `${(form.getFieldValue(`content.areas[${index}].action.label`) || '').length} / ${uriLabelMaxLength}` },
            ],
          })(
            <Input.TextArea
              rows={4}
              placeholder="Enter message label for the action (optional)"
              style={{ resize: 'none', borderRadius: 0 }}
            />,
          )
        }
      </Form.Item>
    </Fragment>
  );

  // use with uri action
  const renderPostBackAction = (index) => (
    <Fragment>
      <Form.Item
        wrapperCol={{ span: 18, offset: 6 }}
      >
        {
          form.getFieldDecorator(`content.areas[${index}].action.data`, {
            ...(isEditing && { initialValue: formData.content.areas[index] ? formData.content.areas[index].action.data : undefined }),
            rules: [
              { required: true, message: 'Please enter PostBack Data' },
            ],
          })(
            <Input placeholder="Enter Query String" />,
          )
        }
      </Form.Item>
      {/* <Form.Item noStyle>
        {
          form.getFieldDecorator(`content.areas[${index}].action.label`, {
            initialValue: 'Postback',
          })(
            <Input type="hidden" />,
          )
        }
      </Form.Item> */}
    </Fragment>
  );

  const renderAction = (type, index) => {
    switch (type) {
      case 'uri':
        return renderLinkAction(index);

      case 'message':
        return renderTextAction(index);

      case 'postback':
        return renderPostBackAction(index);

      default:
        return <React.Fragment />;
    }
  };

  useImperativeHandle(ref, () => ({
    setActivePanel,
  }));

  useEffect(() => {
    const key = template.areas.map((item, index) => index.toString());

    if (_.isEmpty(activePanel)) setCollapse('collapse');
    if (_.isEqual(activePanel, key)) setCollapse('expand');
  }, [ activePanel ]);

  useEffect(() => {
    const key = template.areas.map((item, index) => index.toString());
    const { size } = template;

    const content = { size };
    form.setFieldsValue({ content });
    setActivePanel(key);
  }, [ template ]);

  return (
    <Fragment>
      <PageHeader
        className="is-horizontal-paddingless is-top-paddingless"
        title="Actions"
        extra={(
          <Radio.Group value={collapse} onChange={handleCollapseChange} buttonStyle="solid">
            <Radio.Button value="collapse">
              <Icon type="unordered-list" />
            </Radio.Button>

            <Radio.Button value="expand">
              <Icon type="border-outer" />
            </Radio.Button>
          </Radio.Group>
        )}
      />

      <Collapse
        className="action-form"
        activeKey={activePanel}
        onChange={setActivePanel}
      >
        {/* hidden input */}
        {form.getFieldDecorator('content.size.width', { ...(isEditing && { initialValue: formData.content.size.width }) })(<Input type="hidden" />)}
        {form.getFieldDecorator('content.size.height', { ...(isEditing && { initialValue: formData.content.size.height }) })(<Input type="hidden" />)}

        {
          (template.areas).map((item, index) => (
            <Collapse.Panel
              key={index.toString()}
              className="action-form-item"
              header={alphabet[index].toUpperCase()}
            >
              <Form.Item
                label="Action Type"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                {
                  form.getFieldDecorator(`content.areas[${index}].action.type`, {
                    ...(isEditing && { initialValue: formData.content.areas[index] ? formData.content.areas[index].action.type : undefined }),
                    rules: [{ required: true, message: 'Please select action type' }],
                  })(
                    <Select placeholder="Select action type" disabled={item.disabled}>
                      {types.map((type) => (
                        <Select.Option key={type.value} value={type.value}>{type.title}</Select.Option>
                      ))}
                    </Select>,
                  )
                }
              </Form.Item>

              {renderAction(form.getFieldValue(`content.areas[${index}].action.type`), index)}
            </Collapse.Panel>
          ))
        }
      </Collapse>
    </Fragment>
  );
});

ActionForm.propTypes = {
  form: PropTypes.shape().isRequired,
  template: PropTypes.shape(),
  formData: PropTypes.shape(),
};

ActionForm.defaultProps = {
  template: {
    size: {},
    areas: [{ title: 'a', disabled: true }],
  },
  formData: {},
};

export default ActionForm;
