import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Input, Typography } from 'antd';

/**
 * Text message component
 *
 * @param {object} props
 * @param {string} props.value
 * @param {number} props.rows
 * @param {number} props.maxLength
 * @param {function} props.onChange
 * @return {JSX.Element}
 */
function TextMessage ({ value, rows, maxLength, onChange }) {
  return (
    <Fragment>
      <Input.TextArea
        rows={rows}
        maxLength={maxLength}
        placeholder="Enter text"
        className="is-radiusless"
        value={value}
        onChange={onChange}
      />

      <Typography.Paragraph style={{ textAlign: 'right' }}>{`${value.length} / ${maxLength}`}</Typography.Paragraph>
    </Fragment>
  );
}

TextMessage.propTypes = {
  value: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

TextMessage.defaultProps = {
  value: '',
  rows: 6,
  maxLength: 500,
  onChange () {},
};

export default TextMessage;
