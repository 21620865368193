import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Divider, Button, Table, Tag, Icon, Modal, Input, notification } from 'antd';

import { PageTitle } from '~/components';
import { RichMenuAction } from '~/actions/richMenu';

import './style.less';


/**
 * Rich menu list page
 *
 * @return {JSX.Element}
 */
function RichMenuList () {
  const searchTimeoutRef = useRef(null);

  const { richMenus = [], error, loading } = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteRichMenu = (record) => {
    Modal.confirm({
      title: 'Are you absolutely sure?',
      content: `This action CANNOT be undone. This will permanently remove '${record.title}' rich menu.`,
      okText: 'Remove',
      okType: 'danger',
      async onOk () {
        await dispatch(RichMenuAction.remove(record.id));
        await dispatch(RichMenuAction.find());
      },
    });
  };

  /**
   * Column setting of antd table
   *
   * @constant
   */
  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 300,
      render: (image) => (
        <img src={image.src} alt={image.src} />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, item) => (
        <span>
          {title}
          {item.tier && <Tag color="green" style={{ marginLeft: 5 }}>{item.tier.title.en}</Tag>}
          {item.default && <Tag color="orange" style={{ marginLeft: 5 }}>default</Tag>}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (item) => (
        <span>
          <Icon type="edit" style={{ fontSize: 21 }} onClick={() => history.push(`/rich-menus/edit/${item.id}`)} />
          <Divider type="vertical" />
          <Icon type="delete" style={{ fontSize: 21, color: 'red' }} onClick={() => handleDeleteRichMenu(item)} />
        </span>
      ),
    },
  ];

  const handleSearch = ({ target }) => {
    const TIME = 500;

    if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);

    searchTimeoutRef.current = setTimeout(() => {
      searchTimeoutRef.current = undefined;
      dispatch(RichMenuAction.find({ 'title[$like]': `%${target.value}%` }));
    }, TIME);
  };

  useEffect(() => {
    if (error) notification.error({ message: 'Oops, something went wrong.', description: error.message });
  }, [ error ]);

  useEffect(() => {
    dispatch(RichMenuAction.find());
  }, []);

  return (
    <div className="rich-menu-page">
      <div className="page-title-container">
        <PageTitle
          title="Rich Menus"
          extra={(
            <Link to="/rich-menus/create">
              <Button type="primary">Create New</Button>
            </Link>
          )}
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <div className="input-container">
          <Input.Search
            size="large"
            placeholder="Search rich menu"
            onChange={handleSearch}
          />
        </div>

        <Table
          dataSource={richMenus}
          columns={columns}
          loading={loading}
          showHeader={false}
          bordered
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
}

export default RichMenuList;
