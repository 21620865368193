import axios from 'axios';
import config from '~/config';

// chat service
function service () {
  const { CHAT_SERVICE, CHANNEL_ID } = config;
  const fetcher = axios.create({ baseURL: `${CHAT_SERVICE}/${CHANNEL_ID}` });

  return {
    /**
     * Find chat history
     *
     * @param {any} params
     * @return {Promise<any>}
     */
    find ({ dateStart, dateEnd }) {
      return fetcher.post('data', { startDate: dateStart, endDate: dateEnd });
    },
  };
}

export default {};
export const ChatService = service();
