import { create } from '~/utils/fetcher';
import config from '~/config';

// chat service
function service () {
  const { STAT_SERVICE } = config;
  const fetcher = create(`${STAT_SERVICE}`);

  return {
    /**
     * Find chat history
     *
     * @param {any} params
     * @return {Promise<any>}
     */
    find () {
      return fetcher.get();
    },
  };
}

export default {};
export const StatService = service();
