import React, { Fragment, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Spin, Select, Icon, Upload, Card, Input, Button, Popover, Popconfirm, Form, Tooltip, Divider, Modal, message } from 'antd';

import { PageTitle } from '../../../components';

import * as IntentService from '../../../services/intents';
import { TrainingService } from '../../../services/trainings';

import * as ResponseMessage from '../lib/response-message-parser';
import { InitResponseMessageGroups, ResponseMessageReducer } from '../lib/local-reducer';
import * as LocalAction from '../lib/local-action-types';

import './style.less';


/**
 * API service url
 *
 * @type {string}
 * @constant
 * @private
 */
const _servicePrefix = process.env.REACT_APP_SERVICE;

/**
 * API version
 *
 * @type {string}
 * @constant
 * @private
 */
const _version = process.env.REACT_APP_API;

/**
 * Form item layout
 *
 * @constant
 * @private
 */
const _formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

/**
 * Default form data
 *
 * @constant
 * @private
 */
const _defaultFormData = {
  title: '',
  type: 'intents',
  keywords: [],
  answer: [],
  inputContext: [],
  outputContext: [],
};

/**
 * Create and update intent screen
 *
 * @param {{ form: any }} props
 * @return {JSX.Element}
 */
function CreateAndUpdateIntent ({ form }) {
  const [ loading, setLoading ] = useState(true);
  const [ uploading, setUploading ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ formData, setFormData ] = useState(_defaultFormData);
  const [ previewVisible, setPreviewVisible ] = useState();
  const [ responseMessageGroups, dispatch ] = useReducer(ResponseMessageReducer, InitResponseMessageGroups);

  const history = useHistory();
  const { id: slug } = useParams();
  const location = useLocation();

  const isEditing = !_.isEmpty(slug);

  const getTrainingProps = () => {
    const searchParams = new URLSearchParams(location.search);
    const trainingId = searchParams.get('trainingId');
    const keyword = searchParams.get('keyword');
    return {
      trainingId,
      keyword,
    };
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    form.validateFields(async (error, value) => {
      try {
        if (error) throw error;

        const data = { ...value, answer: ResponseMessage.parseArray(responseMessageGroups) };
        let msg = '';

        const { trainingId, keyword } = getTrainingProps();

        let createdIntentId = null;

        if (!isEditing) {
          const createdResponse = await IntentService.createIntent(JSON.stringify(data));
          createdIntentId = createdResponse.id;
          msg = 'Intent was created successfully.';
        } else {
          await IntentService.updateIntent(slug, JSON.stringify(data));
          msg = 'Intent was edited successfully.';
        }

        if (trainingId && keyword) {
          // patch training
          await TrainingService.update(trainingId, { intentId: createdIntentId });
        }

        setSubmitting(() => {
          message.success(msg);
          return false;
        });
        history.push({ pathname: '/intents', search: location.search });
      } catch (err) {
        setSubmitting(() => {
          console.error(err);
          message.error('Something went wrong.');

          return false;
        });
      }
    });
  };

  const handleDeleteIntent = async () => {
    try {
      setSubmitting(true);

      await IntentService.deleteIntent(slug);
      message.success('Intent was deleted successfully.');

      setSubmitting(false);
      history.push('/intents');
    } catch (error) {
      setSubmitting(() => {
        console.error(error);
        message.error('Something went wrong.');

        return false;
      });
    }
  };

  // use with 'image' and 'video' message
  const handleOnMediaMessageChange = (info, key, groupKey) => {
    const { file } = info;

    if (file.status === 'uploading') setUploading(true);
    if (info.file.status === 'done') {
      const link = file.response[0].src;

      setUploading(false);
      dispatch({
        type: LocalAction.ON_CHANGE,
        payload: { originalContentUrl: link, previewImageUrl: link },
        meta: { key, groupKey },
      });
    }
  };

  // support 'text' message type
  const renderTextMessage = (message, key, groupKey) => (
    <Input.TextArea
      rows={4}
      value={message.data.text}
      placeholder="Enter text"
      style={{ resize: 'none' }}
      onChange={(e) => dispatch({
        type: LocalAction.ON_CHANGE,
        payload: { text: e.target.value },
        meta: { key, groupKey },
      })}
    />
  );

  // support 'image' and 'video' message type
  const renderPreviewMediaMessage = (type, key, previewImageUrl) => {
    let el;

    switch (type) {
      case 'image':
        el = <img style={{ width: '100%' }} src={previewImageUrl} alt={previewImageUrl} />;
        break;

      case 'video':
        el = <video style={{ width: '100%' }} src={previewImageUrl} alt={previewImageUrl} controls />;
        break;

      default:
        console.debug('preview media should identify type');
        break;
    }

    return (
      <Modal footer={null} visible={previewVisible === key} onCancel={() => setPreviewVisible(undefined)}>
        {el}
      </Modal>
    );
  };

  // support 'image' and 'video' message type
  const renderMediaMessage = (message, key, groupKey) => {
    const { type, data } = message;
    const { previewImageUrl } = data;

    const defaultFileList = [];
    if (previewImageUrl) defaultFileList.push({ uid: '1', name: 'image.png', status: 'done', url: previewImageUrl });

    const renderSupportTypes = (formats, size, width) => (
      <Fragment>
        <div>
          <small>
            {`File formats: `}
            {formats}
          </small>
        </div>
        {width && (
          <div>
            <small>
              {`width: `}
              {width}
            </small>
          </div>
        )}
        <div>
          <small>
            {`Max file size: `}
            {size}
          </small>
        </div>
      </Fragment>
    );

    return (
      <Fragment>
        <Upload
          name="file"
          key={`file-${key}`}
          accept={`${type}/*`}
          listType="picture-card"
          defaultFileList={defaultFileList}
          headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
          action={`${_servicePrefix}/${_version}/medias`}
          onPreview={() => setPreviewVisible(key)}
          onChange={(info) => handleOnMediaMessageChange(info, key, groupKey)}
          onRemove={() => dispatch({
            type: LocalAction.ON_CHANGE,
            payload: { originalContentUrl: '', previewImageUrl: '' },
            meta: { key, groupKey },
          })}
        >
          {_.isEmpty(previewImageUrl) && (
            <Fragment>
              <Icon type={uploading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">{uploading ? 'Uploading...' : 'Upload'}</div>
            </Fragment>
          )}
        </Upload>
        {!_.isEmpty(previewImageUrl) && renderPreviewMediaMessage(type, key, previewImageUrl)}
        {type === 'image' && renderSupportTypes('JPG, JPEG, PNG', '1 MB', '472px')}
        {type === 'video' && renderSupportTypes('MP4', '10 MB')}
      </Fragment>
    );
  };

  const renderResponseMessage = (message, key, groupKey) => {
    const { type } = message;
    const keys = Object.keys(responseMessageGroups[groupKey]);

    const oneMessageLeft = keys.length === 1;
    const firstOfList = keys.indexOf(key) === 0;
    const lastOfList = keys.indexOf(key) === keys.length - 1;

    return (
      <Form.Item key={key}>
        <Card
          type="inner"
          extra={(
            <Button.Group>
              <Popover content="Move Up">
                <Button
                  icon="up"
                  type="link"
                  disabled={oneMessageLeft || firstOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'up' } })}
                />
              </Popover>
              <Popover content="Move Down">
                <Button
                  icon="down"
                  type="link"
                  disabled={oneMessageLeft || lastOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'down' } })}
                />
              </Popover>
              <Popconfirm
                placement="top"
                title="Delete this message?"
                onConfirm={() => dispatch({ type: LocalAction.REMOVE_MESSAGE, meta: { key, groupKey } })}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneMessageLeft}
              >
                <Button disabled={oneMessageLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
          title={(
            <Button.Group>
              <Popover content="Text">
                <Button
                  disabled={type !== 'text' && !ResponseMessage.isEmpty(message)}
                  type={type === 'text' ? 'primary' : 'link'}
                  icon="message"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'text', data: { text: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Image">
                <Button
                  disabled={type !== 'image' && !ResponseMessage.isEmpty(message)}
                  type={type === 'image' ? 'primary' : 'link'}
                  icon="picture"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'image', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Video">
                <Button
                  disabled={type !== 'video' && !ResponseMessage.isEmpty(message)}
                  type={type === 'video' ? 'primary' : 'link'}
                  icon="video-camera"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'video', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
            </Button.Group>
          )}
        >
          {type === 'text' && renderTextMessage(message, key, groupKey)}
          {(type === 'image' || type === 'video') && renderMediaMessage(message, key, groupKey)}
        </Card>
      </Form.Item>
    );
  };

  const renderResponseMessageGroup = ([ groupKey, group ]) => {
    const entriesGroup = Object.entries(group);
    const oneGroupLeft = Object.keys(responseMessageGroups).length === 1;

    return (
      <Form.Item key={groupKey}>
        <Card
          loading={loading}
          title="Response Message Group"
          extra={(
            <Button.Group>
              <Popconfirm
                placement="top"
                title="Delete this response message group?"
                onConfirm={() => dispatch({ type: LocalAction.REMOVE_GROUP, meta: { groupKey } })}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneGroupLeft}
              >
                <Button disabled={oneGroupLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
        >
          {entriesGroup.map(([ key, message ]) => renderResponseMessage(message, key, groupKey))}
          {entriesGroup.length <= 5 && (
            <Button
              type="dashed"
              size="large"
              block
              onClick={() => dispatch({ type: LocalAction.ADD_MESSAGE, meta: { groupKey } })}
            >
              Add Message
            </Button>
          )}
        </Card>
      </Form.Item>
    );
  };

  useEffect(() => {
    const init = async () => {
      try {
        const data = await IntentService.getIntent(slug);
        setFormData(data);
        dispatch({ type: LocalAction.SET, payload: ResponseMessage.parseObject(data.answer) });
        setLoading(false);
      } catch (error) {
        setLoading(() => {
          message.error('Something went wrong');
          // console.log(error);
          return false;
        });
      }
    };

    if (slug) {
      init();
    } else {
      setLoading(false);
    }
  }, [ slug ]);

  useEffect(() => {
    const { trainingId, keyword } = getTrainingProps();
    if (trainingId && keyword) {
      // set default keyword input
      setFormData({
        ...formData,
        title: keyword,
        keywords: [ keyword ],
      });
    }
  }, [ location ]);

  return (
    <div className="new-intent-page">
      <Spin spinning={loading} tip="loading...">
        <div className="page-title-container">
          <PageTitle
            title={isEditing ? 'Edit Intent' : 'New Intent'}
            extra={(
              <Fragment>
                {
                  isEditing && (
                    <Popconfirm
                      title="Are you sure delete this intent?"
                      onConfirm={handleDeleteIntent}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Delete">
                        <Button type="danger" style={{ marginRight: 10 }}>
                          Delete
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  )
                }
                <Button key="save" type="primary" htmlType="submit" loading={submitting} form="form">
                  {submitting ? 'Saving...' : 'Save'}
                </Button>
              </Fragment>
            )}
          />
        </div>

        <Divider />

        <div className="page-content-container">
          <Form
            id="form"
            layout="horizontal"
            onSubmit={handleSubmit}
            hideRequiredMark
          >
            <Form.Item label="Intent name" {..._formItemLayout}>
              {form.getFieldDecorator('title', {
                initialValue: formData.title,
                rules: [
                  { required: true, message: 'Please input intent name' },
                ],
              })(
                <Input
                  placeholder="Intent name"
                />,
              )}
            </Form.Item>
            <Form.Item label="Input Context" {..._formItemLayout}>
              {form.getFieldDecorator('inputContext', {
                initialValue: formData.inputContext,
              })(
                <Select
                  mode="tags"
                  placeholder="Add input context"
                  tokenSeparators={[ ',' ]}
                />,
              )}
            </Form.Item>
            <Form.Item label="Output Context" {..._formItemLayout}>
              {form.getFieldDecorator('outputContext', {
                initialValue: formData.outputContext,
              })(
                <Select
                  mode="tags"
                  placeholder="Add output context"
                  tokenSeparators={[ ',' ]}
                />,
              )}
            </Form.Item>
            <Form.Item
              label="Keyword"
              help={(
                <span>
                  The keywords will be tokenised after save.
                  <a href="https://www.bualabs.com/archives/3740/python-word-tokenize-pythainlp-example-algorithm-deepcut-newmm-longest-python-pythainlp-ep-2/" rel="noopener noreferrer" target="_blank">learn more</a>
                </span>
)}
              {..._formItemLayout}
            >
              {form.getFieldDecorator('keywords', {
                initialValue: formData.keywords,
                rules: [
                  { required: true, message: 'Please add at least one keyword', type: 'array' },
                ],
              })(
                <Select
                  mode="tags"
                  placeholder="Add keywords"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {
                form.getFieldDecorator('type', {
                  initialValue: formData.type,
                  rules: [{ required: true, message: 'Please use intent type as \'intents\'' }],
                })(<Input type="hidden" />)
              }
            </Form.Item>
            {Object.entries(responseMessageGroups).map(renderResponseMessageGroup)}
            {Object.keys(responseMessageGroups).length < 5 && (
              <Form.Item>
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => dispatch({ type: LocalAction.ADD_GROUP })}
                >
                  Add Response Group
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </Spin>
    </div>
  );
}

CreateAndUpdateIntent.propTypes = {
  form: PropTypes.shape().isRequired,
};

CreateAndUpdateIntent.defaultProps = {};

export default Form.create()(CreateAndUpdateIntent);
