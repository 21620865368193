import omit from 'lodash.omit'
;
import fetcher from '~/utils/fetcher';
import config from '~/config';

// broadcasts service
function service () {
  const endpoint = 'broadcasts';
  const { API_VERSION } = config;

  return {
    /**
     * Find broadcast by status
     *
     * @param {'draft' | 'pending' | 'sent'}
     */
    findByStatus (status) {
      const params = { status, $populate: [ 'items' ] };

      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },

    /**
     * Publish the draft broadcast
     *
     * @param {string | number} id - broadcast id
     */
    publish (id) {
      return fetcher.patch(`${API_VERSION}/${endpoint}/${id}/publishes`);
    },

    /**
     * Find broadcast by id
     *
     * @param {string | number} id - broadcast id
     */
    findById (id) {
      const params = { $populate: [ 'items' ] };

      return fetcher.get(`${API_VERSION}/${endpoint}/${id}`, { params });
    },

    /**
     * Create new broadcast
     *
     * @param {any} data
     * @param {any} params
     */
    create (data, params) {
      const body = omit(data, 'targeting');

      if (data.targeting === 'criteria') {
        return fetcher.post(`${API_VERSION}/criterias/${endpoint}`, body, { params });
      }

      return fetcher.post(`${API_VERSION}/all-friends/${endpoint}`, body, { params });
    },

    /**
     * Update the target broadcast based on provided id
     *
     * @param {string | number} id
     * @param {object} data
     * @param {object} params
     */
    update (id, data, params) {
      return fetcher.patch(`${API_VERSION}/${endpoint}/${id}`, data, { params });
    },

    /**
     * Remove question
     *
     * @param {string | number} id
     */
    remove (id) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/${id}`);
    },
  };
}

export default {};
export const BroadcastService = service();
