import fetcher from '~/utils/fetcher';
import config from '~/config';

// tier service
function service () {
  const endpoint = 'tiers';
  const { API_VERSION } = config;

  return {
    /**
     * Find tiers
     *
     * @param {any} params
     * @return {Promise<any>}
     */
    find (params) {
      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },

    /**
     * Get target tier
     *
     * @param {number | string} id
     * @param {any} params
     */
    get (id, params) {
      return fetcher.get(`${API_VERSION}/${endpoint}/${id}`, { params });
    },
  };
}

export default {};
export const TierService = service();
