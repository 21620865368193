import config from './index';

export default [
  {
    key: 'members',
    title: 'Members',
    link: '/members',
    icon: 'user',
    permissions: [ 'members:find:any' ],
  },
  {
    key: 'broadcasts',
    title: 'Broadcast',
    link: '/broadcasts',
    icon: 'notification',
    permissions: [ 'broadcasts:find:any' ],
    children: [
      {
        key: 'broadcasts-list',
        title: 'Broadcast list',
        link: '/broadcasts',
        icon: '',
        permissions: [ 'broadcasts:find:any' ],
      },
      {
        key: 'broadcasts-create',
        title: 'New broadcast',
        link: '/broadcasts/create',
        icon: '',
        permissions: [ 'broadcasts:create:any' ],
      },
    ],
  },
  {
    key: 'intents',
    title: 'Intents',
    link: '/intents',
    icon: 'message',
    permissions: [ 'intents:find:any' ],
    children: [
      {
        key: 'intents-list',
        title: 'List',
        link: '/intents',
        icon: '',
        permissions: [ 'intents:find:any' ],
      },
      {
        key: 'intents-create',
        title: 'New Intent',
        link: '/intents/create',
        icon: '',
        permissions: [ 'intents:create:any' ],
      },
      {
        key: 'intents-fallback',
        title: 'Fallback intent',
        link: '/intents/fallback',
        icon: '',
        permissions: [ 'intents:create:any', 'intents:patch:any', 'intents:update:any' ],
      },
    ],
  },
  {
    key: 'trainings',
    title: 'Trainings',
    link: '/trainings',
    icon: 'tool',
    permissions: [ 'trainings:find:any' ],
  },
  {
    key: 'rich-menus',
    title: 'Rich Menus',
    link: '/rich-menus',
    icon: 'border-bottom',
    permissions: [ 'rich-menu:find:any' ],
    children: [
      {
        key: 'rich-menus-list',
        title: 'List',
        link: '/rich-menus',
        permissions: [ 'rich-menu:find:any' ],
      },
      {
        key: 'rich-menus-create',
        title: 'New Rich Menu',
        link: '/rich-menus/create',
        permissions: [ 'rich-menu:create:any' ],
      },
    ],
  },
  {
    key: 'greeting-messages',
    title: 'Greeting Messages',
    link: '/greeting-messages',
    icon: 'wechat',
    permissions: [ 'greeting-messages:create:any' ],
  },
  {
    key: 'questions',
    title: 'Questions',
    link: '/questions',
    icon: 'question',
    permissions: [ 'questions:find:any' ],
    children: [
      {
        key: 'questions-list',
        title: 'Questions',
        link: '/questions',
        icon: '',
        permissions: [ 'questions:find:any' ],
      },
      {
        key: 'questions-create',
        title: 'New Question',
        link: '/questions/create',
        icon: '',
        permissions: [ 'questions:create:any' ],
      },
    ],
  },
  {
    key: 'services',
    title: 'Services',
    link: '/services',
    icon: 'heart',
    permissions: [ 'services:find:any' ],
  },
  {
    key: 'insight',
    title: 'Insight',
    link: '/insight',
    icon: 'rise',
    permissions: [ 'insights:find:any' ],
  },
  {
    key: 'insight-summary',
    title: 'Insight Summary',
    link: '/insight-summary',
    icon: 'bar-chart',
    permissions: [ 'insights:find:any' ],
  },
  {
    key: 'chats',
    title: 'Chats',
    link: '/chats',
    icon: 'wechat',
    permissions: [ 'chats:find:any' ],
  },
  {
    key: '1-1chats',
    title: '1-1 Chats',
    link: config.ONE_ON_ONE_CHAT_LINK,
    icon: 'wechat',
    permissions: [ 'individual-chat:find:any' ],
    target: '_blank',
  },
  {
    key: 'staff',
    title: 'Staff',
    link: '/staff',
    icon: 'team',
    permissions: [ 'accounts:find:any' ],
    children: [
      {
        key: 'staff-list',
        title: 'Staff list',
        link: '/staff',
        icon: '',
        permissions: [ 'accounts:find:any' ],
      },
      {
        key: 'new-staff',
        title: 'New staff',
        link: '/staff/create',
        permissions: [ 'accounts:create:any' ],
      },
    ],
  },
];
