import React from 'react';
import PropTypes from 'prop-types';

import { Card, Button, Popconfirm } from 'antd';

/**
 * Criteria card component
 *
 * @param {object} props
 * @param {string | number} props.id
 * @param {string} props.title - card title
 * @param {string[]} props.values - card criteria values
 * @param {function} props.onRemove - handle on remove function
 * @return {JSX.Element}
 */
function CriteriaCard ({ id, title, values, onRemove }) {
  return (
    <Card
      size="small"
      title={title}
      extra={(
        <Popconfirm
          placement="top"
          title={`Are you sure to remove the "${title}" criteria?`}
          onConfirm={() => onRemove(id)}
          okText="Remove"
          cancelText="No"
        >
          <Button type="link" icon="close" />
        </Popconfirm>
      )}
    >
      {values.join(' / ')}
    </Card>
  );
}

CriteriaCard.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  onRemove: PropTypes.func,
};

CriteriaCard.defaultProps = {
  id: '',
  title: '',
  values: [],
  onRemove () {},
};

export default CriteriaCard;
