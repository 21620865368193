import * as ActionType from './actionType';
import { getLanguagesApi } from '../services/languages';

const switchLanguage = (language) => ({
  type: ActionType.SWITCH_LANGUAGE,
  language,
});

const getLanguages = () => (dispatch) => dispatchLanguages(dispatch);

const dispatchLanguages = (dispatch) => getLanguagesApi().then((data) => {
  dispatch({
    type: ActionType.RECEIVE_LANGUAGES,
    data,
  });
  return data;
});

export { switchLanguage, getLanguages, dispatchLanguages };
