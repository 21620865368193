import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Upload, Icon, Tooltip, Button } from 'antd';

import config from '~/config';

/**
 * Media message component
 *
 * @param {object} props
 * @param {string} props.icon
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.value
 * @param {'image' | 'video'} props.type
 * @param {function} props.onChange
 * @param {function} props.onRemove
 * @return {JSX.Element}
 */
function MediaMessage ({ icon, title, description, value, type, onChange, onRemove }) {
  /**
   * Render image
   *
   * @return {JSX.Element}
   */
  const renderImage = () => (
    <img src={value} alt={value} />
  );

  /**
   * Render video
   *
   * @return {JSX.Element}
   */
  const renderVideo = () => (
    <video controls>
      <source src={value} type="video/mp4" />
      <track kind="captions" />
    </video>
  );

  /**
   * Render preview
   *
   * @return {JSX.Element}
   */
  const renderPreview = () => {
    let preview;

    switch (type) {
      case 'video':
        preview = renderVideo();
        break;

      default:
      case 'image':
        preview = renderImage();
        break;
    }

    return (
      <div className="preview-media-message">
        <div className="close-button">
          <Tooltip title="Remove">
            <Button icon="close" size="small" onClick={onRemove} />
          </Tooltip>
        </div>

        {preview}
      </div>
    );
  };

  return (
    <Fragment>
      {value && renderPreview()}

      {!value && (
        <Upload.Dragger
          accept={`${type}/*`}
          name="file"
          multiple={false}
          showUploadList={false}
          action={`${config.SERVICE}/${config.API_VERSION}/medias`}
          headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
          onChange={onChange}
        >
          <p className="ant-upload-drag-icon">
            <Icon type={icon} theme="filled" />
          </p>

          <p className="ant-upload-text">{title}</p>
          <p className="ant-upload-hint">{description}</p>
        </Upload.Dragger>
      )}
    </Fragment>
  );
}

MediaMessage.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf([ 'image', 'video' ]),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

MediaMessage.defaultProps = {
  icon: 'picture',
  title: 'upload',
  description: '',
  value: '',
  type: 'image',
  onChange () {},
  onRemove () {},
};

export default MediaMessage;
