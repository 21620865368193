import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import permissions from './permissions';

import { QuestionService } from '../services/questions';
import { StatService } from '../services/stats';
import { MemberService } from '../services/members';

export default {
  members: {
    resource: 'members',
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
    },
    permissionPrefix: 'members',
    preloadServices: [
      {
        name: 'questions',
        service: QuestionService.find({ $populate: [ 'choices' ] }),
        id: 1,
      },
    ],
    formItems: [
      {
        field: 'firstname',
        label: 'Firstname',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: true,
        search: true,
        requiredToShow: true,
      },
      {
        field: 'lastname',
        label: 'Lastname',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: 'line',
        label: 'Line',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: false,
        search: false,
      },
      {
        field: 'gender',
        label: 'Gender',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: 'birthdate',
        label: 'Birthdate',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: 'phone',
        label: 'Phone',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: 'email',
        label: 'Email',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: 'properties',
        label: 'Properties',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: false,
        search: false,
        render: (data, record, preloadServices) => {
          const { questions: lasQuestions = [] } = preloadServices;
          if (lasQuestions.length === 0) {
            return <div />;
          }
          const questions = _.groupBy(data, (answer) => {
            const questionId = answer.split(':')[0];
            return questionId;
          });
          const elements = _.reduce(questions, (acc, answers, questionId) => {
            const answerIds = _.map(answers, (answer) => answer.split(':')[1]);
            const lasQuestion = (lasQuestions.find((lasQuestion) => _.isEqual(`${lasQuestion.id}`, `${questionId}`)));
            if (!lasQuestion) return acc;
            const questionText = _.get(lasQuestion, 'title.en', questionId);
            const choices = _.map(answerIds, (answerId) => {
              const choice = _.find(lasQuestion.choices, (lasQuestionChoice) => `${lasQuestionChoice.id}` === `${answerId}`);
              return choice;
            });
            const choiceTexts = _.map(choices, (choice) => _.get(choice, 'title.en', ''));
            return [
              ...acc,
              {
                questionId,
                questionText,
                answerIds,
                answerTexts: choiceTexts,
              },
            ];
          }, []);

          return (
            <div>
              {elements.map((element) => (
                <div>
                  <h4 style={{ marginBottom: 0 }}>{element.questionText}</h4>
                  <ul style={{
                    margin: '10px',
                    'list-style-type': 'square',
                    'padding-inline-start': '20px',
                  }}
                  >
                    {element.answerTexts.map((answerText) => <li style={{ 'list-style-type': 'inherit' }}><span>{answerText}</span></li>)}
                  </ul>
                </div>
              ))}
            </div>
          );
        },
      },
      {
        field: 'createdAt',
        label: 'Joined Date',
        inputType: 'plain-text',
        multiLanguage: false,
        inputOption: {
        },
        tableDisplay: true,
        search: true,
        render: (text, record) => <span>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
      },
    ],
  },
  insight: {
    resource: 'insights',
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
    },
    permissionPrefix: 'insight',
    preloadServices: [
      {
        name: 'insights',
        service: StatService.find(),
        id: 1,
      },
      {
        name: 'members',
        service: MemberService.find(),
        id: 2,
      },
    ],
    formItems: [],
  },
  insightSummary: {
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
    },
    permissionPrefix: 'insight',
    preloadServices: [
      {
        name: 'insights',
        service: StatService.find(),
        id: 1,
      },
      {
        name: 'members',
        service: MemberService.find(),
        id: 2,
      },
    ],
    formItems: [],
  },
  staff: {
    noEditIds: [ 9999 ],
    noDeleteIds: [ 9999 ],
    resource: 'accounts',
    query: {
      $populate: [ 'role' ],
    },
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
    },
    permissionPrefix: 'staff',
    preloadServices: [],
    formItems: [
      {
        field: 'username',
        label: 'Username',
        tableDisplay: true,
        search: true,
        requiredToShow: true,
      },
      {
        field: 'role.title.en',
        label: 'Role',
        tableDisplay: true,
        search: true,
      },
      {
        field: 'status',
        label: 'Status',
        tableDisplay: true,
        search: true,
      },
    ],
  },
  broadcasts: {
    resource: 'broadcasts',
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
    },
    permissionPrefix: 'broadcasts',
    formItems: [],
  },
  intents: {
    resource: 'intents',
  },
  // locations: {
  //   resource: "locations",
  //   enableModule: {
  //     publish: true,
  //     publishTime: true,
  //     categories: false,
  //     tags: true,
  //     recommend: true,
  //     sort: true
  //   },
  //   permissionPrefix: "locations",
  //   formItems: [
  //     {
  //       field: "name",
  //       label: "Location",
  //       inputType: "text",
  //       multiLanguage: true,
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please input Location!" }]
  //       },
  //       tableDisplay: true,
  //       search: true
  //     }
  //   ]
  // },
  // banners: {
  //   resource: "banners",
  //   otherResource: ["locations"],
  //   enableModule: {
  //     publish: true,
  //     publishTime: true,
  //     categories: false,
  //     tags: true,
  //     recommend: true,
  //     sort: true
  //   },
  //   permissionPrefix: "banners",
  //   formItems: [
  //     {
  //       field: "name",
  //       label: "Name",
  //       inputType: "text",
  //       multiLanguage: true,
  //       tableDisplay: true,
  //       search: true
  //     },
  //     {
  //       field: "image_id",
  //       label: "Image / Video",
  //       inputType: "media",
  //       inputOption: {
  //         multiple: false
  //       },
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please select Image / Video!" }]
  //       }
  //     },
  //     {
  //       field: "location_ids",
  //       label: "Locations",
  //       inputType: "select",
  //       inputOption: {
  //         mode: "multiple"
  //       },
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please select Locations!" }]
  //       },
  //       relation: {
  //         resource: "locations",
  //         mapResource: {
  //           id: "id",
  //           label: "name"
  //         }
  //       },
  //       filter: {}
  //     }
  //   ]
  // },
  // "coupon/categories": {
  //   resource: "coupon/categories",
  //   enableModule: {
  //     publish: true,
  //     publishTime: true,
  //     categories: false,
  //     tags: true,
  //     recommend: true,
  //     sort: true
  //   },
  //   permissionPrefix: "coupon.categories",
  //   formItems: [
  //     {
  //       field: "name",
  //       label: "Category",
  //       inputType: "text",
  //       multiLanguage: true,
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please input Category!" }]
  //       }
  //     },
  //     {
  //       field: "parent_id",
  //       label: "Parent",
  //       inputType: "tree-select",
  //       defaultValue: 0
  //     },
  //     {
  //       field: "description",
  //       label: "Description",
  //       inputType: "textarea",
  //       multiLanguage: true
  //     }
  //   ]
  // },
  // coupons: {
  //   resource: "coupons",
  //   otherResource: ["locations"],
  //   enableModule: {
  //     publish: true,
  //     publishTime: true,
  //     categories: "coupon/categories",
  //     tags: true,
  //     recommend: true,
  //     sort: true
  //   },
  //   permissionPrefix: "coupon.coupons",
  //   formItems: [
  //     {
  //       field: "name",
  //       label: "Name",
  //       inputType: "text",
  //       multiLanguage: true,
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please input Name!" }]
  //       },
  //       tableDisplay: true,
  //       search: true
  //     },
  //     {
  //       field: "description",
  //       label: "Description",
  //       inputType: "text",
  //       multiLanguage: true
  //     },
  //     {
  //       field: "remark",
  //       label: "Remark",
  //       inputType: "text",
  //       multiLanguage: true
  //     },
  //     {
  //       field: "image_id",
  //       label: "Coupon Image",
  //       inputType: "media",
  //       inputOption: {
  //         multiple: false
  //       }
  //     },
  //     {
  //       field: "location_ids",
  //       label: "Locations",
  //       inputType: "select",
  //       inputOption: {
  //         mode: "multiple"
  //       },
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please select Locations!" }]
  //       },
  //       relation: {
  //         resource: "locations",
  //         mapResource: {
  //           id: "id",
  //           label: "name"
  //         }
  //       },
  //       filter: {}
  //     }
  //   ]
  // },
  // snacks: {
  //   resource: "snacks",
  //   otherResource: ["locations"],
  //   enableModule: {
  //     publish: true,
  //     publishTime: true,
  //     categories: false,
  //     tags: true,
  //     recommend: true,
  //     sort: true
  //   },
  //   permissionPrefix: "snacks",
  //   formItems: [
  //     {
  //       field: "name",
  //       label: "Name",
  //       inputType: "text",
  //       multiLanguage: true,
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please input Name!" }]
  //       },
  //       tableDisplay: true,
  //       search: true
  //     },
  //     {
  //       field: "description",
  //       label: "Description",
  //       inputType: "text",
  //       multiLanguage: true
  //     },
  //     {
  //       field: "remark",
  //       label: "Remark",
  //       inputType: "text",
  //       multiLanguage: true
  //     },
  //     {
  //       field: "image_id",
  //       label: "Image",
  //       inputType: "media",
  //       inputOption: {
  //         multiple: false
  //       }
  //     },
  //     {
  //       field: "location",
  //       label: "Location",
  //       inputType: "text"
  //     },
  //     {
  //       field: "time",
  //       label: "Time",
  //       inputType: "text"
  //     },
  //     {
  //       field: "phone",
  //       label: "Phone",
  //       inputType: "text"
  //     },
  //     {
  //       field: "location_ids",
  //       label: "Locations",
  //       inputType: "select",
  //       inputOption: {
  //         mode: "multiple"
  //       },
  //       fieldDecorator: {
  //         rules: [{ required: true, message: "Please select Locations!" }]
  //       },
  //       relation: {
  //         resource: "locations",
  //         mapResource: {
  //           id: "id",
  //           label: "name"
  //         }
  //       },
  //       filter: {}
  //     }
  //   ]
  // },
  // users: {
  //   resource: "users",
  //   otherResource: ["roles"],
  //   enableModule: {
  //     publish: true,
  //     publishTime: true,
  //     categories: false,
  //     tags: true,
  //     recommend: false,
  //     sort: false
  //   },
  //   permissionPrefix: "user.users",
  //   noEditIds: [1],
  //   noDeleteIds: [1],
  //   selfEdit: "id",
  //   formItems: [
  //     {
  //       field: "role_ids",
  //       label: "Roles",
  //       inputType: "select",
  //       inputOption: {
  //         mode: "multiple"
  //       },
  //       relation: {
  //         resource: "roles",
  //         mapResource: {
  //           id: "id",
  //           label: "name"
  //         }
  //       },
  //       noEditIds: [1]
  //     },
  //     {
  //       field: "username",
  //       label: "Username",
  //       inputType: "text",
  //       fieldDecorator: {
  //         rules: [
  //           {
  //             required: true,
  //             message: "Please input Username!"
  //           }
  //         ]
  //       },
  //       tableDisplay: true
  //     },
  //     {
  //       field: "email",
  //       label: "Email",
  //       inputType: "text",
  //       fieldDecorator: {
  //         rules: [
  //           {
  //             type: "email",
  //             message: "The input is not valid email!"
  //           },
  //           {
  //             required: true,
  //             message: "Please input Email!"
  //           }
  //         ]
  //       },
  //       tableDisplay: true
  //     },
  //     {
  //       field: "password",
  //       label: "Password",
  //       inputType: "password"
  //     },
  //     {
  //       field: "passport_no",
  //       label: "Passport No",
  //       inputType: "text"
  //     },
  //     {
  //       field: "country",
  //       label: "Country",
  //       inputType: "text"
  //     },
  //     {
  //       field: "how_did_your_know_about_us",
  //       label: "How did your know about us",
  //       inputType: "text"
  //     }
  //   ]
  // },
  // roles: {
  //   resource: "roles",
  //   enableModule: {
  //     publish: false,
  //     publishTime: false,
  //     categories: false,
  //     tags: false,
  //     recommend: false,
  //     sort: false
  //   },
  //   permissionPrefix: "user.roles",
  //   noEditIds: [1, 2],
  //   noDeleteIds: [1, 2],
  //   formItems: [
  //     {
  //       field: "name",
  //       label: "Role",
  //       inputType: "text",
  //       inputOption: {
  //         onChange: (value, props) => {
  //           props.form.setFieldsValue({
  //             slug: _.snakeCase(value)
  //           });
  //         }
  //       },
  //       fieldDecorator: {
  //         rules: [
  //           {
  //             required: true,
  //             message: "Please input Role!"
  //           }
  //         ]
  //       },
  //       tableDisplay: true
  //     },
  //     {
  //       field: "slug",
  //       label: "Slug",
  //       inputType: "text",
  //       inputOption: {
  //         disabled: true
  //       },
  //       fieldDecorator: {
  //         rules: [
  //           {
  //             required: true,
  //             message: "Please input Slug!"
  //           }
  //         ]
  //       }
  //     },
  //     {
  //       field: "description",
  //       label: "Description",
  //       inputType: "textarea"
  //     },
  //     {
  //       field: "permission_slugs",
  //       label: "Permissions",
  //       inputType: "tree",
  //       inputOption: {
  //         treeData: permissions
  //       }
  //     }
  //   ]
  // },
  // languages: {
  //   resource: "languages",
  //   permissionPrefix: "setting.language"
  // }
};
