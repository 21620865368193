import React, { useEffect, useRef } from 'react';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Divider, Button, Table, Tag, Icon, Modal, Input, notification } from 'antd';

import { PageTitle } from '~/components';
import { TierAction } from '~/actions/tier';

import './style.less';


/**
 * Greeting message
 *
 * @return {JSX.Element}
 */
function GreetingMessageTierList () {
  const { tiers = [], error, loading } = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const history = useHistory();
  /**
   * Column setting of antd table
   *
   * @constant
   */
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, item) => (
        <span>
          {title && <Tag color="green" style={{ fontSize: 18 }}>{title.en}</Tag>}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (title, item) => (
        <span>
          <Icon type="edit" style={{ fontSize: 21 }} onClick={() => history.push(`/greeting-messages/tier/${item.id}`)} />
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (error) notification.error({ message: 'Oops, something went wrong.', description: error.message });
  }, [ error ]);

  useEffect(() => {
    dispatch(TierAction.find());
  }, []);


  return (
    <div className="rich-menu-page">
      <div className="page-title-container">
        <PageTitle
          title="Greeting Messages"
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <Table
          dataSource={_.filter(tiers, (tier) => tier.id !== 1)}
          columns={columns}
          loading={loading}
          showHeader={false}
          bordered
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
}

export default GreetingMessageTierList;
