import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';


/**
 * Preview media message modal
 *
 * @return {JSX.Element}
 */
function PreviewMediaMessageModal ({ src, type, visible, onClose }) {
  return (
    <Modal
      footer={null}
      visible={visible}
      onCancel={onClose}
    >
      {(type === 'image' || type === 'imagemap') && (
        <img style={{ width: '100%' }} src={src} alt={src} />
      )}

      {type === 'video' && (
        <video style={{ width: '100%' }} controls>
          <source src={src} type="video/mp4" />
          <track kind="captions" />
        </video>
      )}
    </Modal>
  );
}

PreviewMediaMessageModal.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf([ '', 'image', 'video', 'imagemap' ]),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

PreviewMediaMessageModal.defaultProps = {
  type: '',
  visible: false,
  onClose () {},
};

export default PreviewMediaMessageModal;
