import { v4 as uuidv4 } from 'uuid';

/**
 * Is empty checker
 *
 * @param {object} message
 * @return {boolean}
 */
export function isEmpty (message) {
  const { type, data } = message;

  switch (type) {
    case 'text':
      return !data.text;

    case 'video':
    case 'image':
      return !(data.originalContentUrl || data.previewImageUrl);

    default:
      throw new Error(`undefined type ${type}`);
  }
}

/**
 * Parse object
 *
 * @param {array} messages
 * @return {any}
 */
export function parseObject (messages) {
  const convert = messages.reduce((result, message) => {
    if (!Array.isArray(message)) message = [ message ];

    return [
      ...result,
      message,
    ];
  }, []);

  return convert.reduce((result, message) => ({
    ...result,
    [uuidv4()]: {
      ...(message.reduce((messageResult, { type, ...remaining }) => ({
        ...messageResult,
        [uuidv4()]: { type, data: remaining },
      }), {})),
    },
  }), {});
}

/**
 * Parse array
 *
 * @param {object} groups - message groups
 * @return {}
 */
export function parseArray (groups) {
  return Object.values(groups).map((group) => (
    Object.values(group).filter((message) => !isEmpty(message)).map(({ type, data }) => ({ type, ...data }))
  ));
}
