import * as ActionType from '../actions/actionType';

const initialState = {
  data: {},
  isLogin: false,
  checked: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionType.RECEIVE_AUTH:
      return {
        ...state,
        data: action.data,
      };
    case ActionType.IS_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
      };
    case ActionType.LOGIN_CHECKED:
      return {
        ...state,
        checked: action.checked,
      };
    default:
      return state;
  }
};
