import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


/**
 * List component
 *
 * @param {Object} props
 * @param {any[]} [props.items = []]
 * @param {any} container - container component
 * @param {string} containerClassName - class name of container element
 * @param {(item: any, index: number) => string} props.keyExtractor
 * @param {(item: any, index: number) => React.ReactNode} props.render
 * @param {() => React.ReactNode} props.renderSeparator
 * @return {JSX.Element}
 */
function List ({ items, container, containerClassName, keyExtractor, render, renderSeparator }) {
  const Component = container;

  return (
    <Component className={containerClassName}>
      {items.map((item, index) => (
        <Fragment key={keyExtractor(item, index)}>
          <Fragment>
            {render(item, index)}
          </Fragment>

          {index !== items.length - 1 && renderSeparator()}
        </Fragment>
      ))}
    </Component>
  );
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  container: PropTypes.oneOfType([ PropTypes.string, PropTypes.element, PropTypes.func ]),
  containerClassName: PropTypes.string,
  keyExtractor: PropTypes.func,
  render: PropTypes.func,
  renderSeparator: PropTypes.func,
};

List.defaultProps = {
  items: [],
  container: 'div',
  containerClassName: '',
  keyExtractor () {},
  render () {},
  renderSeparator () {},
};

export default List;
