import axios from 'axios';
import config from '~/config';

/**
 * A brand new fetcher for this project with axios
 *
 * @param {string} baseURL
 */
function fetcher (baseURL) {
  const headers = { Authorization: `Bearer ${window.localStorage.getItem('token')}` };

  return axios.create({ baseURL, headers });
}

export const create = fetcher;
export default fetcher(config.SERVICE);
