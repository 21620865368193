export const SET = 'set';

export const ADD_MESSAGE = 'add-message';
export const REMOVE_MESSAGE = 'remove-answer';
export const CHANGE_MESSAGE_TYPE = 'change-message-type';
export const ON_CHANGE = 'on-change';
export const SWAP = 'swap';

export const ADD_GROUP = 'add-group';
export const REMOVE_GROUP = 'remove-group';
