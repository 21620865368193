import fetcher from '~/utils/fetcher';
import config from '~/config';

// branches service
function service () {
  const endpoint = 'branches';
  const { API_VERSION } = config;

  return {
    get (params) {
      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },
    /**
     * find new branch
     *
     * @param {any} branch - request body
     * @param {any} params
     */
    find (slug, params) {
      return fetcher.get(`${API_VERSION}/${endpoint}/${slug}`, { params });
    },
    /**
     * Create new branch
     *
     * @param {any} branch - request body
     * @param {any} params
     */
    create (branch, params) {
      return fetcher.post(`${API_VERSION}/${endpoint}`, branch, { params });
    },
    /**
     * update branch
     *
     * @param {any} branch - request body
     * @param {any} params
     */
    update (slug, data) {
      return fetcher.patch(`${API_VERSION}/${endpoint}/${slug}`, data);
    },
    /**
     * remove branch
     *
     * @param {any} branch - request body
     * @param {any} params
     */
    remove (slug) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/${slug}`);
    },
  };
}

export default {};
export const BranchService = service();
