import fetcher from '~/utils/fetcher';
import config from '~/config';

// Greeting messages service
function service () {
  const endpoint = 'greeting-messages';
  const { API_VERSION } = config;

  return {
    /**
     * Find greeting message
     *
     * @param {object} params
     */
    find (params) {
      return fetcher.get(`${API_VERSION}/${endpoint}`, { params });
    },

    /**
     * Get existing greeting message
     *
     * @param {string | number} id
     * @param {any} params
     */
    get (id, params) {
      return fetcher.get(`${API_VERSION}/${endpoint}/${id}`, { params });
    },

    /**
     * Create new greeting message
     *
     * @param {any} data
     * @param {any} params
     */
    create (data, params) {
      return fetcher.post(`${API_VERSION}/${endpoint}`, data, { params });
    },

    /**
     * Update existing greeting message (PUT)
     *
     * @param {string | number} id
     * @param {any} data
     * @param {any} params
     */
    update (id, data, params) {
      return fetcher.patch(`${API_VERSION}/${endpoint}/${id}`, data, { params });
    },

    /**
     * Remove existing greeting message
     *
     * @param {string | number} id - greeting message id (primary key)
     * @param {any} params
     */
    remove (id, params) {
      return fetcher.delete(`${API_VERSION}/${endpoint}/${id}`, { params });
    },
  };
}

export default {};
export const GreetingMessagesService = service();
