import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Spin, Typography, Input, Button, Form, Tooltip, Divider, Modal, notification, Upload } from 'antd';

import { PageTitle } from '~/components';
import { ResponseMessageGroup } from '~/explicitComponents';
import { ServiceAction } from '~/actions/services';

import { useDidUpdateEffect } from '~/hooks';

import { deleteMediasApi as removeMedia, getMediasByIdApi } from '~/services/medias';

import './style.less';

import config from '~/config';

/**
 * Default form data
 *
 * @constant
 * @private
 */
const defaultFormData = {
  title: '',
  description: '',
};

/**
 * Create and update intent screen
 *
 * @param {{ form: any }} props
 * @return {JSX.Element}
 */
function SaveService ({ form }) {
  const responseGroupRef = useRef(null);
  const [ formData, setFormData ] = useState(defaultFormData);

  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.service);

  const history = useHistory();

  const { id: slug } = useParams();
  const isEditing = !_.isEmpty(slug);

  const [ image, setImage ] = useState({});

  const { title, description } = form.getFieldsValue();

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, value) => {
      try {
        if (err) throw err;
        const data = {
          imageId: value.imageId,
          title: value.title,
          description: value.description,
          content: [],
          action: {
            type: 'object',
            label: 'See more',
          },
        };
        let msg = {};
        debugger;

        if (!isEditing) {
          await dispatch(ServiceAction.create(data));
          msg = {
            message: 'Created successfully',
            description: 'Your new service was created successfully',
          };
        } else {
          await dispatch(ServiceAction.update(slug, data));
          msg = {
            message: 'Edited successfully',
            description: 'Your service was edited successfully.',
          };
        }

        notification.success(msg);
        history.push('/services');
      } catch (er) {
        notification.error({ message: 'Oops, something went wrong', description: er });
      }
    });
  };

  const handleRemoveQuestion = () => {
    Modal.confirm({
      title: 'Are you absolutely sure?',
      content: 'This action cannot be undone. This will permanently remove this service.',
      okText: 'Remove',
      okType: 'danger',
      async onOk () {
        await dispatch(ServiceAction.remove(slug));

        notification.success({ message: 'Removed successfully', description: 'Your service was removed successfully.' });
        history.push('/services');
      },
    });
  };

  useEffect(() => {
    if (error) notification.error({ message: 'Oops, something went wrong', description: error });
  }, [ error ]);

  useDidUpdateEffect(() => {
    const init = async () => {
      const media = await getMediasByIdApi(formData.imageId);
      setImage({ ...media, old: true });
    };
    if (isEditing) init();
  }, [ formData ]);

  useEffect(() => {
    const init = () => {
      dispatch(async (d) => {
        const services = await ServiceAction.get(slug)(d);
        setFormData(services);
      });
    };

    if (slug) init();
  }, [ slug ]);

  const handleOnChangeFile = async (info) => {
    const { file } = info;

    if (file.status === 'done') {
      if (!_.isEmpty(image) && !image.old) await removeMedia(image.id);

      const media = file.response.shift();

      setImage(media);
      form.setFieldsValue({ imageId: media.id });
    }
  };

  return (
    <div className="services-page">
      <Spin spinning={loading} tip="loading...">
        <div className="page-title-container">
          <PageTitle
            title={isEditing ? 'Edit Service' : 'New Service'}
            extra={(
              <Fragment>
                {isEditing && (
                  <Tooltip title="Delete">
                    <Button type="danger" onClick={handleRemoveQuestion} style={{ marginRight: 10 }}>
                      Delete
                    </Button>
                  </Tooltip>
                )}
                <Button
                  key="save"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  form="form"
                >
                  Save
                </Button>
              </Fragment>
            )}
          />
        </div>

        <Divider className="is-marginless" />

        <div className="page-content-container">
          <Form
            id="form"
            onSubmit={handleSubmit}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            hideRequiredMark
          >
            <Row gutter={16}>
              <Col span={12}>
                {/* hidden input */}
                {form.getFieldDecorator('imageId', {
                  ...(isEditing && { initialValue: formData.imageId }),
                  rules: [{ required: true, message: 'Please upload a image' }],
                })(<Input type="hidden" />)}
                <Form.Item label={<Typography.Title level={3}>Title</Typography.Title>}>
                  {form.getFieldDecorator('title', {
                    initialValue: _.get(formData, [ 'title' ]),
                    rules: [
                      { required: true, message: 'Please enter service title' },
                    ],
                  })(<Input size="large" placeholder="Enter Title" />)}
                </Form.Item>
                <Form.Item label={<Typography.Title level={3}>Description</Typography.Title>}>
                  {form.getFieldDecorator('description', {
                    initialValue: _.get(formData, [ 'description' ]),
                    rules: [
                      { required: true, message: 'Please enter service description' },
                    ],
                  })(<Input.TextArea size="large" placeholder="Enter Description" />)}
                </Form.Item>
                <Form.Item label={<Typography.Title level={3}>Image</Typography.Title>}>
                  <div className="block"><img src={image.src} alt="" /></div>
                  <br />
                  <Upload
                    name="file"
                    accept="image/*"
                    headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
                    action={`${config.SERVICE}/${config.API_VERSION}/medias`}
                    className="is-fullwidth"
                    onChange={handleOnChangeFile}
                    showUploadList={false}
                  >
                    <Button type="primary" block>Upload Image</Button>
                  </Upload>
                  <div className="helper-text">
                    <small>File formats: JPG, JPEG, PNG</small>
                    <small>Width: 240px</small>
                    <small>Max file size: 1 MB</small>
                    <small>Recommended aspect ratio: 4:3</small>
                  </div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Card title="Preview">
                  <div className="card-preview">
                    <div className="img-wrapper">
                      <img src={image.src || config.LOGO_URL} alt="" />
                    </div>
                    <div className="main">
                      <h3 className="title">{title || 'Title'}</h3>
                      <h4 className="description">{description || 'Description'}</h4>
                    </div>
                    <div className="footer">
                      <h3>See more</h3>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ResponseMessageGroup
                  ref={responseGroupRef}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
}

SaveService.propTypes = {
  form: PropTypes.shape().isRequired,
};

SaveService.defaultProps = {};

export default Form.create()(SaveService);
