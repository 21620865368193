import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory, useParams } from 'react-router-dom';
import { Button, Divider, Spin, Form, Input, Select, Row, Col, notification, Checkbox } from 'antd';
import _ from 'lodash';

import { PageTitle } from '../../../components';
import { RoleService } from '../../../services/role';
import { StaffService } from '../../../services/staff';

/**
 * Default form data
 *
 * @constant
 * @private
 */
const defaultFormData = {
  username: '',
  password: '',
  role: {
    id: '',
  },
};

/**
 * Save staff page
 *
 * @param {object} props
 * @param {any} props.form
 * @return {JSX.Element}
 */
function SaveStaff ({ form }) {
  const [ submitting, setSubmitting ] = useState(false);
  const [ roles, setRoles ] = useState([]);
  const [ formData, setFormData ] = useState(defaultFormData);

  const history = useHistory();

  const { id: slug } = useParams();
  const isEditing = !_.isEmpty(slug);

  /**
   * Handle on submit
   *
   * @param {Event} e
   * @return {void}
   */
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    form.validateFields(async (formError, value) => {
      try {
        if (formError) throw formError;
        let msg = {};
        if (!isEditing) {
          const { data } = await StaffService.create(value);
          msg = {
            message: 'Created successfully',
            description: `The staff which is username '${data.username}' was created successfully`,
          };
        } else {
          await StaffService.update(slug, value);
          msg = {
            message: 'Updated successfully',
            description: `The staff which is username '${value.username}' was updated successfully`,
          };
        }
        notification.success(msg);
        history.push('/staff');
      } catch (error) {
        notification.error({ message: 'Oops someting went wrong' });
      } finally {
        setSubmitting(false);
      }
    });
  };

  /**
   * Fetch roles
   *
   * @description fetch the list of role to use in role selection
   * @return {void}
   */
  const fetchRoles = async () => {
    const { data } = await RoleService.find();
    setRoles(data.filter((role) => role.id !== 9999));
  };

  useEffect(() => {
    fetchRoles();
    const init = async () => {
      const { data } = await StaffService.find(slug, { $populate: [ 'role' ] });
      setFormData(data);
    };
    if (slug) init();
  }, []);

  return (
    <Spin spinning={submitting}>
      <div className="page-title-container">
        <PageTitle
          title={isEditing ? 'Edit staff' : 'New staff'}
          extra={(
            <Button key="save" type="primary" htmlType="submit" loading={submitting} form="form">
              {submitting ? 'Saving...' : 'Save'}
            </Button>
          )}
        />
      </div>

      <Divider />

      <div className="page-content-container">
        <Form
          id="form"
          colon={false}
          onSubmit={handleOnSubmit}
          labelCol={{ span: 4 }}
          wrapperCol={{ offset: 1, span: 7 }}
          hideRequiredMark
        >
          <Form.Item label="Username">
            {
              form.getFieldDecorator('username', {
                initialValue: _.get(formData, 'username'),
                rules: [{ required: true, message: 'Please enter username' }],
              })(
                <Input placeholder="Enter username" />,
              )
            }
          </Form.Item>

          {
            isEditing && (
              <Form.Item label="Change Password">
                {
                  form.getFieldDecorator('changePassword', {
                  })(
                    <Checkbox />,
                  )
                }
              </Form.Item>
            )
          }

          {
            (!isEditing || form.getFieldValue('changePassword')) && (
              <Form.Item label={isEditing ? 'New Password' : 'Password'}>
                {
                  form.getFieldDecorator('password', {
                    initialValue: _.get(formData, 'password'),
                    rules: [{ required: true, message: 'Please enter password' }],
                  })(
                    <Input type="password" placeholder="Create password" />,
                  )
                }
              </Form.Item>
            )
          }

          <Form.Item label="Role">
            {
              form.getFieldDecorator('roleId', {
                initialValue: _.get(formData, [ 'role', 'id' ]),
                rules: [{ required: true, message: 'Please select role' }],
              })(
                <Select
                  placeholder="Choose staff role"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) !== -1}
                  showSearch
                >
                  {roles.map((role) => (
                    <Select.Option key={role.id.toString()} value={role.id}>{role.title?.en}</Select.Option>
                  ))}
                </Select>,
              )
            }
          </Form.Item>

          <br />

          <Row>
            <Col offset={8} span={8}>
              <Button
                form="form"
                type="primary"
                size="large"
                key="save"
                htmlType="submit"
                block
              >
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
}

SaveStaff.propTypes = { form: PropTypes.shape().isRequired };
SaveStaff.defaultProps = {};

export default Form.create()(SaveStaff);
