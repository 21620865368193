import { QuestionService } from '~/services/questions';
import * as type from '~/actions/actionType';


// Question action
function action () {
  const { find, create, get, update, remove } = QuestionService;

  return {
    /**
     * Find questions
     *
     * @return {(dispatch: any) => Promise<void>}
     */
    find (params) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_QUESTION });

        try {
          const { data } = await find({ ...params, $populate: [ 'choices' ] });

          dispatch({ type: type.FETCH_QUESTION_SUCCESS, payload: data });
          return data;
        } catch (error) {
          dispatch({ type: type.FETCH_QUESTION_ERROR, payload: error });
        }
      };
    },

    /**
     * Find questions, but add data from the previous existing
     *
     * @return {(dispatch: any) => Promise<void>}
     */
    fetch (params) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_QUESTION_PAGE });

        try {
          const { data } = await find({ ...params, $populate: [ 'choices' ] });

          dispatch({ type: type.FETCH_QUESTION_PAGE_SUCCESS, payload: data });
          return data;
        } catch (error) {
          dispatch({ type: type.FETCH_QUESTION_PAGE_ERROR, payload: error });
        }
      };
    },

    /**
     * Get question
     *
     * @param {string | number} id
     * @param {any} params
     */
    get (id, params) {
      return async (dispatch) => {
        dispatch({ type: type.GET_QUESTION });

        try {
          const { data } = await get(id, params);

          dispatch({ type: type.GET_QUESTION_SUCCESS, payload: data });
          return data;
        } catch (error) {
          dispatch({ type: type.GET_QUESTION_ERROR, payload: error });
        }
      };
    },

    /**
     * Create new question
     *
     * @param {any} question
     */
    create (question) {
      return async (dispatch) => {
        dispatch({ type: type.CREATE_QUESTION });

        try {
          const { data } = await create(question);

          return dispatch({ type: type.CREATE_QUESTION_SUCCESS, payload: data });
        } catch (error) {
          return dispatch({ type: type.CREATE_QUESTION_ERROR, payload: error });
        }
      };
    },

    /**
     * Update question
     *
     * @param {string | number} id
     * @param {any} question
     * @param {any} params
     */
    update (id, question, params) {
      return async (dispatch) => {
        dispatch({ type: type.UPDATE_QUESTION });

        try {
          const { data } = await update(id, question, params);

          return dispatch({ type: type.UPDATE_QUESTION_SUCCESS, payload: data });
        } catch (error) {
          return dispatch({ type: type.UPDATE_QUESTION_ERROR, payload: error });
        }
      };
    },

    /**
     * Remove existing question
     *
     * @param {string | number} id
     */
    remove (id) {
      return async (dispatch) => {
        dispatch({ type: type.REMOVE_QUESTION });

        try {
          const { data } = await remove(id);

          return dispatch({ type: type.REMOVE_QUESTION_SUCCESS, payload: data });
        } catch (error) {
          return dispatch({ type: type.REMOVE_QUESTION_ERROR, payload: error });
        }
      };
    },
  };
}

export default {};
export const QuestionAction = action();
