import React, { Component } from 'react';
import {
  PageHeader,
  Button,
  Affix,
  Row,
  Col,
  Form,
  message,
  Popconfirm,
  Select,
  Divider,
  Icon,
  Drawer,
  TreeSelect,
} from 'antd';
import _ from 'lodash';
import { InputToFormItem } from '../../../components';
import { withRedux } from '../../../hoc';
import {
  fetchApi,
  translateData,
  authHasPermission,
  mapCategoriesLevel,
  convertData,
} from '../../../utils';
import resources from '../../../config/resources';
import './style.less';

const { Option } = Select;
const { SHOW_ALL } = TreeSelect;

const convertDataToSubmit = (
  values,
  formItems,
  item,
  enableModule,
  languages,
) => {
  const convetedData = convertData(item, values, formItems, languages);
  if (_.get(enableModule, 'tags') === true) {
    convetedData.tags = JSON.stringify(convetedData.tags);
  }
  return convetedData;
};

const mapStateToProps = (state) => ({
  t: _.get(state, 't'),
  languages: _.get(state, 'languages'),
  auth: _.get(state, 'auth.data'),
});

const actionToProps = {};

class LayoutSave extends Component {
  constructor (props) {
    super(props);
    this.state = {
      submitting: false,
      deleting: false,
      formItems: props.formItems,
      item: {},
      // items: [],
      otherItems: [],
      categories: [],
      addOtherItem: null,
      preloadServices: {},
    };
  }

  componentDidMount () {
    console.log('componentDidMount', this);
    this.init();
  }

  init = async () => {
    await this.fetchOtherItems();
    await this.fetchItem();
    // this.fetchItems();
    await this.fetchCategories();
    this.autoFocusFirstInput();
    await this.fetchPreloadServices();
  };

  fetchPreloadServices = async () => {
    const { preloadServices } = this.props;

    const preloadServiceResponses = await Promise.all(_.map(preloadServices, 'service'));

    const mappedServices = _.reduce(preloadServiceResponses, (acc, serviceResponse, index) => {
      const targetPreloadServiceName = preloadServices[index].name || (index + 1);
      return {
        ...acc,
        [targetPreloadServiceName]: serviceResponse.data,
      };
    }, {});

    this.setState({
      preloadServices: mappedServices,
    });
  }

  mapRelation = (formItems, translateItem = {}) => {
    const { otherItems } = this.state;
    const { t } = this.props;
    const id = _.toInteger(this.props.match.params.id);
    const newFormItems = _.map(formItems, (formItem, _index) => {
      const {
        field,
        inputType,
        relation,
        disabledOnEdit,
        noEditIds,
        inputOption,
      } = formItem;
      const result = {
        ...formItem,
        inputOption: {
          ...inputOption,
        },
        defaultValue: _.get(translateItem, field),
      };
      if (inputType === 'repeat') {
        return {
          ...result,
          formItems: this.mapRelation(formItem.formItems),
        };
      }
      if (relation) {
        const otherItem = _.get(otherItems, relation.resource);
        const otherResource = _.get(resources, relation.resource);
        const translateOtherItems = _.map(otherItem, (item) => translateData(item, t, _.get(otherResource, 'formItems')));
        if (inputType === 'select') {
          result.inputOption.children = _.map(translateOtherItems, (value) => (
            <Option value={_.get(value, relation.mapResource.id)}>
              {_.get(value, relation.mapResource.label)}
            </Option>
          ));
          result.inputOption.dropdownRender = (menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{ padding: '8px', cursor: 'pointer' }}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  this.setState({
                    addOtherItem: {
                      ...otherResource,
                      formItem,
                    },
                  });
                }}
              >
                <Icon type="plus" />
                {' '}
                Add item
              </div>
            </div>
          );
        } else if (inputType === 'tree-select') {
          const categoriesConvetedData = mapCategoriesLevel(
            translateOtherItems,
          );
          result.inputOption.treeData = categoriesConvetedData;
          // result.defaultValue = _.map(_.get(translateItem, field), value => {
          //   return _.get(value, relation.mapResource.id);
          // });
        }
      }
      _.set(
        result,
        'inputOption.disabled',
        (id && disabledOnEdit === true)
          || _.includes(noEditIds, id)
          || _.get(inputOption, 'disabled', false) === true,
      );
      return result;
    });
    return newFormItems;
  };

  fetchItem = async () => {
    const { formItems } = this.state;
    const { t } = this.props;
    const id = _.toInteger(this.props.match.params.id);
    const { resource, redirectPath } = this.props;
    let item = {};
    if (id) item = await fetchApi(`${resource}/${id}`).then((res) => res);
    // console.log({ id, item });
    if (id && !item) return this.props.history.push(redirectPath);
    const translateItem = translateData(item, t, formItems);
    const newFormItems = this.mapRelation(formItems, translateItem);
    // console.log("newFormItems", newFormItems);
    this.setState({
      item,
      formItems: newFormItems,
    });
  };

  fetchItems = async () => {
    const { resource } = this.props;
    const items = await fetchApi(`${resource}`).then((res) => _.get(res, 'data'));
    if (!items) return;
    this.setState({
      items,
    });
  };

  fetchOtherItems = async () => {
    const { otherResource } = this.props;
    const otherItems = await _.reduce(
      otherResource,
      async (result, resource) => {
        const items = await fetchApi(`${resource}`).then((res) => _.get(res, 'data'));
        return {
          ...(await result),
          [resource]: items,
        };
      },
      Promise.resolve({}),
    );
    this.setState({
      otherItems,
    });
  };

  fetchCategories = async () => {
    const { enableModule } = this.props;
    if (!enableModule.categories) return;
    const categories = await fetchApi(enableModule.categories).then((res) => _.get(res, 'data'));
    this.setState({
      categories,
    });
  };

  autoFocusFirstInput = () => {
    setTimeout(() => {
      const firstElement = document.querySelector('.ant-form-item-children');
      if (_.get(firstElement, 'childNodes')) firstElement.childNodes[0].focus();
    }, 200);
  };

  _create = async (data) => {
    const { resource, redirectPath } = this.props;
    const created = await fetchApi(resource, 'POST', JSON.stringify(data));
    if (_.get(created, 'status') !== 'success') {
      message.error(_.get(created, 'message', 'Create failed.'));
      return;
    }
    message.success('Create success.');
    this.props.history.push(redirectPath);
  };

  _update = async (data) => {
    const id = _.toInteger(this.props.match.params.id);
    const { resource, redirectPath } = this.props;
    const updated = await fetchApi(
      `${resource}/${id}`,
      'PUT',
      JSON.stringify(data),
    );
    if (_.get(updated, 'status') !== 'success') {
      message.error(_.get(updated, 'message', 'Update failed.'));
      return;
    }
    message.success('Update success.');
    this.props.history.push(redirectPath);
  };

  _delete = async () => {
    const id = _.toInteger(this.props.match.params.id);
    if (this.state.deleting) return;
    this.setState({ deleting: true });
    const { resource, redirectPath } = this.props;
    const deleted = await fetchApi(`${resource}/${id}`, 'DELETE');
    if (_.get(deleted, 'status') !== 'success') {
      message.error(_.get(deleted, 'message', 'Delete failed.'));
    } else {
      message.success('Delete success.');
      return this.props.history.push(redirectPath);
    }
    this.setState({ deleting: false });
  };

  submit = async (data) => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    const id = _.toInteger(this.props.match.params.id);
    if (id) {
      await this._update(data);
    } else {
      await this._create(data);
    }
    this.setState({ submitting: false });
  };

  handleSubmit = (e) => {
    const { formItems, item } = this.state;
    const { enableModule, languages } = this.props;
    e.preventDefault();
    const options = {
      scroll: {
        offsetTop: 96,
      },
    };
    this.props.form.validateFieldsAndScroll(options, (err, values) => {
      if (!err) {
        const data = convertDataToSubmit(
          values,
          formItems,
          item,
          enableModule,
          languages,
        );
        // console.log('Received values of form: ', values, data);
        // return;
        this.submit(data);
      }
    });
  };

  renderPublishTime = () => {
    const { item } = this.state;
    const { form } = this.props;
    return (
      <Row gutter={8}>
        <Col lg={12}>
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'publish_start_time',
                label: 'Publish Start Time',
                inputType: 'date-picker',
                multiLanguage: false,
                defaultValue: _.get(item, 'publish_start_time'),
              },
            ]}
          />
        </Col>
        <Col lg={12}>
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'publish_end_time',
                label: 'Publish End Time',
                inputType: 'date-picker',
                multiLanguage: false,
                defaultValue: _.get(item, 'publish_end_time'),
              },
            ]}
          />
        </Col>
      </Row>
    );
  };

  renderPageHeader = () => {
    const { submitting, deleting, item } = this.state;
    const {
      title,
      redirectPath,
      permissionPrefix,
      auth,
      noEditIds,
      noDeleteIds,
      selfEdit,
      isView = false,
    } = this.props;
    const id = _.toInteger(this.props.match.params.id);
    const extra = [];
    if (id) {
      if (
        authHasPermission(_.get(auth, 'claim'), [
          `${permissionPrefix}.delete`,
        ])
        && !_.includes(noDeleteIds, id)
        && !isView
      ) {
        extra.push(
          <Popconfirm
            key="delete"
            title="Are you sure delete this task?"
            onConfirm={this._delete}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" loading={deleting}>
              {deleting ? 'Deleting...' : 'Delete'}
            </Button>
          </Popconfirm>,
        );
      }
    }
    let canEdit = false;
    if (selfEdit) canEdit = _.get(auth, 'id') === _.get(item, selfEdit);
    if (
      ((authHasPermission(_.get(auth, 'claim'), [
        id ? `${permissionPrefix}.edit` : `${permissionPrefix}.add`,
      ])
        && !_.includes(noEditIds, id))
        || canEdit)
      && !isView
    ) {
      extra.push(
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          loading={submitting}
        >
          {submitting ? 'Saving...' : 'Save'}
        </Button>,
      );
    }
    const propsPageHeader = {
      title: isView ? `${title}` : `${id ? 'Edit' : 'New'} ${title}`,
      extra,
    };
    propsPageHeader.onBack = () => this.props.history.push(redirectPath);
    return (
      <div className="page-header">
        <PageHeader {...propsPageHeader} />
      </div>
    );
  };

  renderMainContent = () => {
    const { formItems, item, preloadServices } = this.state;
    const { form } = this.props;
    return (
      <div className="main-content">
        {((this.props.match.params.id && !_.isEmpty(item))
          || !this.props.match.params.id) && (
          <InputToFormItem form={form} formItems={formItems} preloadServices={preloadServices} />
        )}
      </div>
    );
  };

  getCategories = () => {
    const { categories } = this.state;
    const { t, enableModule } = this.props;
    if (!enableModule.categories) return;
    const resource = _.get(resources, enableModule.categories);
    const { formItems } = resource;
    const translateItems = _.map(categories, (item) => translateData(item, t, formItems));
    const categoriesConvetedData = mapCategoriesLevel(translateItems);
    return categoriesConvetedData;
  };

  renderRightBar = () => {
    const { item } = this.state;
    const { form, enableModule } = this.props;
    const { getFieldsValue } = form;
    const formData = getFieldsValue();
    const publishedFormData = _.get(formData, 'published');
    return (
      <div className="right-bar">
        {_.get(enableModule, 'publish') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'published',
                label: 'Publish',
                inputType: 'switch',
                multiLanguage: false,
                defaultValue: _.get(item, 'published', true),
              },
            ]}
          />
        )}
        {_.get(enableModule, 'publish') === true
          && _.get(enableModule, 'publishTime') === true
          && (!_.has(formData, 'published')
            || (_.has(formData, 'published') && publishedFormData === true))
          && this.renderPublishTime()}
        {_.get(enableModule, 'categories') && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'category_ids',
                label: 'Categories',
                inputType: 'tree-select',
                multiLanguage: false,
                defaultValue: _.get(item, 'category_ids', []),
                inputOption: {
                  multiple: true,
                  treeCheckable: true,
                  treeData: this.getCategories(),
                  showCheckedStrategy: SHOW_ALL,
                },
              },
            ]}
          />
        )}
        {_.get(enableModule, 'tags') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'tags',
                label: 'Tags',
                inputType: 'select',
                inputOption: {
                  mode: 'tags',
                },
                multiLanguage: false,
                defaultValue: _.isEmpty(_.get(item, 'tags'))
                  ? []
                  : _.get(item, 'tags', []),
              },
            ]}
          />
        )}
        {_.get(enableModule, 'recommend') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'recommended',
                label: 'Recommended',
                inputType: 'switch',
                multiLanguage: false,
                defaultValue: _.get(item, 'recommended', false),
              },
            ]}
          />
        )}
        {_.get(enableModule, 'sort') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'sort',
                label: 'Sort',
                inputType: 'number',
                multiLanguage: false,
                defaultValue: _.get(item, 'sort', 0),
              },
            ]}
          />
        )}
      </div>
    );
  };

  render () {
    const { addOtherItem, preloadServices } = this.state;
    const { languages, isView } = this.props;
    // console.log(this)
    return (
      <div className="layout-save-page">
        <Form onSubmit={this.handleSubmit}>
          <div className="page-header-container">
            <Affix>{this.renderPageHeader()}</Affix>
          </div>
          <Row>
            <Col md={isView ? 24 : 16}>
              <div className="main-content-container">
                {this.renderMainContent()}
              </div>
            </Col>
            {!isView && (
              <Col md={8}>
                <div className="right-bar-container">
                  <Affix offsetTop={73}>{this.renderRightBar()}</Affix>
                </div>
              </Col>
            )}
          </Row>
        </Form>
        <OtherFormWarpper
          {...addOtherItem}
          preloadServices={preloadServices}
          languages={languages}
          visible={!_.isEmpty(addOtherItem)}
          onClose={() => this.setState({ addOtherItem: null })}
          onCreated={async (data) => {
            await this.fetchOtherItems();
            this.fetchItem();
            this.props.form.setFieldsValue({
              [addOtherItem.formItem.field]: _.get(
                data,
                addOtherItem.formItem.relation.mapResource.id,
              ),
            });
            this.setState({ addOtherItem: null });
          }}
        />
      </div>
    );
  }
}

export default Form.create()(
  withRedux(mapStateToProps, actionToProps)(LayoutSave),
);

class OtherForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  _create = async (data) => {
    const { resource } = this.props;
    const created = await fetchApi(resource, 'POST', JSON.stringify(data));
    if (_.get(created, 'status') !== 'success') {
      message.error(_.get(created, 'message', 'Create failed.'));
      return;
    }
    message.success('Create success.');
    this.props.onCreated(created.data);
  };

  submit = async (data) => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    await this._create(data);
    this.setState({ submitting: false });
  };

  handleSubmit = (e) => {
    const { formItems, enableModule, languages } = this.props;
    e.preventDefault();
    const options = {
      scroll: {
        offsetTop: 39,
      },
    };
    this.props.form.validateFieldsAndScroll(options, (err, values) => {
      if (!err) {
        const data = convertDataToSubmit(
          values,
          formItems,
          {},
          enableModule,
          languages,
        );
        this.submit(data);
      }
    });
  };

  render () {
    const { submitting } = this.state;
    const { form, formItems, formItem, visible, preloadServices } = this.props;
    return (
      <Drawer
        {...this.props}
        title={_.get(formItem, 'label')}
        visible={visible}
        width="50%"
        height="100%"
      >
        <Form onSubmit={this.handleSubmit}>
          <InputToFormItem form={form} formItems={formItems} preloadServices={preloadServices} />
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              loading={submitting}
            >
              {submitting ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}

const OtherFormWarpper = Form.create()(OtherForm);
