import React, { Fragment, useEffect, useState, useRef } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Table, Divider, Input, Tag, Popconfirm, Tooltip, Icon, message, Empty, Select } from 'antd';

import { PageTitle } from '../../../components';
import * as IntentService from '../../../services/intents';
import { BranchService } from '../../../services/branches';
import { useInput, useDidUpdateEffect, usePaginate, usePrevious } from '../../../hooks';

import './style.less';

const { Option } = Select;

/**
 * Intents page
 *
 * @return {JSX.Element}
 */
function Intents () {
  const [ intents, setIntents ] = useState({});
  const [ branches, setBranches ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const timeoutId = useRef();

  const history = useHistory();
  const location = useLocation();

  const { pagination, isVisited, setTotal, setCurrent } = usePaginate();

  const params = new URLSearchParams(location.search);
  const defaultFilter = params.get('q');
  const [ filter, setFilter ] = useInput(defaultFilter);
  const previousFilter = usePrevious(filter);

  const defaultBranches = params.get('branches') || '';
  const [ filterBranches, setFilterBranches ] = useState(defaultBranches.split('+').filter(Boolean));

  /**
   * Handle on row
   *
   * @param {any} item
   * @param {number} index
   * @return {{ onClick (): any }}
   */
  const handleClickEdit = (item) => {
    if (item.type === 'fallback-intents') return history.push('/intents/fallback');
    history.push({ pathname: `/intents/edit/${item.id}`, search: location.search });
  };

  const findBranches = async () => {
    const { data } = await BranchService.get();
    setLoading(() => {
      setBranches(data);
      return false;
    });
  };

  /**
   * Find intent
   *
   * @param {string} filter
   * @return {void}
   */
  const findIntents = async (opts, textFilter) => {
    const query = new URLSearchParams({ '$select[]': 'id' });
    query.append('$select[]', 'title');
    query.append('$select[]', 'keywords');
    query.append('$select[]', 'type');
    query.append('type', 'intents');
    query.append('$sort[id]', '-1');

    if (opts) {
      Object.keys(opts).forEach((op) => {
        query.append(op, opts[op]);
      });
    }
    if (textFilter) {
      query.append('keywords[]', textFilter);
      query.append('title[$iLike]', `%${textFilter}%`);
    }

    if (filterBranches.length > 0) {
      for (let i = 0; i < filterBranches.length; i += 1) {
        query.append('keywords[]', filterBranches[i]);
      }
    }

    const data = await IntentService.findIntents(query);
    setLoading(() => {
      setIntents(data);
      return false;
    });
  };

  const handleDelete = async (id) => {
    try {
      await IntentService.deleteIntent(id);
      message.success('Intent was deleted successfully.');
    } catch (error) {
      console.error(error);
      message.error('Something went wrong.');
    }
    await findIntents();
  };

  const renderEmptyData = () => (
    <Empty
      imageStyle={{ height: 80 }}
      description={
        timeoutId.current
          ? <span>No result found</span>
          : (
            <span>
              You don't have any intent. Create your first intent to response your customer automatically.
              {' '}
              <Link to="/intents/create">Create now</Link>
            </span>
          )
      }
    >
      {!filter && !timeoutId.current && (
        <Link to="/intents/create">
          <Button type="primary">Create New Intent</Button>
        </Link>
      )}
    </Empty>
  );

  useEffect(() => {
    findBranches();
    findIntents(pagination.query, filter);
    const currentPage = Number(params.get('page'));
    if (currentPage) {
      setCurrent(currentPage);
      if (!isVisited(currentPage)) findIntents({ ...pagination.query, $page: currentPage });
    }
  }, []);

  useDidUpdateEffect(() => {
    const TIME = 300;
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      timeoutId.current = undefined;
      if (previousFilter !== filter) {
        params.set('q', filter);
      }
      if (filterBranches.length > 0) {
        params.set('branches', filterBranches.join('+'));
      }
      history.push({ search: params.toString() });
    }, TIME);
  }, [ filter, filterBranches ]);

  useDidUpdateEffect(() => {
    setTotal(intents.total);
  }, [ intents ]);

  const handleTableChange = (paginationChange) => {
    const { current } = paginationChange;
    params.set('page', current);
    setCurrent(current);
    if (!isVisited(current)) findIntents({ ...pagination.query, $page: current });
    history.push({ search: params.toString() });
  };

  const handleFilterTextChange = (e) => {
    setFilter(e);
  };

  const handleChangeBranch = (values) => {
    setFilterBranches([ values ]);
  };

  /**
   * Column setting of table
   */
  const COLUMNS = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => (
        <Fragment>
          {title}
          {record.type === 'fallback-intents' && (
            <Tag color="green" style={{ marginLeft: 5 }}>
              {record.type}
            </Tag>
          )}
        </Fragment>
      ),
    },
    {
      title: 'Keywords',
      dataIndex: 'keywords',
      key: 'keywords',
      render: (tags) => (
        <span>
          {tags && tags.length > 0 && tags.map((tag, i) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={`${tag}-${i + 1}`}>
                {tag}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text, record) => (
        <span>
          <Icon type="edit" onClick={() => handleClickEdit(record)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure delete this intent?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Icon style={{ color: 'red' }} type="delete" />
            </Tooltip>
          </Popconfirm>
        </span>
      ),
    },
  ];


  return (
    <div className="intent-page">
      <div className="page-title-container">
        <PageTitle
          title="Intents"
          extra={
            <Link to="/intents/create">
              <Button type="primary">Create New</Button>
            </Link>
          }
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <div className="input-container">
          <Input.Search
            value={filter}
            size="large"
            placeholder="Search intents"
            onChange={handleFilterTextChange}
          />
        </div>
        <div className="input-container">
          <Select
            style={{ width: "100%" }}
            placeholder="Tags Mode"
            defaultValue={filterBranches.filter(Boolean)}
            onChange={handleChangeBranch}
          >
            {branches.map((branch) => (
              <Option key={branch.keyword}>{branch.keyword}</Option>
            ))}
          </Select>
        </div>
        <Table
          rowKey={(item) => item.id}
          dataSource={intents.data}
          loading={loading}
          columns={COLUMNS}
          showHeader={false}
          pagination={pagination.plain}
          onChange={handleTableChange}
          locale={{ emptyText: renderEmptyData() }}
          bordered
        />
        {/* <Table
          dataSource={intents}
          loading={loading}
          columns={COLUMNS}
          bordered
          showHeader={false}
          locale={{ emptyText: renderEmptyData() }}
        /> */}
      </div>
    </div>
  );
}

export default Intents;
