import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

const initialActions = {};

export default (mapStateToProps, actions = {}) => (Component) => {
  const mapDispatchToProps = (dispatch) => ({
    dispatch,
    actions: bindActionCreators({ ...initialActions, ...actions }, dispatch),
  });
  class WithRedux extends React.Component {
    getDataFromObject = (object) => {
      const { languages, settings } = this.props;
      const currentActive = _.get(languages, 'active');
      const defaultLanguage = _.get(settings, 'data.defaultLanguage');
      let string = _.get(object, currentActive, _.get(object, defaultLanguage));
      if (!string) string = object[Object.keys(object)[0]];
      return string;
    };

    getDataWithLanguage = (content) => {
      let result = content;
      if (_.isObject(result)) result = this.getDataFromObject(result);
      return result;
    };

    render () {
      return <Component {...this.props} t={this.getDataWithLanguage} />;
    }
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithRedux);
};
