import { ServiceService } from '~/services/services';
import * as type from '~/actions/actionType';


// Question action
function action () {
  const { find, create, get, update, remove } = ServiceService;

  return {
    /**
     * Find questions
     *
     * @return {(dispatch: any) => Promise<void>}
     */
    find (params) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_SERVICE });

        try {
          const { data } = await find({ ...params, $populate: [ 'image' ] });

          dispatch({ type: type.FETCH_SERVICE_SUCCESS, payload: data });
          return data;
        } catch (error) {
          dispatch({ type: type.FETCH_SERVICE_ERROR, payload: error });
        }
      };
    },

    /**
     * Find questions, but add data from the previous existing
     *
     * @return {(dispatch: any) => Promise<void>}
     */
    fetch (params) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_SERVICE });

        try {
          const { data } = await find({ ...params, $populate: [ 'choices' ] });

          dispatch({ type: type.FETCH_SERVICE_SUCCESS, payload: data });
          return data;
        } catch (error) {
          dispatch({ type: type.FETCH_SERVICE_ERROR, payload: error });
        }
      };
    },

    /**
     * Get question
     *
     * @param {string | number} id
     * @param {any} params
     */
    get (id, params) {
      return async (dispatch) => {
        dispatch({ type: type.GET_QUESTION });

        try {
          const { data } = await get(id, params);

          dispatch({ type: type.GET_SERVICE_SUCCESS, payload: data });
          return data;
        } catch (error) {
          dispatch({ type: type.GET_SERVICE_ERROR, payload: error });
        }
      };
    },

    /**
     * Create new question
     *
     * @param {any} question
     */
    create (question) {
      return async (dispatch) => {
        dispatch({ type: type.CREATE_SERVICE });

        try {
          const { data } = await create(question);

          return dispatch({ type: type.CREATE_SERVICE_SUCCESS, payload: data });
        } catch (error) {
          return dispatch({ type: type.CREATE_SERVICE_ERROR, payload: error });
        }
      };
    },

    /**
     * Update question
     *
     * @param {string | number} id
     * @param {any} question
     * @param {any} params
     */
    update (id, question, params) {
      return async (dispatch) => {
        dispatch({ type: type.UPDATE_SERVICE });

        try {
          const { data } = await update(id, question, params);

          return dispatch({ type: type.UPDATE_SERVICE_SUCCESS, payload: data });
        } catch (error) {
          return dispatch({ type: type.UPDATE_SERVICE_ERROR, payload: error });
        }
      };
    },

    /**
     * Remove existing question
     *
     * @param {string | number} id
     */
    remove (id) {
      return async (dispatch) => {
        dispatch({ type: type.REMOVE_SERVICE });

        try {
          const { data } = await remove(id);

          return dispatch({ type: type.REMOVE_SERVICE_SUCCESS, payload: data });
        } catch (error) {
          return dispatch({ type: type.REMOVE_SERVICE_ERROR, payload: error });
        }
      };
    },
  };
}

export default {};
export const ServiceAction = action();
