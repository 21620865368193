import React, { PureComponent } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import _ from 'lodash';
import { createMediasApi } from '../../services/medias';

export default class TextEditor extends PureComponent {
  uploadImageCallBack = (file) => new Promise(async (resolve, reject) => {
    try {
      const uploadResult = await createMediasApi(file);
      if (_.get(uploadResult, 'status') === 'success') {
        resolve({
          data: {
            ..._.get(uploadResult, 'data'),
            link: `${process.env.REACT_APP_SERVICE}/${_.get(uploadResult, 'data.full_file_path')}`,
          },
        });
      } else {
        reject(_.get(uploadResult, 'message'));
      }
    } catch (error) {
      reject(error);
    }
  });

  render () {
    return (
      <Editor
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: this.uploadImageCallBack,
            previewImage: true,
            alt: { present: true, mandatory: false },
          },
        }}
        {...this.props}
      />
    );
  }
}
