import React, { Component } from 'react';
import { Button, PageHeader, Affix, message, Spin } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { DraggableTree } from '../../../../components';
import { withRedux } from '../../../../hoc';
import { fetchApi, sortCollection, authHasPermission } from '../../../../utils';
import './style.less';

const mapStateToProps = (state) => ({
  t: _.get(state, 't'),
  languages: _.get(state, 'languages'),
  auth: _.get(state, 'auth.data'),
});

const actionToProps = {};

class LayoutList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
    };
  }

  componentDidMount () {
    this.init();
  }

  init = () => {
    this.fetchItems();
  };

  fetchItems = async () => {
    this.setState({ loading: true });
    const { resource, sort } = this.props;
    const items = await fetchApi(resource)
      .then((res) => _.get(res, 'data'))
      .then((res) => (sort ? _.orderBy(res, [ 'sort' ], [ 'asc' ]) : sortCollection(res)));
    this.setState({
      items,
      loading: false,
    });
  };

  delete = async (id) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { resource } = this.props;
    const deleted = await fetchApi(`${resource}/${id}`, 'DELETE');
    if (_.get(deleted, 'status') !== 'success') {
      message.error(_.get(deleted, 'message', 'Delete failed.'));
    } else {
      message.success('Delete success.');
      this.fetchItems();
    }
    this.setState({ loading: false });
  };

  bulkUpdate = async (items) => {
    const { resource } = this.props;
    const updated = await fetchApi(`${resource}`, 'PUT', JSON.stringify({ data: items }));
    return updated;
  };

  updateCategoriesLevel = async (items) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const updated = await this.bulkUpdate(items);
    if (_.get(updated, 'status') === 'success') {
      this.setState({
        items,
      });
    }
    this.setState({ loading: false });
  };

  mapCategoriesLevel = (d) => {
    const { t } = this.props;
    const findChildren = (d, v) => {
      const options = {
        ...v,
        key: _.get(v, 'id'),
        title: t(_.get(v, 'name')),
      };
      const parent = _.filter(d, { parent_id: _.get(v, 'id') });
      if (!_.isEmpty(parent)) options.children = _.map(_.sortBy(parent, [ 'sort' ]), (p) => findChildren(d, p));
      return options;
    };
    const firstLevel = _.filter(d, { parent_id: 0 });
    return _.map(_.sortBy(firstLevel, [ 'sort' ]), (v) => findChildren(d, v));
  };

  renderPageHeader = () => {
    const { title, path, permissionPrefix, auth } = this.props;
    const extra = [];
    if (authHasPermission(_.get(auth, 'claim'), [ `${permissionPrefix}.add` ])) {
      extra.push(
        <Link key="add" to={`${path}/save`}>
          <Button type="primary">Add New</Button>
        </Link>,
      );
    }
    return (
      <div className="page-header">
        <PageHeader title={title} extra={extra} />
      </div>
    );
  };

  renderMainContent = () => {
    const { items, loading } = this.state;
    const { path, permissionPrefix, auth } = this.props;
    const options = this.mapCategoriesLevel(items);
    const draggableTreeProps = {
      data: options,
      onChange: (values) => {
        const sortedData = flattenCategories(values);
        const cleanData = _.map(sortedData, (v) => {
          delete v.key;
          delete v.title;
          delete v.value;
          delete v.image;
          delete v.created_at;
          delete v.updated_at;
          return {
            ...v,
          };
        });
        this.updateCategoriesLevel(cleanData);
      },
    };
    if (authHasPermission(_.get(auth, 'claim'), [ `${permissionPrefix}.edit` ])) {
      draggableTreeProps.onEdit = (id) => this.props.history.push(`${path}/save/${id}`);
    }
    if (authHasPermission(_.get(auth, 'claim'), [ `${permissionPrefix}.delete` ])) {
      draggableTreeProps.onDelete = this.delete;
    }
    return (
      <div className="main-content">
        <Spin spinning={loading} tip="Loading...">
          <DraggableTree {...draggableTreeProps} />
        </Spin>
      </div>
    );
  };

  render () {
    return (
      <div className="layout-categories-page">
        <div className="page-header-container">
          <Affix>{this.renderPageHeader()}</Affix>
        </div>
        <div className="main-content-container">{this.renderMainContent()}</div>
      </div>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(LayoutList);

const flattenCategories = (categories, level = 0, parent_id = 0) => _.flatten(
  _.map(categories, (cat, i) => {
    const self = {
      ..._.omit(cat, [ 'children' ]),
      level,
      parent_id,
      sort: i,
      children_count: _.size(_.get(cat, 'children')),
    };
    return [ self, ...flattenCategories(cat.children, level + 1, cat.key) ];
  }),
);
