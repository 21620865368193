import React, { useEffect, useState, useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover, Form, Input, Card, Popconfirm, Divider, Upload, Icon, Spin, notification } from 'antd';

import { PageTitle } from '~/components';
import * as IntentService from '~/services/intents';
import { FallbackIntentAction } from '~/actions/fallback-intent';
import { useDidUpdateEffect } from '~/hooks';
import config from '~/config';

import { InitResponseMessageGroups, ResponseMessageReducer } from '../lib/local-reducer';
import * as ResponseMessage from '../lib/response-message-parser';
import * as LocalAction from '../lib/local-action-types';

import { SupportMediaType, PreviewMediaMessageModal } from './components';
import './style.less';


/**
 * Default form data
 *
 * @constant
 * @private
 */
const defaultFormData = {
  title: '',
  type: 'fallback-intents',
};

/**
 * Fallback intent page
 *
 * @param {any} props.form
 * @return {JSX.Element}
 */
function FallbackIntent ({ form }) {
  const [ formData, setFormData ] = useState(defaultFormData);
  const [ previewVisible, setPreviewVisible ] = useState();
  const [ uploading, setUploading ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ responseMessageGroups, dispatch ] = useReducer(ResponseMessageReducer, InitResponseMessageGroups);

  const { fallbackIntent = [], error: serviceError, loading } = useSelector((state) => state.service);
  const reduxDispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    form.validateFields(async (error, value) => {
      try {
        if (error) notification.error({ message: 'Oops something went wrong', description: error });

        const data = { ...value, answer: ResponseMessage.parseArray(responseMessageGroups) };

        if (formData.id) await IntentService.updateIntent(formData.id, JSON.stringify(data));
        else await IntentService.createIntent(JSON.stringify(data));

        notification.success({ message: 'Updated sucessfully', description: `'${value.title}' is updated successfully.` });
        setSubmitting(false);

        history.push('/intents');
      } catch (error) {
        notification.error({ message: 'Oops something went wrong', description: error });
        setSubmitting(false);
      }
    });
  };

  // use with 'image' and 'video' message
  const handleOnMediaMessageChange = (info, key, groupKey) => {
    const { file } = info;

    if (file.status === 'uploading') setUploading(true);
    if (file.status === 'done') {
      const link = file.response[0].src;

      setUploading(false);
      dispatch({
        type: LocalAction.ON_CHANGE,
        payload: { originalContentUrl: link, previewImageUrl: link },
        meta: { key, groupKey },
      });
    }
  };

  // support 'text' message type
  const renderTextMessage = (message, key, groupKey) => (
    <Input.TextArea
      rows={4}
      value={message.data.text}
      placeholder="Enter text"
      style={{ resize: 'none' }}
      onChange={(e) => dispatch({
        type: LocalAction.ON_CHANGE,
        payload: { text: e.target.value },
        meta: { key, groupKey },
      })}
    />
  );

  // support 'image' and 'video' message type
  const renderMediaMessage = (ans, key, groupKey) => {
    const { type, data } = ans;
    const { previewImageUrl } = data;

    const defaultFileList = [];
    if (previewImageUrl) defaultFileList.push({ uid: '1', name: 'image.png', status: 'done', url: previewImageUrl });

    return (
      <React.Fragment>
        <Upload
          name="file"
          key={`file-${key}`}
          accept={`${type}/*`}
          listType="picture-card"
          defaultFileList={defaultFileList}
          headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
          action={`${config.SERVICE}/${config.API_VERSION}/medias`}
          onPreview={() => setPreviewVisible(key)}
          onChange={(info) => handleOnMediaMessageChange(info, key, groupKey)}
          onRemove={() => dispatch({
            type: LocalAction.ON_CHANGE,
            payload: { originalContentUrl: '', previewImageUrl: '' },
            meta: { key, groupKey },
          })}
        >
          {_.isEmpty(previewImageUrl) && (
            <Fragment>
              <Icon type={uploading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">{uploading ? 'Uploading...' : 'Upload'}</div>
            </Fragment>
          )}
        </Upload>

        {!_.isEmpty(previewImageUrl) && (
          <PreviewMediaMessageModal
            src={previewImageUrl}
            type={type}
            visible={previewVisible === key}
            onClose={() => setPreviewVisible(undefined)}
          />
        )}

        {type === 'image' && <SupportMediaType format="JPG, JPEG" size="1 MB" width="472px" />}
        {type === 'video' && <SupportMediaType format="MP4" size="10 MB" />}
      </React.Fragment>
    );
  };

  const renderResponseMessage = (message, key, groupKey) => {
    const { type } = message;
    const keys = Object.keys(responseMessageGroups[groupKey]);

    const oneMessageLeft = keys.length === 1;
    const firstOfList = keys.indexOf(key) === 0;
    const lastOfList = keys.indexOf(key) === keys.length - 1;

    return (
      <Form.Item key={key}>
        <Card
          type="inner"
          extra={(
            <Button.Group>
              <Popover content="Move Up">
                <Button
                  icon="up"
                  type="link"
                  disabled={oneMessageLeft || firstOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'up' } })}
                />
              </Popover>
              <Popover content="Move Down">
                <Button
                  icon="down"
                  type="link"
                  disabled={oneMessageLeft || lastOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'down' } })}
                />
              </Popover>
              <Popconfirm
                placement="top"
                title="Delete this message?"
                onConfirm={() => dispatch({ type: LocalAction.REMOVE_MESSAGE, meta: { key, groupKey } })}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneMessageLeft}
              >
                <Button disabled={oneMessageLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
          title={(
            <Button.Group>
              <Popover content="Text">
                <Button
                  disabled={type !== 'text' && !ResponseMessage.isEmpty(message)}
                  type={type === 'text' ? 'primary' : 'link'}
                  icon="message"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'text', data: { text: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Image">
                <Button
                  disabled={type !== 'image' && !ResponseMessage.isEmpty(message)}
                  type={type === 'image' ? 'primary' : 'link'}
                  icon="picture"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'image', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Video">
                <Button
                  disabled={type !== 'video' && !ResponseMessage.isEmpty(message)}
                  type={type === 'video' ? 'primary' : 'link'}
                  icon="video-camera"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'video', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
            </Button.Group>
          )}
        >
          {type === 'text' && renderTextMessage(message, key, groupKey)}
          {(type === 'image' || type === 'video') && renderMediaMessage(message, key, groupKey)}
        </Card>
      </Form.Item>
    );
  };

  const renderAnswerForm = ([ groupKey, ansGroup ]) => {
    const entriesGroup = Object.entries(ansGroup);
    const oneGroupLeft = Object.keys(responseMessageGroups).length === 1;

    return (
      <Form.Item key={groupKey}>
        <Card
          loading={loading}
          title="Response Message Group"
          extra={(
            <Button.Group>
              <Popconfirm
                placement="top"
                title="Delete this response message group?"
                onConfirm={() => dispatch({ type: 'remove-group', meta: { groupKey } })}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneGroupLeft}
              >
                <Button disabled={oneGroupLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
        >
          {entriesGroup.map(([ key, message ]) => renderResponseMessage(message, key, groupKey))}
          {entriesGroup.length <= 5 && (
            <Button
              type="dashed"
              size="large"
              block
              onClick={() => dispatch({ type: LocalAction.ADD_MESSAGE, meta: { groupKey } })}
            >
              Add More Message
            </Button>
          )}
        </Card>
      </Form.Item>
    );
  };

  useDidUpdateEffect(() => {
    notification.error({ message: 'Oops something went wrong', description: serviceError });
  }, [ serviceError ]);

  useDidUpdateEffect(() => {
    if (!_.isEmpty(fallbackIntent)) setFormData(fallbackIntent);
    if (!_.isEmpty(fallbackIntent.answer)) dispatch({ type: LocalAction.SET, payload: ResponseMessage.parseObject(fallbackIntent.answer) });
  }, [ fallbackIntent ]);

  useEffect(() => {
    reduxDispatch(FallbackIntentAction.get());
  }, []);

  return (
    <div className="fallback-intent-page">
      <Spin spinning={loading} tip="loading...">
        <div className="page-title-container">
          <PageTitle
            title="Fallback Intent"
            extra={(
              <Button key="save" type="primary" form="form" htmlType="submit" loading={submitting}>
                { submitting ? 'Saving...' : 'Save' }
              </Button>
            )}
          />
        </div>

        <Divider className="is-marginless" />

        <div className="page-content-container">
          <Form
            id="form"
            layout="horizontal"
            onSubmit={handleSubmit}
            colon={false}
            hideRequiredMark
          >
            {/* hidden form */}
            {
              form.getFieldDecorator('type', {
                initialValue: formData.type,
                rules: [{ required: true, message: 'Please use intent type as \'fallback-intents\'' }],
              })(<Input type="hidden" />)
            }

            <Form.Item
              label="Intent name"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              {
                form.getFieldDecorator('title', {
                  initialValue: formData.title,
                  rules: [{ required: true, message: 'Please input fallback intent name' }],
                })(<Input placeholder="Fallback intent name" />)
              }
            </Form.Item>
            {Object.entries(responseMessageGroups).map(renderAnswerForm)}
            {Object.keys(responseMessageGroups).length < 5 && (
              <Form.Item>
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => dispatch({ type: 'add-group' })}
                >
                  Add Response Group
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </Spin>
    </div>
  );
}

FallbackIntent.propTypes = {
  form: PropTypes.shape().isRequired,
};

FallbackIntent.defaultProps = {};

export default Form.create()(FallbackIntent);
