import React, { Fragment, Component } from 'react';
import {
  PageHeader,
  Button,
  Affix,
  Tooltip,
  Icon,
  Popconfirm,
  message,
  Tag,
  Table,
  Input,
  Select,
  Row,
  Col,
  Radio,
} from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import dayjs from 'dayjs';

import { DragSortingTable } from '../../../components';
import { withRedux } from '../../../hoc';
import { fetchApi, translateData, authHasPermission, JSONToCSVConvertor } from '../../../utils';
import './style.less';

const { Option } = Select;
const { Search } = Input;

const mapStateToProps = (state) => ({
  t: _.get(state, 't'),
  languages: _.get(state, 'languages'),
  auth: _.get(state, 'auth.data'),
});

const actionToProps = {};

class LayoutList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      displayStatus: 'all', // all, trash
      items: [],
      trashItems: [],
      otherItems: [],
      searchValue: null,
      bulkAction: null,
      selectedRowKeys: [],
      filters: {},
    };
  }

  componentDidMount () {
    this.init();
  }

  init = async () => {
    // this.fetchTrashItems();
    await this.fetchOtherItems();
    await this.fetchItems();
    await this.fetchPreloadServices();
  };

  fetchPreloadServices = async () => {
    const { preloadServices } = this.props;
    const preloadServiceResponses = await Promise.all(_.map(preloadServices, 'service'));
    const mappedServices = _.reduce(preloadServiceResponses, (acc, serviceResponse, index) => {
      const targetPreloadServiceName = preloadServices[index].name || (index + 1);
      return {
        ...acc,
        [targetPreloadServiceName]: serviceResponse.data,
      };
    }, {});
    this.setState({
      preloadServices: mappedServices,
    });
  }

  fetchTrashItems = async () => {
    const { resource } = this.props;
    const trashItems = await fetchApi(`trashes/table/${resource}`).then((res) => _.get(res, 'data'));
    this.setState({
      trashItems,
    });
  };

  fetchOtherItems = async () => {
    const { otherResource } = this.props;
    const otherItems = await _.reduce(
      otherResource,
      async (result, resource) => {
        const items = await fetchApi(`${resource}`).then((res) => _.get(res, 'data'));
        return {
          ...(await result),
          [resource]: items,
        };
      },
      Promise.resolve({}),
    );
    this.setState({
      otherItems,
    });
  };

  fetchItems = async () => {
    this.setState({ loading: true });

    const { resource, enableModule, query } = this.props;
    const params = new URLSearchParams();

    if (!_.isEmpty(query)) {
      Object.entries(query).forEach(([ key, values ]) => {
        if (Array.isArray(values)) {
          values.map((value) => params.append(`${key}[]`, value));
        } else {
          params.append(key, values);
        }
      });
    }

    const items = await fetchApi(`${resource}?${params.toString()}`)
      .then((res) => res) // _.get(res, "data"))
      .then((items) => {
        if (enableModule.sort) {
          return _.orderBy(items, [ 'sort', 'created_at' ], [ 'asc', 'desc' ]);
        }
        return items;
      });
    this.setState({
      items,
      loading: false,
    });
  };

  handleApplyBulkActions = () => {
    const { selectedRowKeys, bulkAction } = this.state;
    if (_.isEmpty(selectedRowKeys)) return;
    if (_.isEmpty(bulkAction)) return;
    if (bulkAction === 'delete') this._deleteBulk();
    if (bulkAction === 'restore') this._restoreBulk();
    if (bulkAction === 'delete_permanently') this._deletePermanentlyBulk();
  };

  getLeftActions = () => {
    const { t, enableModule, formItems } = this.props;
    const { otherItems, displayStatus } = this.state;
    const options = [{ value: null, label: 'Bulk Actions' }];
    if (displayStatus === 'all') {
      options.push({ value: 'delete', label: 'Delete' });
    }
    if (displayStatus === 'trash') {
      options.push({ value: 'restore', label: 'Restore' });
      options.push({
        value: 'delete_permanently',
        label: 'Delete Permanently',
      });
    }
    const actions = [
      <React.Fragment>
        <Select
          placeholder="Bulk Actions"
          style={{ minWidth: 120 }}
          onChange={(value) => this.setState({ bulkAction: value })}
          value={this.state.bulkAction}
        >
          {_.map(options, (option, index) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {' '}
        <Button type="primary" onClick={this.handleApplyBulkActions}>
          Apply
        </Button>
      </React.Fragment>,
    ];
    if (enableModule.publish) {
      actions.push(
        <Select
          allowClear
          placeholder="Published"
          style={{ minWidth: 120 }}
          onChange={(value) => {
            const { filters } = this.state;
            if (value === undefined) {
              delete filters.published;
            } else {
              filters.published = {
                value,
                condition_type: 'eq',
              };
            }
            this.setState({ filters });
          }}
        >
          <Option value>Published</Option>
          <Option value={false}>No Publish</Option>
        </Select>,
      );
    }
    if (enableModule.recommend) {
      actions.push(
        <Select
          allowClear
          placeholder="Recommended"
          style={{ minWidth: 150 }}
          onChange={(value) => {
            const { filters } = this.state;
            if (value === undefined) {
              delete filters.recommended;
            } else {
              filters.recommended = {
                value,
                condition_type: 'eq',
              };
            }
            this.setState({ filters });
          }}
        >
          <Option value>Recommended</Option>
          <Option value={false}>No Recommend</Option>
        </Select>,
      );
    }
    _.map(formItems, (formItem) => {
      const { filter, field, label, inputType, relation } = formItem;
      if (!filter) return;
      const resource = _.get(relation, 'resource');
      const mapResourceId = _.get(relation, 'mapResource.id');
      const mapResourceLabel = _.get(relation, 'mapResource.label');
      const datas = _.get(otherItems, resource);
      if (inputType === 'select') {
        actions.push(
          <Select
            showSearch
            allowClear
            filterOption={(input, option) => option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            placeholder={label}
            style={{ minWidth: 120 }}
            onChange={(value) => {
              const { filters } = this.state;
              if (value === undefined) {
                delete filters[field];
              } else {
                filters[field] = {
                  value,
                  condition_type: 'in',
                };
              }
              this.setState({ filters });
            }}
          >
            {_.map(datas, (data, index) => (
              <Option key={index} value={_.get(data, mapResourceId)}>
                {t(_.get(data, mapResourceLabel))}
              </Option>
            ))}
          </Select>,
        );
      }
    });
    return actions;
  };

  handleExportCsv = () => {
    const { preloadServices } = this.state;

    const items = this.filterItems();
    const { questions } = preloadServices;

    const dataToExport = _.reduce(items, (acc, item, index) => {
      const removeKeys = [ 'id', 'line', 'privilege', 'tierId', 'deletedAt', 'updatedAt', 'version' ];
      const modifiedItem = _.omit(item, removeKeys);
      const createdAt = dayjs(modifiedItem.createdAt).format('YYYY-MM-DD');

      const filterPropertyCategory = _.filter(modifiedItem.properties, (propertyAnswer) => {
        const [ questionId ] = propertyAnswer.split(':');
        return `${questionId}` === '2';
      });
      const filterPropertyRemaining = _.filter(modifiedItem.properties, (propertyAnswer) => {
        const [ questionId ] = propertyAnswer.split(':');
        return `${questionId}` !== '2';
      });
      const categoryAnswersGroupByQuestionId = _.groupBy(filterPropertyCategory, (property) => {
        const [ questionId ] = _.split(property, ':');
        return questionId;
      });
      const categoryProperties = _.reduce(categoryAnswersGroupByQuestionId, (acc2, answers, questionId) => {
        const targetQuestion = _.find(questions, (question) => `${question.id}` === `${questionId}`);
        if (!targetQuestion) return acc2;
        // const questionText = _.get(targetQuestion, 'title.en', '');
        const { choices } = targetQuestion;
        const choiceTexts = _.map(answers, (answer) => {
          const answerId = _.split(answer, ':')[1];
          const targetAnswer = _.find(choices, (choice) => `${choice.id}` === `${answerId}`);
          return _.get(targetAnswer, 'title.en');
        });
        return `${acc2} ${_.join(choiceTexts, ',')}`;
      }, '');

      const answersGroupByQuestionId = _.groupBy(filterPropertyRemaining, (property) => {
        const [ questionId ] = _.split(property, ':');
        return questionId;
      });
      let currentQuestionId;
      const properties = _.reduce(answersGroupByQuestionId, (acc2, answers, questionId) => {
        const targetQuestion = _.find(questions, (question) => `${question.id}` === `${questionId}`);
        if (!targetQuestion) return acc2;
        const questionText = _.get(targetQuestion, 'title.en', '');
        const { choices } = targetQuestion;
        const choiceTexts = _.map(answers, (answer) => {
          const answerId = _.split(answer, ':')[1];
          const targetAnswer = _.find(choices, (choice) => `${choice.id}` === `${answerId}`);
          return _.get(targetAnswer, 'title.en');
        });
        if (!currentQuestionId || currentQuestionId !== questionId) {
          currentQuestionId = questionId;
        }
        return {
          ...acc2,
          [`${questionText}`]: _.join(choiceTexts, ','),
        };
      }, {});
      const categoryQuestionText = _.get(_.find(questions, (question) => `${question.id}` === '2'), 'title.en', '');
      const reModifiedItem = _.omit(modifiedItem, [ 'properties' ]);
      return [
        ...acc,
        {
          // '#': index + 1,
          ...reModifiedItem,
          [`${categoryQuestionText}`]: categoryProperties,
          ...properties,
          createdAt,
        },
      ];
    }, []);
    JSONToCSVConvertor(dataToExport, 'line_registered_members', true);
  }

  getRightActions = () => {
    const { preloadServices } = this.state;
    const actions = [
      <Row type="flex" justify="space-between" gutter={16}>
        <Col>
          <Button
            type="primary"
            disabled={
              preloadServices === undefined || (
                Object.keys(preloadServices).length <= 0
              )
            }
            onClick={this.handleExportCsv}
          >
            Export CSV
          </Button>
        </Col>
        <Col>
          <Search
            autoFocus
            placeholder="Search"
            enterButton
            onSearch={(searchValue) => {
              if (this.state.searchValue !== searchValue) {
                this.setState({ searchValue });
              }
            }}
          />
        </Col>
      </Row>,
    ];
    return actions;
  };

  renderActionsBar = () => {
    const leftActions = []; // this.getLeftActions();
    const rightActions = this.getRightActions();
    return (
      <div className="actions-bar">
        <Row type="flex" justify="space-between">
          <Col>
            <div className="left">
              <ul>
                {_.map(leftActions, (leftAction, index) => <li key={index}>{leftAction}</li>)}
              </ul>
            </div>
          </Col>
          <Col>
            <div className="right">
              <ul>
                {_.map(rightActions, (rightAction, index) => <li key={index}>{rightAction}</li>)}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  renderPageHeader = () => {
    const { displayStatus, items, trashItems } = this.state;
    const { title, path, permissionPrefix, auth } = this.props;
    const extra = [];
    if (
      authHasPermission(_.get(auth, 'claims'), [ `${permissionPrefix}.add` ])
    ) {
      // extra.push(
      //   <Link key="add" to={`${path}/save`}>
      //     <Button type="primary">Add New</Button>
      //   </Link>
      // );
    }
    return (
      <div className="page-header">
        <PageHeader
          title={title}
          // subTitle={
          //   <Radio.Group
          //     size="small"
          //     buttonStyle="solid"
          //     value={displayStatus}
          //     onChange={e => {
          //       const value = e.target.value;
          //       if (value === displayStatus) return;
          //       this.setState({ displayStatus: value, bulkAction: null });
          //     }}
          //   >
          //     <Radio.Button value="all">All ({_.size(items)})</Radio.Button>
          //     <Radio.Button value="trash">
          //       Trash ({_.size(trashItems)})
          //     </Radio.Button>
          //   </Radio.Group>
          // }
          extra={extra}
        />
        <div className="actions-bar-container">{this.renderActionsBar()}</div>
      </div>
    );
  };

  _update = async (id, data) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { resource } = this.props;
    await fetchApi(`${resource}/${id}`, 'PUT', JSON.stringify(data));
    this.setState({ loading: false });
  };

  _delete = async (id) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { resource } = this.props;
    console.log({ resource });
    const deleted = await fetchApi(`${resource}/${id}?$hard=true`, 'DELETE');
    // if (_.get(deleted, "status") !== "success") {
    // message.error(_.get(deleted, "message", "Delete failed."));
    // } else {
    await this.fetchItems();
    await this.fetchTrashItems();
    message.success('Delete success.');
    // }
    this.setState({ loading: false });
  };

  _restore = async (trash_id) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const restoreResult = await fetchApi(`trashes/restore/${trash_id}`);
    if (_.get(restoreResult, 'status') !== 'success') {
      message.error(_.get(restoreResult, 'message', 'Restore failed.'));
    } else {
      await this.fetchItems();
      await this.fetchTrashItems();
      message.success('Restore success.');
    }
    this.setState({ loading: false });
  };

  _deletePermanently = async (trash_id) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const deleteResult = await fetchApi(`trashes/${trash_id}`, 'DELETE');
    if (_.get(deleteResult, 'status') !== 'success') {
      message.error(_.get(deleteResult, 'message', 'Delete failed.'));
    } else {
      await this.fetchItems();
      await this.fetchTrashItems();
      message.success('Delete success.');
    }
    this.setState({ loading: false });
  };

  _deleteBulk = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { resource } = this.props;
    const { selectedRowKeys } = this.state;
    const items = this.filterItems();
    await Promise.all(
      _.map(selectedRowKeys, async (selectedRowKey) => {
        const item = items[selectedRowKey - 1];
        const deleteResult = await fetchApi(`${resource}/${item.id}`, 'DELETE');
        return deleteResult;
      }),
    );
    await this.fetchItems();
    await this.fetchTrashItems();
    this.setState({ selectedRowKeys: [], bulkAction: null, loading: false });
    message.success('Delete success.');
  };

  _restoreBulk = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { selectedRowKeys, trashItems } = this.state;
    for (let index = 0; index < selectedRowKeys.length; index++) {
      const selectedRowKey = selectedRowKeys[index];
      const currentData = trashItems[selectedRowKey - 1];
      await fetchApi(`trashes/restore/${currentData.trash_id}`);
    }
    await this.fetchItems();
    await this.fetchTrashItems();
    this.setState({ selectedRowKeys: [], bulkAction: null, loading: false });
    message.success('Restore success.');
  };

  _deletePermanentlyBulk = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { selectedRowKeys, trashItems } = this.state;
    for (let index = 0; index < selectedRowKeys.length; index++) {
      const selectedRowKey = selectedRowKeys[index];
      const currentData = trashItems[selectedRowKey - 1];
      await fetchApi(`trashes/${currentData.trash_id}`, 'DELETE');
    }
    await this.fetchItems();
    await this.fetchTrashItems();
    this.setState({ selectedRowKeys: [], bulkAction: null, loading: false });
    message.success('Delete success.');
  };

  getDataTable = () => {
    const { displayStatus, trashItems } = this.state;
    const {
      formItems,
      path,
      enableModule,
      permissionPrefix,
      auth,
      noEditIds,
      noDeleteIds,
    } = this.props;

    const items = this.filterItems();

    const getColumn = () => {
      const columns = [];
      columns.push({
        title: '#',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => _.toInteger(a.key) - _.toInteger(b.key),
        render: (text) => text,
      });
      for (let index = 0; index < formItems.length; index++) {
        const formItem = formItems[index];
        const { tableDisplay, field, label, render } = formItem;
        if (tableDisplay === true) {
          columns.push({
            title: label,
            dataIndex: field,
            key: field,
            sorter: (a, b) => a[field].localeCompare(b[field]),
            render: render ? (text, record) => render(text, record) : (text) => _.toString(text),
          });
        }
      }
      if (enableModule.publish) {
        columns.push({
          title: 'Published',
          dataIndex: 'published',
          key: 'published',
          sorter: (a, b) => b.published - a.published,
          render: (text) => (
            <Tag color={text ? 'green' : 'red'}>
              {text ? 'Published' : 'No Publish'}
            </Tag>
          ),
        });
      }
      if (enableModule.recommend) {
        columns.push({
          title: 'Recommended',
          dataIndex: 'recommended',
          key: 'recommended',
          sorter: (a, b) => b.recommended - a.recommended,
          render: (text) => (
            <Tag color={text ? 'green' : 'red'}>
              {text ? 'Recommended' : 'No Recommend'}
            </Tag>
          ),
        });
      }
      // columns.push({
      //   title: "Create Date",
      //   dataIndex: "created_at",
      //   key: "created_at",
      //   sorter: (a, b) => a.created_at.localeCompare(b.created_at)
      // });

      const getActionBtns = (id) => {
        const trashItem = _.find(trashItems, { id });
        const trash_id = _.get(trashItem, 'trash_id');
        const actionBtns = [];
        const hasDeletePermission = authHasPermission(
          _.get(auth, 'claim'),
          [ `${permissionPrefix}.delete` ],
        );
        const canEditThisId = !_.includes(noEditIds, id);
        const noDeleteThisId = !_.includes(noDeleteIds, id);
        if (displayStatus === 'all' && canEditThisId) {
          actionBtns.push(
            <Link to={`${path}/save/${id}`}>
              <Tooltip title="Edit">
                <Icon type="edit" />
              </Tooltip>
            </Link>,
          );
          if (hasDeletePermission && noDeleteThisId) {
            actionBtns.push(
              <Popconfirm
                title="Are you sure delete this task?"
                onConfirm={() => this._delete(id)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete">
                  <Icon type="delete" />
                </Tooltip>
              </Popconfirm>,
            );
          }
        } else if (displayStatus === 'trash') {
          if (hasDeletePermission) {
            actionBtns.push(
              <Popconfirm
                title="Are you sure restore this task?"
                onConfirm={() => this._restore(trash_id)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Restore">
                  <Icon type="rollback" />
                </Tooltip>
              </Popconfirm>,
            );
            actionBtns.push(
              <Popconfirm
                title="Are you sure delete this task?"
                onConfirm={() => this._deletePermanently(trash_id)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete Permanently">
                  <Icon type="delete" />
                </Tooltip>
              </Popconfirm>,
            );
          }
        }
        return actionBtns;
      };

      columns.push({
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (id) => {
          const actionBtns = getActionBtns(id);
          return (
            <ul className="actions-btn">
              {_.map(actionBtns, (actionBtn, index) => <li key={index}>{actionBtn}</li>)}
            </ul>
          );
        },
      });
      return columns;
    };

    const getDataSource = () => {
      const { t, formItems } = this.props;
      const translateItems = _.map(items, (item) => translateData(item, t, formItems));
      const dataSource = _.map(translateItems, (value, index) => ({
        ...value,
        key: index + 1,
      }));
      return dataSource;
    };

    const columns = getColumn();
    const dataSource = getDataSource();

    return { columns, dataSource };
  };

  renderMainContent = () => {
    const { enableModule } = this.props;
    const { loading, selectedRowKeys } = this.state;
    const { columns, dataSource } = this.getDataTable();
    const tableProps = {
      rowSelection: {
        selectedRowKeys,
        onChange: (selectedRowKeys) => this.setState({ selectedRowKeys }),
      },
      columns,
      dataSource,
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: [ '10', '20', '30', '40', '50', '100' ],
        showTotal: (total) => `Total ${total} items`,
        showQuickJumper: true,
      },
      onMove: async (value) => {
        const { data, dragIndex, hoverIndex } = value;
        let startIndex = dragIndex;
        let endIndex = hoverIndex;
        if (dragIndex > hoverIndex) {
          startIndex = hoverIndex;
          endIndex = dragIndex;
        }
        for (let index = startIndex; index <= endIndex; index++) {
          const element = data[index];
          await this._update(element.id, { sort: index });
        }
      },
      size: 'small',
      loading,
      // scroll: { y: window.innerHeight / 1.5 }
    };
    return (
      <div className="main-content">
        {enableModule.sort ? (
          <DragSortingTable {...tableProps} />
        ) : (
          <Table {...tableProps} />
        )}
      </div>
    );
  };

  filterItems = () => {
    const {
      items,
      trashItems,
      searchValue,
      filters,
      displayStatus,
    } = this.state;
    const { formItems } = this.props;
    const requiredFieldsToShow = _.map(_.filter(formItems, formItem => formItem.requiredToShow), 'field');
    const filterKey = _.map(_.filter(formItems, 'search'), (formItem) => formItem.field);
    const languages = this.props.languages.data;
    let newItems = _.filter(items, item => _.every(requiredFieldsToShow, requiredFieldToShow => item[requiredFieldToShow]));
    if (displayStatus === 'trash') {
      newItems = trashItems;
    }
    if (!_.isEmpty(filters)) {
      _.map(filters, (filter, field) => {
        const value = _.get(filter, 'value');
        const conditionType = _.get(filter, 'condition_type', 'eq');
        // eq       => Equals.
        // finset   =>	A value within a set of values
        // from     =>	The beginning of a range. Must be used with to
        // gt       =>	Greater than
        // gteq     =>	Greater than or equal
        // in       =>	In. The value can contain a comma-separated list of values.
        // like     =>	Like. The value can contain the SQL wildcard characters when like is specified.
        // lt       =>	Less than
        // lteq     =>	Less than or equal
        // moreq    =>	More or equal
        // neq      =>	Not equal
        // nfinset  =>	A value that is not within a set of values
        // nin      =>	Not in. The value can contain a comma-separated list of values.
        // notnull  =>	Not null
        // null     =>	Null
        // to       =>	The end of a range. Must be used with from
        if (conditionType === 'eq') {
          newItems = _.filter(newItems, { [field]: value });
        } else if (conditionType === 'in') {
          newItems = _.filter(newItems, (newItem) => _.includes(newItem[field], value));
        }
      });
    }
    if (searchValue) {
      const keys = [ ...(filterKey || []) ];
      _.map(filterKey, (value) => {
        _.map(languages, (language) => {
          keys.push(`${value}.${language.code}`);
        });
      });
      const options = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys,
      };
      const fuse = new Fuse(newItems, options);
      newItems = fuse.search(searchValue);
    }
    return newItems;
  };

  render () {
    return (
      <div className="layout-list-page">
        <div className="page-header-container">
          <Affix>{this.renderPageHeader()}</Affix>
        </div>
        <div className="main-content-container">{this.renderMainContent()}</div>
      </div>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(LayoutList);
