import _ from 'lodash';
import { fetchApi, toObject } from '../utils';

const getLanguagesApi = () => fetchApi('languages')
  .then((res) => _.get(res, 'data'))
  .then(toObject);

const getLanguagesByIdApi = (id) => fetchApi(`languages/${id}`);

const createLanguagesApi = (data) => fetchApi('languages', 'POST', JSON.stringify(data));

const updateLanguagesApi = (id, data) => fetchApi(`languages/${id}`, 'PUT', JSON.stringify(data));

const deleteLanguagesApi = (id) => fetchApi(`languages/${id}`, 'DELETE');

export {
  getLanguagesApi,
  getLanguagesByIdApi,
  createLanguagesApi,
  updateLanguagesApi,
  deleteLanguagesApi,
};
