import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { Modal, Typography, Row, Col } from 'antd';
import cx from 'classnames';

import { List } from '~/components';
import { joinPublic } from '~/utils';
import { large, compact } from '~/assets/json/rich-menu-template.json';

import './style.less';


/**
 * Rich menu template modal
 *
 * @param {Object} props
 * @param {(item: any, index: number) => void} [props.onChange = () => {}]
 * @param {React.RefAttributes} ref
 * @return {JSX.Element}
 */
const RichMenuTemplateModel = forwardRef(({ onApply }, ref) => {
  const [ visible, setVisible ] = useState(false);
  const [ selected, setSelected ] = useState({});

  const close = () => setVisible(false);

  const handleOnApply = () => {
    onApply(selected);
    close();
  };

  const handleOnApplyDoubleClick = (item) => {
    onApply(item);
    close();
  };

  const renderOption = (item, index) => (
    <Col span={6}>
      <div
        tabIndex={index}
        role="button"
        onClick={() => setSelected(item)}
        onKeyPress={() => setSelected(item)}
        onDoubleClick={() => handleOnApplyDoubleClick(item)}
        className={cx('rich-menu-template-modal-item', { 'is-active': item.id === selected.id })}
      >
        <div className="filter" />
        <img src={joinPublic(item.src)} alt={item.id} />
      </div>
    </Col>
  );

  useImperativeHandle(ref, () => ({
    open () {
      setVisible(true);
    },
  }), []);

  return (
    <Modal
      title="Select a template"
      wrapClassName="rich-menu-template-modal"
      visible={visible}
      okText="Apply"
      onOk={handleOnApply}
      onCancel={close}
    >
      <div>
        <Typography.Title level={4}>Large</Typography.Title>
        <Row gutter={[ 14, 14 ]}>
          <List
            items={large}
            keyExtractor={(item) => item.id.toString()}
            render={renderOption}
          />
        </Row>
      </div>

      <div>
        <Typography.Title level={4}>Compact</Typography.Title>
        <Row gutter={[ 14, 14 ]}>
          <List
            items={compact}
            keyExtractor={(item) => item.id.toString()}
            render={renderOption}
          />
        </Row>
      </div>
    </Modal>
  );
});

RichMenuTemplateModel.propTypes = {
  onApply: PropTypes.func,
};

RichMenuTemplateModel.defaultProps = {
  onApply () {},
};

export default RichMenuTemplateModel;
