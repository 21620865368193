import React, { Component } from 'react';
import { Button, Modal, Upload, Icon, message, Pagination } from 'antd';
import _ from 'lodash';
import { BackgroundImage, DragAndDrop } from '..';
import { withRedux } from '../../hoc';
import { getMedias } from '../../actions/medias';
import { sortCollection, convertSelectedFiles } from '../../utils';
import './style.less';

const { Dragger } = Upload;

const mapStateToProps = (state) => ({
  medias: _.get(state, 'medias'),
});

const actionToProps = {
  getMedias,
};

class MediaPopup extends Component {
  static defaultProps = {
    multiple: false,
  };

  state = {
    lastValue: null,
    selectedFiles: [],
    selectedFilesTmp: [],
    uploading: false,
    showMediaModal: false,
    currentPage: 1,
    pageSize: 10,
  };

  static getDerivedStateFromProps (props, state) {
    if (!_.isEqual(props.value, state.lastValue)) {
      let selectedFiles = convertSelectedFiles(props.value);
      if (!_.isArray(selectedFiles)) selectedFiles = [ selectedFiles ];
      return {
        lastValue: props.value,
        selectedFiles,
        selectedFilesTmp: selectedFiles,
      };
    }
    return null;
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.selectedFiles, this.state.selectedFiles)) {
      this.onChange();
    }
  }

  handleClickAddMedia = () => {
    this.setState({ showMediaModal: !this.state.showMediaModal });
  };

  handleCancelMediaModal = () => {
    this.setState({ selectedFilesTmp: [], showMediaModal: false });
  };

  onChange = () => {
    const { multiple } = this.props;
    if (multiple) {
      this.props.onChange(_.compact(this.state.selectedFiles));
    } else {
      this.props.onChange(_.get(this.state, 'selectedFiles.0', null));
    }
  };

  handleOk = () => {
    this.setState({
      selectedFiles: this.state.selectedFilesTmp,
      showMediaModal: false,
    });
  };

  uploadButton = () => (
    <div>
      <Icon type={this.state.uploading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  beforeUpload = (file) => true;

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true });
    } else {
      this.setState({ uploading: false });
    }
    if (info.file.status === 'done') {
      this.props.actions.getMedias();
    } else if (info.file.status === 'error') {
      message.error(info.file.error.message);
    }
  };

  handleSelectMedia = (id) => {
    const { multiple } = this.props;
    const { selectedFilesTmp } = this.state;
    let selectedFilesTmpNew = [ ...selectedFilesTmp ];
    if (_.includes(selectedFilesTmp, id)) {
      // selectedFilesTmpNew = _.filter(selectedFilesTmp, item => item !== id);
    } else if (multiple) {
      selectedFilesTmpNew.push(id);
    } else {
      selectedFilesTmpNew = [ id ];
    }
    this.setState({ selectedFilesTmp: selectedFilesTmpNew });
  };

  handleDeselectMedia = (id) => {
    const { selectedFilesTmp } = this.state;
    let selectedFilesTmpNew = [ ...selectedFilesTmp ];
    if (_.includes(selectedFilesTmp, id)) {
      selectedFilesTmpNew = _.filter(selectedFilesTmp, (item) => item !== id);
    } else {
      // selectedFilesTmpNew.push(id);
    }
    this.setState({ selectedFilesTmp: selectedFilesTmpNew });
  };

  handleRemoveMedia = (id) => {
    const { selectedFiles } = this.state;
    let selectedFilesNew = [ ...selectedFiles ];
    if (_.includes(selectedFiles, id)) {
      selectedFilesNew = _.filter(selectedFiles, (item) => item !== id);
    } else {
      // selectedFilesNew.push(id);
    }
    this.setState({ selectedFiles: selectedFilesNew });
  };

  renderMediaByType = (media) => {
    const { name, full_file_path, type } = media;
    if (type === 'image') {
      return (
        <div className="type-image">
          <BackgroundImage image={full_file_path} size="contain" />
          <div className="file-name">{name}</div>
        </div>
      );
    } if (type === 'application' || type === 'video') {
      return (
        <div className="type-file">
          <div className="icon-file">
            <Icon type="file" theme="outlined" />
          </div>
          <div className="file-name">{name}</div>
        </div>
      );
    }
  };

  filterMedias = () => {
    const medias = sortCollection(_.get(this.props, 'medias.data'));
    return medias;
  };

  handleChangePagination = (page, pageSize) => {
    this.setState({ currentPage: page, pageSize });
  };

  handleShowSizeChange = (current, pageSize) => {
    this.setState({ currentPage: current, pageSize });
  };

  handleReorderMedia = (items) => {
    const ids = _.map(items, (item) => item.id);
    this.setState({ selectedFiles: ids, selectedFilesTmp: ids });
  };

  render () {
    const {
      showMediaModal,
      selectedFiles,
      selectedFilesTmp,
      currentPage,
      pageSize,
    } = this.state;
    const { label } = this.props;
    const medias = this.filterMedias();
    const mediaCount = _.size(medias);
    const mediasPagination = _.slice(
      medias,
      (currentPage - 1) * pageSize,
      currentPage * pageSize,
    );
    return (
      <div className="media-component">
        <Button type="primary" onClick={this.handleClickAddMedia}>
          {`Add ${label}`}
        </Button>
        <div className="media-items">
          {!_.isEmpty(selectedFiles) && !_.isEmpty(medias) && (
            <DragAndDrop
              items={_.map(selectedFiles, (selectedFile) => {
                const media = _.find(medias, { id: selectedFile });
                const id = _.get(media, 'id');
                const full_file_path = _.get(media, 'full_file_path');
                return {
                  id,
                  full_file_path,
                  media,
                };
              })}
              uiRenderer={(item) => (
                <div className="item">
                  <div
                    className="check-block"
                    onClick={() => this.handleRemoveMedia(item.id)}
                  >
                    <Icon type="close" theme="outlined" />
                  </div>
                  <a
                    href={item.full_file_path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.renderMediaByType(item.media)}
                  </a>
                </div>
              )}
              onChange={this.handleReorderMedia}
            />
          )}
        </div>
        <Modal
          className="media-modal"
          title="Media Library"
          visible={showMediaModal}
          okText="Select"
          cancelText={null}
          onOk={this.handleOk}
          onCancel={this.handleCancelMediaModal}
          destroyOnClose
          width="100%"
          footer={[
            <div key="left" className="left">
              <Pagination
                showSizeChanger
                onChange={this.handleChangePagination}
                onShowSizeChange={this.handleShowSizeChange}
                current={currentPage}
                total={mediaCount}
                showTotal={(total) => `Total ${total} items`}
                showQuickJumper
              />
            </div>,
            <div key="right" className="right">
              <Button key="back" onClick={this.handleCancelMediaModal}>
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={this.handleOk}>
                Select
              </Button>
            </div>,
          ]}
        >
          <div className="media-items">
            <div className="item item-upload">
              <Dragger
                accept="image/*, video/*, application/pdf"
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                multiple
                action={`${process.env.REACT_APP_SERVICE}/${process.env.REACT_APP_API}/medias`}
                headers={{
                  Authorization: `Bearer ${window.localStorage.getItem(
                    'token',
                  )}`,
                }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                {this.uploadButton()}
              </Dragger>
            </div>
            {_.map(mediasPagination, (media) => {
              const { id } = media;
              const active = _.includes(selectedFilesTmp, id);
              const activeClassName = active ? 'active' : '';
              return (
                <div className={`item ${activeClassName}`} key={id}>
                  {active && (
                    <div
                      className="check-block"
                      onClick={() => this.handleDeselectMedia(id)}
                    >
                      <Icon className="check" type="check" theme="outlined" />
                      <Icon className="minus" type="minus" theme="outlined" />
                    </div>
                  )}
                  <div
                    onClick={() => this.handleSelectMedia(id)}
                    style={{ width: '100%', height: '100%' }}
                  >
                    {this.renderMediaByType(media)}
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(MediaPopup);
