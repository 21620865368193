import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Button, Form, Typography, Input, Radio, Select, Icon, Tooltip, Row, Col, Spin, Modal, notification, Checkbox } from 'antd';

import { PageTitle } from '~/components';
import { TierAction } from '~/actions/tier';
import { RichMenuAction } from '~/actions/richMenu';
import { RichMenuService } from '~/services/rich-menu';

import RichMenuTemplateModal from './components/RichMenuTemplateModal';
import TemplateSelector from './components/TemplateSelector';
import ActionForm from './components/ActionForm';
import './style.less';


/**
 * Default form data
 *
 * @constant
 * @private
 */
const defaultFormData = {
  id: '',
  title: '',
  tierId: undefined,
  imageId: undefined,
  richMenuId: '',
  default: false,
  content: {
    chatBarText: '',
    selected: 'true',
    size: { width: undefined, height: undefined },
    areas: [{ action: {} }],
  },
};

const MAX_TITLE_LENGTH = 40;
const MAX_MENU_BAR_LABEL_LENGTH = 14;

/**
 * Create and update rich menu
 *
 * @return {JSX.Element}
 */
function CreateRichMenu ({ form }) {
  const [ rawData, setRawData ] = useState(defaultFormData);
  const [ formData, setFormData ] = useState(defaultFormData);
  const [ submitting, setSubmitting ] = useState(false);
  const [ template, setTemplate ] = useState();

  const richMenuTemplateRef = useRef(null);
  const actionFormRef = useRef(null);

  const { id: slug } = useParams();
  const history = useHistory();
  const { tiers = [], error, loading } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const isEditing = !_.isEmpty(slug);

  const handleRemoveRichMenu = () => {
    Modal.confirm({
      title: 'Are you absolutely sure?',
      content: `This action CANNOT be undone. This will permanently remove '${formData.title}' rich menu.`,
      okText: 'Remove',
      okType: 'danger',
      async onOk () {
        await dispatch(RichMenuAction.remove(formData.id));
        history.push('/rich-menus');
      },
    });
  };

  const handleRemoveDefaultRichMenu = (value) => {
    Modal.confirm({
      title: 'Default Rich Menu?',
      content: 'This action CANNOT be undone. This will permanently remove your default rich menu',
      okText: 'Remove',
      okType: 'danger',
      onOk: async () => {
        await RichMenuService.removeDefault({ id: value.id });
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    form.validateFields(async (formError, value) => {
      try {
        if (formError) throw formError;

        let notify = { message: '', description: '' };

        const areas = value.content.areas.map((area, index) => ({ ...area, bounds: template.areas[index].bounds }));
        const data = _.omit({
          ...value,
          content: { ...value.content, selected: value.content.selected === 'true', areas },
        }, [ 'default' ]);

        if (!isEditing) {
          const { data: res } = await RichMenuService.create(data);
          notify = {
            message: 'Created successfully',
            description: `Your new rich menu name '${res.title}' was created successfully.`,
          };
        } else {
          await dispatch(RichMenuAction.update(data.id, data));
          notify = {
            message: 'Edited successfully',
            description: `Your rich menu name '${data.title}' was edited successfully.`,
          };
        }

        if (value.default) {
          await RichMenuService.setDefault({ id: value.id });
        } else if (!value.default && rawData.default) {
          handleRemoveDefaultRichMenu(value);
        }

        notification.success(notify);
        setSubmitting(false);

        history.push('/rich-menus');
      } catch (err) {
        console.error(err);
        notification.error({ message: 'Oops! something went wrong' });
        setSubmitting(false);
      }
    });
  };

  const renderToolTip = (title) => (
    <Tooltip title={<p style={{ textAlign: 'center', margin: 0 }}>{title}</p>}>
      <Icon type="question-circle" style={{ color: '#999' }} />
    </Tooltip>
  );

  useEffect(() => {
    if (error) notification.error({ message: 'Oops! something went wrong.', description: error });
  }, [ error ]);

  useEffect(() => {
    dispatch(TierAction.find());

    if (isEditing) {
      dispatch(async (d) => {
        const richMenu = await RichMenuAction.get(slug)(d);
        richMenu.content.selected = richMenu.content.selected.toString();
        setRawData(richMenu);
        setFormData(richMenu);
        setTemplate(richMenu.content);
      });
    }
  }, []);

  return (
    <Spin spinning={loading || submitting} wrapperClassName="save-rich-menu-page">
      <RichMenuTemplateModal
        ref={richMenuTemplateRef}
        onApply={setTemplate}
      />

      <div className="page-title-container">
        <PageTitle
          title={isEditing ? 'Edit Rich Menu' : 'Create Rich Menu'}
          extra={(
            <div className="button-group">
              {isEditing && <Button onClick={handleRemoveRichMenu}>Remove</Button>}
              <Button type="primary" key="save" htmlType="submit" form="form">Save</Button>
            </div>
          )}
        />
      </div>

      <Divider className="is-marginless" />

      <div className="page-content-container">
        <Form
          id="form"
          layout="horizontal"
          onSubmit={handleSubmit}
          colon={false}
          hideRequiredMark
        >
          <Typography.Title level={3}>Settings</Typography.Title>

          {/* hidden input */}
          { isEditing && form.getFieldDecorator('id', { initialValue: formData.id })(<Input type="hidden" />) }
          { isEditing && form.getFieldDecorator('richMenuId', { initialValue: formData.richMenuId })(<Input type="hidden" />) }

          <Form.Item
            label="Title"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            help={form.getFieldError('title') || `${(form.getFieldValue('title') || '').length} / ${MAX_TITLE_LENGTH}`}
          >
            {
              form.getFieldDecorator('title', {
                initialValue: formData.title,
                rules: [
                  { required: true, message: 'Please enter title' },
                  { max: MAX_TITLE_LENGTH, message: `${(form.getFieldValue('title') || '').length} / ${MAX_TITLE_LENGTH}` },
                ],
              })(
                <Input placeholder="Enter title" />,
              )
            }
          </Form.Item>

          <Form.Item
            label={(
              <span>
                Menu Label &nbsp;
                {renderToolTip('This label is shown on the menu bar at the bottom of the chat screen.')}
              </span>
            )}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 4 }}
            help={form.getFieldError('content.chatBarText') || `${(form.getFieldValue('content.chatBarText') || formData.content.chatBarText).length} / ${MAX_MENU_BAR_LABEL_LENGTH}`}
          >
            {
              form.getFieldDecorator('content.chatBarText', {
                initialValue: formData.content.chatBarText,
                rules: [
                  { required: true, message: 'Please enter menu bar label' },
                  { max: MAX_MENU_BAR_LABEL_LENGTH, message: `${(form.getFieldValue('content.chatBarText') || '').length} / ${MAX_MENU_BAR_LABEL_LENGTH}` },
                ],
              })(<Input placeholder="Enter menu bar label" />)
            }
          </Form.Item>

          <Form.Item
            label={(
              <span>
                Default &nbsp;
                {renderToolTip('Check this box will set this rich menu to become a default rich menu.')}
              </span>
            )}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 4 }}
          >
            {
              form.getFieldDecorator('default', {
                initialValue: formData.default ? 1 : 0,
                rules: [{ required: true, message: 'Please select default' }],
              })(
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>,
              )
            }
          </Form.Item>


          <Form.Item
            label={(
              <span>
                Default Behavior &nbsp;
                {renderToolTip('Choose whether or not to have your rich menu shown by default when the user opens the chat screen.')}
              </span>
            )}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 4 }}
          >
            {
              form.getFieldDecorator('content.selected', {
                initialValue: formData.content.selected,
                rules: [{ required: true, message: 'Please select default behavior' }],
              })(
                <Radio.Group>
                  <Radio value="true">Shown</Radio>
                  <Radio value="false">Collapsed</Radio>
                </Radio.Group>,
              )
            }
          </Form.Item>

          <Form.Item
            label={(
              <span>
                Tier Level &nbsp;
                {renderToolTip('Choose tier level for display the rich menu to the member in each tier level. (optional)')}
              </span>
            )}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 4 }}
          >
            {
              form.getFieldDecorator('tierId', {
                ...(isEditing && { initialValue: formData.tierId }),
              })(
                <Select
                  placeholder="Select tier level (optional)"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) !== -1}
                  showSearch
                  allowClear
                >
                  {tiers.map((tier) => (
                    <Select.Option key={tier.id.toString()} value={tier.id}>{tier.title.en}</Select.Option>
                  ))}
                </Select>,
              )
            }
          </Form.Item>

          <Divider />

          <Typography.Title level={3}>Content</Typography.Title>
          <Row gutter={30}>
            <Col xs={12} sm={12} md={12} lg={8} xl={4}>
              <TemplateSelector
                form={form}
                template={template}
                formData={formData}
                onClickActionSlot={(index) => actionFormRef.current.setActivePanel([ index ])}
                onClickSelectTemplate={() => richMenuTemplateRef.current.open()}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={16} xl={12}>
              <ActionForm
                ref={actionFormRef}
                form={form}
                template={template}
                formData={formData}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
}

CreateRichMenu.propTypes = {
  form: PropTypes.shape().isRequired,
};

CreateRichMenu.defaultProps = {};

export default Form.create()(CreateRichMenu);
