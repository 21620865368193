import { useState } from 'react';

import _ from 'lodash';


const INIT_PAGE = 1;
const DEFAULT_LIMI = 10;

/**
 * User paginate
 *
 * @param {Object} props
 * @param {number} props.limit - default limit data per page
 * @param {number} props.current - default current page
 */
function usePaginate ({ limit: defaultLimit = DEFAULT_LIMI, current: defaultCurrent = INIT_PAGE, total: defaultTotal } = {}) {
  const [ limit, setLimit ] = useState(defaultLimit);
  const [ current, setCurrent ] = useState(defaultCurrent);
  const [ total, setTotal ] = useState(defaultTotal);
  const [ visited, setVisited ] = useState([ current ]);

  return {
    setLimit,
    setTotal,
    isVisited (target) {
      return visited.indexOf(target) !== -1;
    },
    setCurrent (target) {
      setCurrent(target);
      setVisited((state) => (_.uniq([ ...state, target ])));
    },
    pagination: {
      query: { $page: current, $limit: limit },
      plain: { current, total, pageSize: limit },
    },
  };
}

export default usePaginate;
