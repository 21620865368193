import { useState, useEffect } from 'react';
import _ from 'lodash';
/**
 * User paginate
 *
 * @param {Object} props
 * @param {number} props.limit - default limit data per page
 * @param {number} props.current - default current page
 */
function usePreloadServices (preloadServices) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ responses, setResponses ] = useState({});

  const fetchPreloadServices = async () => {
    setIsLoaded(false);
    const preloadServiceResponses = await Promise.all(_.map(preloadServices, 'service'));
    const mappedServices = _.reduce(preloadServiceResponses, (acc, serviceResponse, index) => {
      const targetPreloadServiceName = preloadServices[index].name || (index + 1);
      return {
        ...acc,
        [targetPreloadServiceName]: serviceResponse.data,
      };
    }, {});
    setIsLoaded(true);
    setResponses(mappedServices);
  };

  useEffect(() => {
    fetchPreloadServices();
  }, []);

  const refresh = async () => {
    await fetchPreloadServices();
  };

  return {
    isLoaded,
    responses,
    refresh,
  };
}

export default usePreloadServices;
