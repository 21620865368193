import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash.isempty';
import { Button } from 'antd';

import { List, CardMessage } from '~/components';
import { useDidUpdateEffect } from '~/hooks';

import { initialState, reducer } from './reducer';

/**
 * Message form component
 *
 * @param {object} props
 * @param {object[]} props.values
 * @param {function} props.onChange - on message is changed event
 * @return {JSX.Element}
 */
function MessageForm ({ values, onChange }) {
  const [ state, dispatch ] = useReducer(reducer, initialState);

  const oneMessageLeft = state.length === 1;

  /**
   * Handle add message
   *
   * @description add message event handler for add text message type
   * @return {void}
   */
  const handleAddMessage = () => {
    dispatch({ type: 'ADD_MESSAGE' });
  };

  /**
   * Handle remove message
   *
   * @description remove message event handler
   * @param {string | number} index - message index
   * @return {void}
   */
  const handleRemoveMessage = (index) => {
    const payload = { index };

    dispatch({ type: 'REMOVE_MESSAGE', payload });
  };

  /**
   * Handle change message
   *
   * @description change message event handler
   * @return {void}
   */
  const handleChangeMessage = (index, data) => {
    const payload = { index, data };

    dispatch({ type: 'CHANGE_MESSAGE', payload });
  };

  /**
   * Handle change message type
   *
   * @description change message type event handler
   * @param {number} index
   * @param {'text' | 'image' | 'video'} type - message type
   * @return {void}
   */
  const handleChangeMessageType = (index, type) => {
    const payload = { index, type };

    return dispatch({ type: 'CHANGE_MESSAGE_TYPE', payload });
  };

  /**
   * On move message event handler
   *
   * @param {number} index
   * @param {'up' | 'down'} direction
   * @return {void}
   */
  const handleOnMoveMessage = (index, direction) => {
    const payload = { index, direction };

    return dispatch({ type: 'MOVE_MESSAGE', payload });
  };

  useEffect(() => {
    if (!isEmpty(values)) dispatch({ type: 'SET', payload: values });
  }, [ values ]);

  // update the changed message
  useDidUpdateEffect(() => {
    onChange(state);
  }, [ state ]);

  return (
    <div>
      <List
        items={state}
        keyExtractor={(item, index) => index.toString()}
        renderSeparator={() => <div style={{ height: 16 }} />}
        render={({ type, text, originalContentUrl, ...rest }, index) => {
          const firstMessage = index === 0;
          const lastMessage = index === state.length - 1;
          const messageIsNotEmpty = Boolean(text ?? originalContentUrl);
          let value = '';
          switch (type) {
            default:
            case 'text':
              value = text;
              break;
            case 'image':
            case 'video':
              value = originalContentUrl;
              break;
            case 'imagemap':
              value = rest;
              break;
          }
          return (
            <CardMessage
              type={type}
              value={value}
              onRemove={() => handleRemoveMessage(index)}
              onMoveUp={() => handleOnMoveMessage(index, 'up')}
              onMoveDown={() => handleOnMoveMessage(index, 'down')}
              onChange={(data) => handleChangeMessage(index, data)}
              onChangeType={(targetType) => handleChangeMessageType(index, targetType)}
              disabled={{
                text: type !== 'text' && messageIsNotEmpty,
                image: type !== 'image' && messageIsNotEmpty,
                video: type !== 'video' && messageIsNotEmpty,
                imageMap: type !== 'imagemap' && messageIsNotEmpty,
                up: oneMessageLeft || firstMessage,
                down: oneMessageLeft || lastMessage,
                remove: oneMessageLeft,
              }}
            />
          );
        }}
      />

      {state.length < 5 && (
        <div style={{ margin: '16px 0' }}>
          <Button type="default" size="large" icon="plus" onClick={handleAddMessage}>Add</Button>
        </div>
      )}
    </div>
  );
}

MessageForm.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
};

MessageForm.defaultProps = {
  values: undefined,
  onChange () {},
};

export default MessageForm;
