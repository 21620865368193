import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Button, Typography, Upload, Input, Form } from 'antd';

import { List } from '~/components';
import { useDidUpdateEffect } from '~/hooks';
import config from '~/config';
import { deleteMediasApi as removeMedia, getMediasByIdApi } from '~/services/medias';

import './style.less';


/**
 * Template selector component
 *
 * @param {Object} props
 * @param {{}} props.form
 * @param {{}} props.template
 * @param {{}} props.formData - initial value for editing
 * @param {(title: string) => void} props.onClickActionSlot
 * @param {(event: Event) => void} props.onClickSelectTemplate
 * @return {JSX.Element}
 */
function TemplateSelector ({ form, template, formData, onClickActionSlot, onClickSelectTemplate }) {
  const { size, areas } = template;
  const alphabet = [ 'a', 'b', 'c', 'd', 'e', 'f' ];

  const [ image, setImage ] = useState({});

  const { id: slug } = useParams();
  const isEditing = !_.isEmpty(slug);

  const handleOnChangeFile = async (info) => {
    const { file } = info;

    if (file.status === 'done') {
      if (!_.isEmpty(image) && !image.old) await removeMedia(image.id);

      const media = file.response.shift();

      setImage(media);
      form.setFieldsValue({ imageId: media.id });
    }
  };

  useDidUpdateEffect(() => {
    const init = async () => {
      const media = await getMediasByIdApi(formData.imageId);
      setImage({ ...media, old: true });
    };

    if (isEditing) init();
  }, [ formData ]);

  return (
    <div className="template-selector">
      <Form.Item>
        {/* hidden input */}
        {form.getFieldDecorator('imageId', {
          ...(isEditing && { initialValue: formData.imageId }),
          rules: [{ required: true, message: 'Please upload a rich menu image' }],
        })(<Input type="hidden" />)}

        <div className="sample-template">
          <svg className="sample-template-svg" viewBox={`0 0 ${size ? size.width : 2500} ${size ? size.height : 1686}`} xmlns="http://www.w3.org/2000/svg" version="1.1">
            {image.src && (<image href={image.src} x="0" y="0" />)}

            <g id="outline">
              <List
                items={areas}
                keyExtractor={(item, index) => index.toString()}
                render={({ bounds }, index) => (
                  <g className="action-slot" onClick={() => onClickActionSlot(index)}>
                    <rect width={bounds.width} height={bounds.height} x={bounds.x} y={bounds.y} />
                    <text x={bounds.x + (bounds.width / 2)} y={bounds.y + (bounds.height / 1.75)} dominantBaseline="middle" textAnchor="middle">{alphabet[index]}</text>
                  </g>
                )}
              />
            </g>
          </svg>
          {!image.src && (
            <div className="sample-template-description">
              <Typography.Text disabled>Select a template and upload a background image.</Typography.Text>
            </div>
          )}
        </div>
      </Form.Item>

      <div className="button-group">
        <Button type="primary" onClick={onClickSelectTemplate} block>Select Template</Button>
        <Upload
          name="file"
          accept="image/*"
          headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
          action={`${config.SERVICE}/${config.API_VERSION}/medias`}
          className="is-fullwidth"
          onChange={handleOnChangeFile}
          showUploadList={false}
        >
          <Button type="primary" disabled={_.isEmpty(template)} block>Upload Image</Button>
        </Upload>
        <br />
        <br />
        <p style={{ textAlign: 'center', fontWeight: 700, margin: 0 }}>2500w x 1686h</p>
        <p style={{ textAlign: 'center', fontWeight: 700, margin: 0 }}>2500w x 843h</p>
        <p style={{ textAlign: 'center', fontWeight: 700, margin: 0 }}>up to 1 MB</p>
      </div>
    </div>
  );
}

TemplateSelector.propTypes = {
  form: PropTypes.shape(),
  template: PropTypes.shape(),
  formData: PropTypes.shape(),
  onClickActionSlot: PropTypes.func,
  onClickSelectTemplate: PropTypes.func,
};

TemplateSelector.defaultProps = {
  form: {},
  template: {},
  formData: {},
  onClickActionSlot () {},
  onClickSelectTemplate () {},
};

export default TemplateSelector;
