import React, { Fragment, useState, useReducer, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Card,
  Popconfirm,
  Upload,
  Popover,
  Modal,
  Input,
  Icon,
} from 'antd';
import _ from 'lodash';

import * as ResponseMessage from './lib/response-message-parser';
import { InitResponseMessageGroups, ResponseMessageReducer } from './lib/local-reducer';
import * as LocalAction from './lib/local-action-types';

/**
 * API service url
 *
 * @type {string}
 * @constant
 * @private
 */
const _servicePrefix = process.env.REACT_APP_SERVICE;

/**
 * API version
 *
 * @type {string}
 * @constant
 * @private
 */
const _version = process.env.REACT_APP_API;

const ResponseMessageGroup = ({ multi }, ref) => {
  const [ responseMessageGroups, dispatch ] = useReducer(ResponseMessageReducer, InitResponseMessageGroups);
  const [ previewVisible, setPreviewVisible ] = useState();
  const [ uploading, setUploading ] = useState(false);

  useImperativeHandle(ref, () => ({
    getParseArray: () => ResponseMessage.parseArray(responseMessageGroups),
    getResponseMessageGroups: () => responseMessageGroups,
    getResponseMessageGroup: () => responseMessageGroups[Object.keys(responseMessageGroups)[0]],
  }));

  // support 'image' and 'video' message type
  const renderPreviewMediaMessage = (type, key, previewImageUrl) => {
    let el;

    switch (type) {
      case 'image':
        el = <img style={{ width: '100%' }} src={previewImageUrl} alt={previewImageUrl} />;
        break;

      case 'video':
        el = <video style={{ width: '100%' }} src={previewImageUrl} alt={previewImageUrl} controls />;
        break;

      default:
        console.debug('preview media should identify type');
        break;
    }

    return (
      <Modal footer={null} visible={previewVisible === key} onCancel={() => setPreviewVisible(undefined)}>
        {el}
      </Modal>
    );
  };

  // use with 'image' and 'video' message
  const handleOnMediaMessageChange = (info, key, groupKey) => {
    const { file } = info;

    if (file.status === 'uploading') setUploading(true);
    if (info.file.status === 'done') {
      const link = file.response[0].src;

      setUploading(false);
      dispatch({
        type: LocalAction.ON_CHANGE,
        payload: { originalContentUrl: link, previewImageUrl: link },
        meta: { key, groupKey },
      });
    }
  };

  // support 'image' and 'video' message type
  const renderMediaMessage = (message, key, groupKey) => {
    const { type, data } = message;
    const { previewImageUrl } = data;

    const defaultFileList = [];
    if (previewImageUrl) defaultFileList.push({ uid: '1', name: 'image.png', status: 'done', url: previewImageUrl });

    const renderSupportTypes = (formats, size, width) => (
      <Fragment>
        <div>
          <small>
            {`File formats: `}
            {formats}
          </small>
        </div>
        {width && (
          <div>
            <small>
              {`width: `}
              {formats}
            </small>
          </div>
        )}
        <div>
          <small>
            {`Max file size: `}
            {size}
          </small>
        </div>
      </Fragment>
    );

    return (
      <Fragment>
        <Upload
          name="file"
          key={`file-${key}`}
          accept={`${type}/*`}
          listType="picture-card"
          defaultFileList={defaultFileList}
          headers={{ Authorization: `Bearer ${window.localStorage.getItem('token')}` }}
          action={`${_servicePrefix}/${_version}/medias`}
          onPreview={() => setPreviewVisible(key)}
          onChange={(info) => handleOnMediaMessageChange(info, key, groupKey)}
          onRemove={() => dispatch({
            type: LocalAction.ON_CHANGE,
            payload: { originalContentUrl: '', previewImageUrl: '' },
            meta: { key, groupKey },
          })}
        >
          {_.isEmpty(previewImageUrl) && (
            <Fragment>
              <Icon type={uploading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">{uploading ? 'Uploading...' : 'Upload'}</div>
            </Fragment>
          )}
        </Upload>
        {!_.isEmpty(previewImageUrl) && renderPreviewMediaMessage(type, key, previewImageUrl)}
        {type === 'image' && renderSupportTypes('JPG, JPEG', '1 MB', '472px')}
        {type === 'video' && renderSupportTypes('MP4', '10 MB')}
      </Fragment>
    );
  };

  // support 'text' message type
  const renderTextMessage = (message, key, groupKey) => (
    <Input.TextArea
      rows={4}
      value={message.data.text}
      placeholder="Enter text"
      style={{ resize: 'none' }}
      onChange={(e) => dispatch({
        type: LocalAction.ON_CHANGE,
        payload: { text: e.target.value },
        meta: { key, groupKey },
      })}
    />
  );

  const renderResponseMessage = (message, key, groupKey) => {
    const { type } = message;
    const keys = Object.keys(responseMessageGroups[groupKey]);

    const oneMessageLeft = keys.length === 1;
    const firstOfList = keys.indexOf(key) === 0;
    const lastOfList = keys.indexOf(key) === keys.length - 1;

    return (
      <Form.Item key={key}>
        <Card
          type="inner"
          extra={(
            <Button.Group>
              <Popover content="Move Up">
                <Button
                  icon="up"
                  type="link"
                  disabled={oneMessageLeft || firstOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'up' } })}
                />
              </Popover>
              <Popover content="Move Down">
                <Button
                  icon="down"
                  type="link"
                  disabled={oneMessageLeft || lastOfList}
                  onClick={() => dispatch({ type: LocalAction.SWAP, meta: { key, groupKey, direction: 'down' } })}
                />
              </Popover>
              <Popconfirm
                placement="top"
                title="Delete this message?"
                onConfirm={() => dispatch({ type: LocalAction.REMOVE_MESSAGE, meta: { key, groupKey } })}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneMessageLeft}
              >
                <Button disabled={oneMessageLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
          title={(
            <Button.Group>
              <Popover content="Text">
                <Button
                  disabled={type !== 'text' && !ResponseMessage.isEmpty(message)}
                  type={type === 'text' ? 'primary' : 'link'}
                  icon="message"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'text', data: { text: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Image">
                <Button
                  disabled={type !== 'image' && !ResponseMessage.isEmpty(message)}
                  type={type === 'image' ? 'primary' : 'link'}
                  icon="picture"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'image', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
              <Popover content="Video">
                <Button
                  disabled={type !== 'video' && !ResponseMessage.isEmpty(message)}
                  type={type === 'video' ? 'primary' : 'link'}
                  icon="video-camera"
                  onClick={() => dispatch({ type: LocalAction.CHANGE_MESSAGE_TYPE,
                    payload: { type: 'video', data: { originalContentUrl: '', previewImageUrl: '' } },
                    meta: { key, groupKey } })}
                />
              </Popover>
            </Button.Group>
          )}
        >
          {type === 'text' && renderTextMessage(message, key, groupKey)}
          {(type === 'image' || type === 'video') && renderMediaMessage(message, key, groupKey)}
        </Card>
      </Form.Item>
    );
  };

  const renderResponseMessageGroup = ([ groupKey, group ]) => {
    const entriesGroup = Object.entries(group);
    const oneGroupLeft = Object.keys(responseMessageGroups).length === 1;

    return (
      <Form.Item key={groupKey}>
        <Card
          title="Response Message Group"
          extra={(
            <Button.Group>
              <Popconfirm
                placement="top"
                title="Delete this response message group?"
                onConfirm={() => dispatch({ type: LocalAction.REMOVE_GROUP, meta: { groupKey } })}
                okType="danger"
                okText="Delete"
                cancelText="No"
                disabled={oneGroupLeft}
              >
                <Button disabled={oneGroupLeft} icon="close" type="link" />
              </Popconfirm>
            </Button.Group>
          )}
        >
          {entriesGroup.map(([ key, message ]) => renderResponseMessage(message, key, groupKey))}
          {entriesGroup.length <= 5 && (
            <Button
              type="dashed"
              size="large"
              block
              onClick={() => dispatch({ type: LocalAction.ADD_MESSAGE, meta: { groupKey } })}
            >
              Add Message
            </Button>
          )}
        </Card>
      </Form.Item>
    );
  };

  return (
    <div>
      {Object.entries(responseMessageGroups).map(renderResponseMessageGroup)}
      {multi && Object.keys(responseMessageGroups).length < 5 && (
        <Form.Item>
          <Button
            type="primary"
            icon="plus"
            onClick={() => dispatch({ type: LocalAction.ADD_GROUP })}
          >
            Add Response Group
          </Button>
        </Form.Item>
      )}
    </div>
  );
};

ResponseMessageGroup.propTypes = {
  multi: PropTypes.bool,
};

ResponseMessageGroup.defaultProps = {
  multi: false,
};

export default forwardRef(ResponseMessageGroup);
