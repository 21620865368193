import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import omit from 'lodash.omit';
import isEmpty from 'lodash.isempty';
import { useParams, useHistory } from 'react-router-dom';
import { Spin, Button, Divider, Form, Radio, Row, Col, Input, notification } from 'antd';

import dayjs from '~/utils/dayjs';
import { QuestionService } from '~/services/questions';
import { PageTitle } from '~/components';
import { BroadcastService } from '~/services/broadcasts';

import baseCriterias from './resources/base-criterias.json';
import { CriteriaModal, CriteriaList, TargetEstimate, MessageForm, BroadcastTimePicker, PreviewMessage } from './components';

// Constant
const DATE_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'HH:mm';

/**
 * Save broadcast page
 *
 * @param {object} props
 * @param {object} props.form
 * @return {JSX.Element}
 */
function SaveBroadcast ({ form }) {
  const [ loading, setLoading ] = useState(true);
  const [ criterias, setCriterias ] = useState({});
  const [ broadcastTime, setBroadcastTime ] = useState(undefined);
  const [ criteriaModalVisible, setCriteriaModalVisible ] = useState(false);
  const [ recipients, setRecipients ] = useState(0);

  const params = useParams();
  const history = useHistory();
  const editing = Boolean(params.id);

  /**
   * Handle submit form
   *
   * @param {Event} e - dom event
   * @param {'pending' | 'draft'} status - broadcast status
   */
  const handleSubmit = (e, status = 'pending') => {
    e.preventDefault();

    form.validateFields(async (_, value) => {
      const body = {
        ...value,
        broadcastTime: value.broadcastTimeStrategy === 'now' ? parseInt(dayjs().format('x'), 10) : parseInt(value.broadcastTime, 10),
      };

      try {
        if (editing) {
          await BroadcastService.update(body.id, { ...body });

          if (status === 'pending') await BroadcastService.publish(body.id);
        } else {
          await BroadcastService.create({ ...body, status });
        }

        if (status === 'pending' && value.broadcastTimeStrategy === 'now') {
          notification.success({
            message: 'Success',
            description: 'Broadcast was sent successfully',
          });
        }

        if (status === 'pending' && value.broadcastTimeStrategy === 'date-time') {
          notification.success({
            message: 'Success',
            description: `Broadcast will be sent at ${dayjs(parseInt(value.broadcastTime, 10)).format(`${DATE_FORMAT} ${TIME_FORMAT}`)}`,
          });
        }

        if (status === 'draft') {
          notification.success({
            message: 'Success',
            description: 'Save successfully draft broadcast',
          });
        }

        history.push('/broadcasts');
      } catch (error) {
        notification.error({
          message: 'Oops',
          description: 'Failed to send message. Please try again',
        });
      }
    });
  };

  /**
   * On targeting changed event handler
   *
   * @return {void}
   */
  const handleOnTargetingChanged = async () => {
    try {
      const value = form.getFieldsValue();

      const body = {
        ...value,
        broadcastTime: value.broadcastTimeStrategy === 'now' ? parseInt(dayjs().format('x'), 10) : parseInt(value.broadcastTime, 10),
      };

      const { data } = await BroadcastService.create(body, { '$dry-run': true });
      const { receiver } = data;

      setRecipients(receiver);
    } catch (error) {
      // api is need the length of receiver more than 0, so if error occur that mean the receiver is 0.
      setRecipients(0);
    }
  };

  /**
   * On broadcast time changed event handler
   *
   * @param {strign} date - date string
   * @return {void}
   */
  const handleOnBroadcastTimeChanged = (date) => {
    setBroadcastTime(() => {
      form.setFieldsValue({
        broadcastTimeStrategy: 'date-time',
        broadcastTime: dayjs(date, `${DATE_FORMAT} ${TIME_FORMAT}`).format('x'),
      });

      return date;
    });
  };

  /**
   * On broadcast time strategy changed event handler
   *
   * @param {Event} e - javascript event
   * @return {void}
   */
  const handleOnBroadcastTimeStrategyChanged = (e) => {
    form.setFieldsValue({ broadcastTimeStrategy: e.target.value });

    if (e.target.value === 'now') {
      form.setFieldsValue({ broadcastTime: dayjs().format('x') });
    }

    if (e.target.value === 'date-time') {
      const targetBroadcastTime = broadcastTime ?? dayjs().format(`${DATE_FORMAT} ${TIME_FORMAT}`);

      setBroadcastTime(targetBroadcastTime);
      form.setFieldsValue({ broadcastTime: dayjs(targetBroadcastTime, `${DATE_FORMAT} ${TIME_FORMAT}`).format('x') });
    }
  };

  /**
   * Handle submit criteria
   *
   * @description handle on submit event from criteria modal
   * @param {{ [string]: string[] }} criteria - broadcast criteria
   */
  const handleSubmitCriteria = (criteria) => {
    setCriteriaModalVisible(() => {
      if (criteria && Object.keys(criteria) && Object.keys(criteria)[0] && Number(Object.keys(criteria)[0]) > 0) {
        const questionId = Object.keys(criteria)[0];
        const question = criterias.properties[questionId];
        const answerIds = criteria[questionId];
        const answers = answerIds.map((answerId) => {
          const answer = question.values.find((choice) => choice.value === answerId);
          return answer ? answer.value : null;
        }).filter(Boolean);
        const reFormatCriteria = {
          [questionId]: answers,
        };

        const properties = {
          ...form.getFieldValue('criterias').properties || {},
          ...reFormatCriteria,
        };
        form.setFieldsValue({
          criterias: { ...form.getFieldValue('criterias'), properties },
        });
      } else {
        form.setFieldsValue({
          criterias: { ...form.getFieldValue('criterias'), ...criteria },
        });
      }

      return false;
    });

    handleOnTargetingChanged();
  };

  /**
   * Handle on remove event of selected criteria card
   *
   * @param {string | number} id - criteria id
   * @return {void}
   */
  const handleRemoveCriteria = (id) => {
    if (id !== 'age' && id !== 'gender') {
      const properties = omit(form.getFieldValue('criterias')?.properties, id);

      form.setFieldsValue({ criterias: { ...form.getFieldValue('criterias'), properties } });
    } else {
      form.setFieldsValue({ criterias: omit(form.getFieldValue('criterias'), id) });
    }

    handleOnTargetingChanged();
  };

  /**
   * Fetch qa for criterias
   *
   * @description fetch question and its choices for criteria modal
   * @return {void}
   */
  const fetchQAForCriterias = async () => {
    const { data } = await QuestionService.find({ $populate: [ 'choices' ], $select: [ 'id', 'title' ], '$sort[id]': 1 });

    const tranformedQA = data.reduce((result, current) => ({
      ...result,
      [current.id]: {
        title: current.title?.en || current.title?.th,
        values: current.choices.map((choice) => ({ label: choice.title?.en || choice.title?.th, value: choice.id })),
      },
    }), {});

    setCriterias((prev) => ({ ...prev, properties: tranformedQA }));
  };

  const handleOnMessageChanged = (messages) => {
    form.setFieldsValue({ messages });
  };

  const fetchBroadcastForEditing = async () => {
    const { data } = await BroadcastService.findById(params.id);

    form.setFieldsValue({
      id: data.id,
      targeting: data.targeting,
      messages: data.messages,
      criterias: data.criterias,
      broadcastTimeStrategy: 'date-time',
      broadcastTime: dayjs(data.broadcastTime, 'YYYY-MM-DDTHH:mm:ss.zz').format('x'),
    });

    // if broadcast time is before to day, then change time strategy to send now
    if (dayjs(data.broadcastTime, 'YYYY-MM-DDTHH:mm:ss.zz').isSameOrBefore(dayjs())) {
      form.setFieldsValue({ broadcastTimeStrategy: 'now', broadcastTime: dayjs().format('x') });

      setBroadcastTime(dayjs().format(`${DATE_FORMAT} ${TIME_FORMAT}`));
    } else {
      const broadcastTimeStr = dayjs(data.broadcastTime, 'YYYY-MM-DDTHH:mm:ss.zz').format(`${DATE_FORMAT} ${TIME_FORMAT}`);

      form.setFieldsValue({ broadcastTimeStrategy: 'date-time', broadcastTime: broadcastTimeStr });
      setBroadcastTime(broadcastTimeStr);
    }
    setRecipients(data.receiver);
  };

  const init = async () => {
    // set form initial value
    form.setFieldsValue({
      targeting: 'all-friend',
      broadcastTimeStrategy: 'now',
      broadcastTime: dayjs().format('x'),
      criterias: {},
      messages: [{ type: 'text', text: '' }],
    });

    // set base criterias
    setCriterias(baseCriterias);

    // set question criterias
    await fetchQAForCriterias();

    if (editing) await fetchBroadcastForEditing();

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="save-broadcast is-fullheight">
      <Spin spinning={loading} tip="loading...">
        <div className="page-title-container">
          <PageTitle
            title={editing ? 'Edit broadcast' : 'New broadcast'}
            extra={(
              <div className="button-group">
                <Button type="dashed" onClick={(e) => handleSubmit(e, 'draft')}>Save draft</Button>
                <Button type="primary" key="send" htmlType="submit" form="form">Send</Button>
              </div>
            )}
          />
        </div>

        <Divider className="is-marginless" />

        <div className="page-content-container">
          <Form
            id="form"
            layout="horizontal"
            colon={false}
            onSubmit={handleSubmit}
            labelCol={{ span: 5 }}
            wrapperCol={{ offset: 1, span: 18 }}
            hideRequiredMark
          >

            {/* Hidden form field */}
            { form.getFieldDecorator('id')(<Input type="hidden" />) }
            { form.getFieldDecorator('criterias')(<Input type="hidden" />) }
            { form.getFieldDecorator('messages')(<Input type="hidden" />) }
            { form.getFieldDecorator('broadcastTime')(<Input type="hidden" />) }

            <Row>
              <Col span={12}>
                <Form.Item label="Recipients">
                  {
                    form.getFieldDecorator('targeting', {
                      rules: [{ required: true, message: 'Please select the recipient strategy.' }],
                    })(
                      <Radio.Group>
                        <Radio className="is-vertical-radio" value="all-friend">
                          All friends
                        </Radio>

                        <Radio className="is-vertical-radio" value="criteria">
                          Targeting (only members)
                        </Radio>
                      </Radio.Group>,
                    )
                  }
                </Form.Item>

                {
                  form.getFieldValue('targeting') === 'criteria' && (
                    <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                      {
                        !isEmpty(form.getFieldValue('criterias')) && (
                          <CriteriaList
                            criterias={criterias}
                            values={form.getFieldValue('criterias')}
                            onRemove={handleRemoveCriteria}
                          />
                        )
                      }

                      <Button type="default" size="large" icon="plus" onClick={() => setCriteriaModalVisible(true)}>Add criteria</Button>
                    </Form.Item>
                  )
                }

                <Form.Item label="Broadcast time">
                  {
                    form.getFieldDecorator('broadcastTimeStrategy')(
                      <Radio.Group onChange={handleOnBroadcastTimeStrategyChanged}>
                        <Radio className="is-vertical-radio" value="now">
                          Send now
                        </Radio>

                        <Radio className="is-vertical-radio" value="date-time">
                          <BroadcastTimePicker
                            format={{ date: DATE_FORMAT, time: TIME_FORMAT }}
                            placeholder={{ date: dayjs().format(DATE_FORMAT), time: dayjs().format(TIME_FORMAT) }}
                            disabled={{ date: (current) => current < dayjs().startOf('day') }}
                            onChange={handleOnBroadcastTimeChanged}
                            value={broadcastTime}
                          />
                        </Radio>
                      </Radio.Group>,
                    )
                  }
                </Form.Item>
              </Col>

              <Col offset={1} span={4}>
                <TargetEstimate recipients={recipients} />
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={12}>
                <MessageForm
                  values={form.getFieldValue('messages')}
                  onChange={handleOnMessageChanged}
                />
              </Col>
            </Row>
          </Form>

          <Row style={{ marginTop: 10 }}>
            <Col span={4} offset={10}>
              <Button
                type="primary"
                key="send"
                htmlType="submit"
                form="form"
                size="large"
                block
              >
                Send
              </Button>
            </Col>
          </Row>
        </div>
      </Spin>

      <PreviewMessage
        messages={(form.getFieldValue('messages') ?? []).filter((message) => message.text ?? message.originalContentUrl)}
      />

      {/* Modal */}
      <CriteriaModal
        criterias={criterias}
        visible={criteriaModalVisible}
        onSubmit={handleSubmitCriteria}
        onCancel={() => setCriteriaModalVisible(false)}
      />
    </div>
  );
}

SaveBroadcast.defaultProps = {};
SaveBroadcast.propTypes = {
  form: PropTypes.shape().isRequired,
};

export default Form.create()(SaveBroadcast);
