import React from 'react';
import PropTypes from 'prop-types';

import { Card, Tooltip, Button } from 'antd';

import { List } from '~/components';

import { TextMessage, MediaMessage, LinkMessage } from './components';

// ref : https://developers.line.biz/en/reference/messaging-api/#imagemap-message

/**
 * Card message component
 *
 * @param {object} props
 * @param {'text' | 'image' | 'video'} props.type - the current message type
 * @param {{ up: boolean, down: boolean, remove: boolean }} props.disabled
 * @param {string} props.value - message value
 * @param {function} props.onRemove - on message is removed event
 * @param {function} props.onChange - on message is changed event
 * @param {function} props.onChangeType - on type of message is changed event
 * @param {function} props.onMoveUp - on message is move up event
 * @param {function} props.onMoveDown - on message is move down event
 * @return {JSX.Element}
 */
function CardMessage ({ type, disabled, value, onRemove, onChange, onChangeType, onMoveUp, onMoveDown }) {
  /**
   * Render message type button component
   *
   * @description render the left menu of the card header
   * @param {{ type: string, tooltip: string, icon: string, active: boolean }} button
   * @return {JSX.Element}
   */
  const renderMessageTypeButton = (button) => (
    <Tooltip title={button.tooltip}>
      <Button
        size="large"
        icon={button.icon}
        disabled={button.disabled}
        type={button.active ? 'primary' : 'link'}
        onClick={() => onChangeType(button.type)}
      />
    </Tooltip>
  );

  /**
   * Render control button
   *
   * @description render the right menu of the card header
   * @param {{ key: string, icon: string, disabled: boolean, onClick: function }} button
   */
  const renderControlButton = (button) => (
    <Button
      type="link"
      icon={button.icon}
      disabled={button.disabled}
      onClick={button.onClick}
    />
  );

  /**
   * On media message changed handler
   *
   * @param {object} info
   * @return {void}
   */
  const handleOnMediaMessageChanged = ({ file }) => {
    switch (file.status) {
      case 'done': {
        const link = file.response[0].src;
        return onChange({ originalContentUrl: link, previewImageUrl: link });
      }

      default:
        // do not thing
        break;
    }
  };

  /**
   * on media message removed handler
   *
   * @return {void}
   */
  const handleOnMediaMessageRemoved = () => {
    onChange({ originalContentUrl: '', previewImageUrl: '' });
  };

  /**
   * on media message removed handler
   *
   * @return {void}
   */
  const handleOnImageMapLinkChanged = (linkUri) => {
    onChange({
      ...value,
      altText: linkUri,
      actions: [
        {
          type: 'uri',
          linkUri,
          area: value.actions[0].area,
        },
      ],
    });
  };

  /**
   * on media message removed handler
   *
   * @return {void}
   */
  const handleOnMediaMapMessageChanged = ({ file }) => {
    switch (file.status) {
      case 'done': {
        const link = file.response[0].src;
        if (link) {
          const img = new Image();
          img.onload = () => {
            const { width, height } = img;
            onChange({
              ...value,
              baseUrl: `${link}?_ignored=`,
              baseSize: {
                width,
                height,
              },
              actions: [
                {
                  type: 'uri',
                  linkUri: value.actions[0].linkUri,
                  area: {
                    x: 0,
                    y: 0,
                    width,
                    height,
                  },
                },
              ],
            });
          };
          img.src = link;
        }
        return '';
      }
      default:
        // do not thing
        break;
    }
  };

  /**
   * on media message removed handler
   *
   * @return {void}
   */
  const handleOnMediaMapMessageRemoved = () => {
    onChange({
      ...value,
      baseUrl: '',
      altText: '',
      baseSize: {
        width: 0,
        height: 0,
      },
      video: {
        originalContentUrl: '',
        previewImageUrl: '',
        area: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        },
        externalLink: {
          linkUri: '',
          label: '',
        },
      },
      actions: [],
    });
  };

  /**
   * Render compose message
   *
   * @description render message input depend on message type
   * @return {JSX.Element}
   */
  const renderComposeMessage = () => {
    switch (type) {
      default:
      case 'text':
        return (
          <TextMessage
            value={value}
            onChange={(e) => onChange({ text: e.target.value })}
          />
        );

      case 'image':
        return (
          <MediaMessage
            type="image"
            icon="picture"
            title="Upload photo"
            description={(
              <div>
                <p>File formats: JPG, JPEG, PNG</p>
                <p>Max image size: No limits</p>
                <p>Max file size: 10 MB</p>
              </div>
            )}
            value={value}
            onChange={handleOnMediaMessageChanged}
            onRemove={handleOnMediaMessageRemoved}
          />
        );

      case 'imagemap':
        return (
          <React.Fragment>
            <MediaMessage
              type="image"
              icon="picture"
              title="Upload photo"
              description={(
                <div>
                  <p>File formats: JPG, JPEG, PNG</p>
                  <p>Image width: 240px, 300px, 460px, 700px, 1040px</p>
                  <p>Max file size: 10 MB</p>
                </div>
              )}
              value={value.baseUrl}
              onChange={handleOnMediaMapMessageChanged}
              onRemove={handleOnMediaMapMessageRemoved}
            />
            <LinkMessage
              value=""
              onChange={handleOnImageMapLinkChanged}
            />
          </React.Fragment>
        );

      case 'video':
        return (
          <MediaMessage
            type="video"
            icon="play-square"
            title="Upload Video"
            description={
              (
                <div>
                  <p>File formats: mp4</p>
                  <p>Max file size: 200 MB</p>
                </div>
              )
            }
            value={value}
            onChange={handleOnMediaMessageChanged}
            onRemove={handleOnMediaMessageRemoved}
          />
        );
    }
  };

  return (
    <Card
      type="inner"
      className="card-message"
      title={(
        <List
          items={[
            { type: 'text', tooltip: 'Text', active: type === 'text', icon: 'message', disabled: disabled.text },
            { type: 'image', tooltip: 'Image', active: type === 'image', icon: 'picture', disabled: disabled.image },
            { type: 'imagemap', tooltip: 'Image Map', active: type === 'imagemap', icon: 'file-image', disabled: false },
            { type: 'video', tooltip: 'Video', active: type === 'video', icon: 'play-square', disabled: disabled.video },
          ]}
          container="div"
          containerClassName="button-group"
          keyExtractor={(item) => item.tooltip}
          render={renderMessageTypeButton}
        />
      )}
      extra={(
        <List
          items={[
            { key: 'move-up', icon: 'up', disabled: disabled.up, onClick: onMoveUp },
            { key: 'move-down', icon: 'down', disabled: disabled.down, onClick: onMoveDown },
            { key: 'close', icon: 'close', disabled: disabled.remove, onClick: onRemove },
          ]}
          container="div"
          containerClassName="button-group"
          keyExtractor={(button) => button.key}
          render={renderControlButton}
        />
      )}
    >
      {renderComposeMessage()}
    </Card>
  );
}

CardMessage.propTypes = {
  type: PropTypes.oneOf([ 'text', 'image', 'video', 'imagemap' ]),
  disabled: PropTypes.shape({
    text: PropTypes.bool,
    image: PropTypes.bool,
    video: PropTypes.bool,
    up: PropTypes.bool,
    down: PropTypes.bool,
    remove: PropTypes.bool,
  }),
  value: PropTypes.oneOfType([ PropTypes.string ]),
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onChangeType: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
};

CardMessage.defaultProps = {
  type: 'text',
  disabled: {
    text: false,
    image: false,
    video: false,
    imageMap: false,
    up: true,
    down: true,
    remove: true,
  },
  value: '',
  onRemove () {},
  onChange () {},
  onChangeType () {},
  onMoveUp () {},
  onMoveDown () {},
};

export default CardMessage;
