import _ from 'lodash';
import moment from 'moment';

import { ChatService } from '~/services/chats';
import { MemberService } from '~/services/members';

import * as type from '~/actions/actionType';

// Question action
function action () {
  const { find } = ChatService;
  return {
    /**
     * Find questions
     *
     * @return {(dispatch: any) => Promise<void>}
     */
    find ({ dateStart, dateEnd }) {
      return async (dispatch) => {
        dispatch({ type: type.FETCH_CHAT_HISTORY });
        try {
          const { data } = await find({ dateStart, dateEnd });
          const cleanedData = _.sortBy(data, (message) => _.toNumber(moment(message.createdDate).format('x')) * -1);
          const groupByUid = _.groupBy(cleanedData, 'customerId');
          const memberPromises = _.map(groupByUid, (messages, uid) => MemberService.find({ line: uid }));
          const memberResponses = await Promise.all(memberPromises);
          const members = _.chain(memberResponses).map((response) => _.first(response.data)).compact().value();
          const mappedDataWithMembers = _.map(cleanedData, (chatHistoryMessage) => {
            const targetMember = _.find(members, (member) => member.line === chatHistoryMessage.customerId);
            let displayName = 'Anonymous';
            const isUserMessage = chatHistoryMessage?.rawEvent?.source?.userId;
            if (!isUserMessage) {
              displayName = 'Bot';
            } else if (isUserMessage && targetMember?.firstname && targetMember?.lastname) {
              displayName = `${targetMember.firstname} ${targetMember.lastname}`;
            }
            return {
              ...targetMember,
              ...chatHistoryMessage,
              displayName,
              hasMember: targetMember !== undefined,
              member: targetMember,
            };
          });
          dispatch({ type: type.FETCH_CHAT_HISTORY_SUCCESS, payload: mappedDataWithMembers });
          return data;
        } catch (error) {
          dispatch({ type: type.FETCH_CHAT_HISTORY_ERROR, payload: error });
          throw error;
        }
      };
    },
  };
}

export default {};
export const ChatAction = action();
