import React, { Component } from 'react';
import {
  PageHeader,
  Button,
  Affix,
  Icon,
  Popconfirm,
  message,
  Input,
  Select,
  Row,
  Col,
  Modal,
  Form,
  Switch,
  Checkbox,
  Spin,
} from 'antd';
import _ from 'lodash';
import { withRedux } from '../../../hoc';
import { getLanguages } from '../../../actions/languages';
import { getSettings } from '../../../actions/settings';
import { createLanguagesApi, updateLanguagesApi, deleteLanguagesApi } from '../../../services/languages';
import { saveSettingsApi } from '../../../services/settings';
import { authHasPermission } from '../../../utils';

const FormItem = Form.Item;
const { Option } = Select;

const mapStateToProps = (state) => ({
  t: _.get(state, 't'),
  languages: _.get(state, 'languages'),
  settings: _.get(state, 'settings'),
  auth: _.get(state, 'auth.data'),
});

const actionToProps = {
  getLanguages,
  getSettings,
};

class SettingLanguage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      id: null,
      submitting: false,
      showModal: false,
      defaultLanguage: props.settings.data.defaultLanguage,
    };
  }

  _create = async (data) => {
    const createdLanguage = await createLanguagesApi(data);
    if (_.get(createdLanguage, 'status') !== 'success') {
      message.error(_.get(createdLanguage, 'message', 'Create failed.'));
      return;
    }
    message.success('Create success.');
  };

  _update = async (data) => {
    const updatedLanguage = await updateLanguagesApi(this.state.id, data);
    if (_.get(updatedLanguage, 'status') !== 'success') {
      message.error(_.get(updatedLanguage, 'message', 'Update failed.'));
      return;
    }
    message.success('Update success.');
  };

  _delete = async (id) => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    const deletedLanguage = await deleteLanguagesApi(id);
    if (_.get(deletedLanguage, 'status') !== 'success') {
      message.error(_.get(deletedLanguage, 'message', 'Delete failed.'));
    } else {
      message.success('Delete success.');
    }
    this.props.actions.getLanguages();
    this.setState({ submitting: false });
  };

  submit = async (data) => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    if (this.state.id === null) {
      await this._create(data);
    } else {
      await this._update(data);
    }
    this.props.actions.getLanguages();
    this.setState({ submitting: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const options = {
      scroll: {
        offsetTop: 96,
      },
    };
    this.props.form.validateFieldsAndScroll(options, (err, values) => {
      if (!err) {
        console.log('values', values);
        // return;
        this.submit(values);
      }
    });
  };

  renderPageHeader = () => {
    const { title, permissionPrefix, auth } = this.props;
    const extra = [];
    if (authHasPermission(_.get(auth, 'claim'), [ `${permissionPrefix}.add` ])) {
      extra.push(
        <Button key="add" type="primary" onClick={() => this.setState({ showModal: true })}>
          Add New
        </Button>,
      );
    }
    return (
      <div className="page-header">
        <PageHeader title={title} extra={extra} />
      </div>
    );
  };

  renderMainContent = () => {
    const { permissionPrefix, auth } = this.props;
    const { submitting, defaultLanguage } = this.state;
    const languages = this.props.languages.data;
    const noPermissionToEdit = !authHasPermission(_.get(auth, 'claim'), [ `${permissionPrefix}.edit` ]);
    return (
      <Spin spinning={submitting}>
        <div className="main-content">
          <h4>Default Language</h4>
          <Select
            disabled={noPermissionToEdit}
            value={defaultLanguage}
            style={{ width: 120 }}
            onChange={async (value) => {
              if (this.state.submitting) return;
              this.setState({ submitting: true });
              await saveSettingsApi({ name: 'defaultLanguage', value });
              this.props.actions.getSettings();
              this.setState({ submitting: false, defaultLanguage: value });
            }}
          >
            {_.map(languages, (language) => {
              const { code, icon, name, enabled } = language;
              if (!enabled) return null;
              return (
                <Option key={code} value={code}>
                  <span className={`flag-icon flag-icon-${icon}`} />
                  {' '}
                  {name}
                </Option>
              );
            })}
          </Select>
          <br />
          <br />
          <h4>Active Language</h4>
          <Row>
            {_.map(languages, (language) => {
              const { id, code, icon, name, enabled } = language;
              const currentActive = defaultLanguage === code;
              return (
                <Col key={id}>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Checkbox
                              disabled={currentActive || noPermissionToEdit}
                              defaultChecked={enabled}
                              onChange={async (e) => {
                                const { checked } = e.target;
                                if (this.state.submitting) return;
                                this.setState({ submitting: true });
                                await updateLanguagesApi(id, { enabled: checked });
                                this.props.actions.getLanguages();
                                this.setState({ submitting: false });
                              }}
                            >
                              <span className={`flag-icon flag-icon-${icon}`} />
                              {' '}
                              {name}
                              {' '}
                            </Checkbox>
                          </td>
                          <td style={{ padding: '0 5px' }}>
                            {!currentActive && (
                              <Icon
                                type="edit"
                                onClick={() => {
                                  this.setState({ id, showModal: true }, () => {
                                    this.props.form.setFieldsValue(language);
                                  });
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          </td>
                          {authHasPermission(_.get(auth, 'claim'), [ `${permissionPrefix}.delete` ]) && (
                            <td style={{ padding: '0 5px' }}>
                              {!currentActive && (
                                <Popconfirm
                                  title="Are you sure delete this task?"
                                  onConfirm={() => this._delete(id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Icon type="delete" style={{ cursor: 'pointer' }} />
                                </Popconfirm>
                              )}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Spin>
    );
  };

  render () {
    const { id, submitting, showModal } = this.state;
    const { title, permissionPrefix, auth } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="setting-language-page">
        <div className="page-header-container">
          <Affix>{this.renderPageHeader()}</Affix>
        </div>
        <div className="main-content-container">{this.renderMainContent()}</div>
        <Modal
          destroyOnClose
          visible={showModal}
          title={`${id === null ? 'New' : 'Edit'} ${title}`}
          footer={null}
          onCancel={() => this.setState({ showModal: false })}
          afterClose={() => this.setState({ id: null })}
        >
          <Form onSubmit={this.handleSubmit}>
            <FormItem label="Code">
              {getFieldDecorator('code', {
                rules: [{ required: true, message: 'Please input Code!' }],
              })(<Input autoFocus />)}
            </FormItem>
            <FormItem label="Name">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please input Name!' }],
              })(<Input />)}
            </FormItem>
            <FormItem
              label="Icon"
              extra={(
                <a href="http://flag-icon-css.lip.is" target="_blank" rel="noopener noreferrer">
                  http://flag-icon-css.lip.is
                </a>
              )}
            >
              {getFieldDecorator('icon')(<Input />)}
            </FormItem>
            <FormItem label="Status">
              {getFieldDecorator('enabled', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Switch />)}
            </FormItem>
            {authHasPermission(_.get(auth, 'claim'), [
              id === null ? `${permissionPrefix}.add` : `${permissionPrefix}.edit`,
            ]) && (
              <FormItem style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  {submitting ? 'Saving...' : 'Save'}
                </Button>
              </FormItem>
            )}
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Form.create()(withRedux(mapStateToProps, actionToProps)(SettingLanguage));
