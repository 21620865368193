import React, { Component } from 'react';
import { PageHeader, Button, Affix, Row, Col, Form, message, Popconfirm } from 'antd';
import _ from 'lodash';
import { InputToFormItem } from '../../../../components';
import { withRedux } from '../../../../hoc';
import { fetchApi, translateData, authHasPermission, mapCategoriesLevel, convertData } from '../../../../utils';
import './style.less';

const mapStateToProps = (state) => ({
  t: _.get(state, 't'),
  languages: _.get(state, 'languages'),
  auth: _.get(state, 'auth.data'),
});

const actionToProps = {};

class LayoutSave extends Component {
  constructor (props) {
    super(props);
    this.state = {
      submitting: false,
      deleting: false,
      formItems: props.formItems,
      item: {},
      items: [],
    };
  }

  componentDidMount () {
    this.init();
  }

  init = async () => {
    await this.fetchItems();
    this.fetchItem();
    this.autoFocusFirstInput();
  };

  fetchItem = async () => {
    const { formItems } = this.state;
    const { t } = this.props;
    const { id } = this.props.match.params;
    if (!id) return;
    const { resource, redirectPath } = this.props;
    const item = await fetchApi(`${resource}/${id}`).then((res) => _.get(res, 'data'));
    if (!item) return this.props.history.push(redirectPath);
    const translateItem = translateData(item, t, formItems);
    this.setState({
      item,
      formItems: _.map(formItems, (formItem) => {
        const { field } = formItem;
        return {
          ...formItem,
          defaultValue: _.get(translateItem, field),
        };
      }),
    });
  };

  fetchItems = async () => {
    const { id } = this.props.match.params;
    const { formItems } = this.state;
    const { t, resource } = this.props;
    const items = await fetchApi(`${resource}`).then((res) => _.get(res, 'data'));
    if (!items) return;
    const translateItems = _.map(items, (item) => translateData(item, t, formItems));
    const filterItems = _.filter(translateItems, (translateItem) => translateItem.id !== _.toInteger(id));
    const categoriesConvetedData = mapCategoriesLevel(filterItems);
    this.setState({
      items,
      formItems: _.map(formItems, (formItem) => {
        const result = formItem;
        if (formItem.field === 'parent_id') {
          result.inputOption = {
            treeData: categoriesConvetedData,
          };
        }
        return result;
      }),
    });
  };

  autoFocusFirstInput = () => {
    setTimeout(() => {
      const firstElement = document.querySelector('.ant-form-item-children');
      if (_.get(firstElement, 'childNodes')) firstElement.childNodes[0].focus();
    }, 200);
  };

  _create = async (data) => {
    const { resource, redirectPath } = this.props;
    const created = await fetchApi(resource, 'POST', JSON.stringify(data));
    if (_.get(created, 'status') !== 'success') {
      message.error(_.get(created, 'message', 'Create failed.'));
      return;
    }
    message.success('Create success.');
    this.props.history.push(redirectPath);
  };

  _update = async (data) => {
    const { id } = this.props.match.params;
    const { resource, redirectPath } = this.props;
    const updated = await fetchApi(`${resource}/${id}`, 'PUT', JSON.stringify(data));
    if (_.get(updated, 'status') !== 'success') {
      message.error(_.get(updated, 'message', 'Update failed.'));
      return;
    }
    message.success('Update success.');
    this.props.history.push(redirectPath);
  };

  _delete = async (id = this.props.match.params.id) => {
    if (this.state.deleting) return;
    this.setState({ deleting: true });
    const { resource, redirectPath } = this.props;
    const deleted = await fetchApi(`${resource}/${id}`, 'DELETE');
    if (_.get(deleted, 'status') !== 'success') {
      message.error(_.get(deleted, 'message', 'Delete failed.'));
    } else {
      message.success('Delete success.');
      return this.props.history.push(redirectPath);
    }
    this.setState({ deleting: false });
  };

  submit = async (data) => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    const { id } = this.props.match.params;
    if (id) {
      await this._update(data);
    } else {
      await this._create(data);
    }
    this.setState({ submitting: false });
  };

  convertDataToSubmit = (values) => {
    const { formItems, item } = this.state;
    const { enableModule, languages } = this.props;
    const convetedData = convertData(item, values, formItems, languages);
    if (_.get(enableModule, 'tags') === true) {
      convetedData.tags = JSON.stringify(convetedData.tags);
    }
    return convetedData;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const options = {
      scroll: {
        offsetTop: 96,
      },
    };
    this.props.form.validateFieldsAndScroll(options, (err, values) => {
      if (!err) {
        const data = this.convertDataToSubmit(values);
        // console.log('Received values of form: ', values, data);
        this.submit(data);
      }
    });
  };

  renderPublishTime = () => {
    const { item } = this.state;
    const { form } = this.props;
    return (
      <Row gutter={8}>
        <Col lg={12}>
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'publish_start_time',
                label: 'Publish Start Time',
                inputType: 'date-picker',
                multiLanguage: false,
                defaultValue: _.get(item, 'publish_start_time'),
              },
            ]}
          />
        </Col>
        <Col lg={12}>
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'publish_end_time',
                label: 'Publish End Time',
                inputType: 'date-picker',
                multiLanguage: false,
                defaultValue: _.get(item, 'publish_end_time'),
              },
            ]}
          />
        </Col>
      </Row>
    );
  };

  renderPageHeader = () => {
    const { submitting, deleting } = this.state;
    const { title, redirectPath, permissionPrefix, auth } = this.props;
    const { id } = this.props.match.params;
    const extra = [];
    if (id) {
      if (authHasPermission(_.get(auth, 'claim'), [ `${permissionPrefix}.delete` ])) {
        extra.push(
          <Popconfirm
            key="delete"
            title="Are you sure delete this task?"
            onConfirm={() => this._delete()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" loading={deleting}>
              {deleting ? 'Deleting...' : 'Delete'}
            </Button>
          </Popconfirm>,
        );
      }
    }
    if (authHasPermission(_.get(auth, 'claim'), [ id ? `${permissionPrefix}.edit` : `${permissionPrefix}.add` ])) {
      extra.push(
        <Button key="save" type="primary" htmlType="submit" loading={submitting}>
          {submitting ? 'Saving...' : 'Save'}
        </Button>,
      );
    }
    const propsPageHeader = {
      title: `${id ? 'Edit' : 'New'} ${title}`,
      extra,
    };
    propsPageHeader.onBack = () => this.props.history.push(redirectPath);
    return (
      <div className="page-header">
        <PageHeader {...propsPageHeader} />
      </div>
    );
  };

  renderMainContent = () => {
    const { formItems, item } = this.state;
    const { form } = this.props;
    return (
      <div className="main-content">
        {((this.props.match.params.id && !_.isEmpty(item)) || !this.props.match.params.id) && (
          <InputToFormItem form={form} formItems={formItems} />
        )}
      </div>
    );
  };

  renderRightBar = () => {
    const { item } = this.state;
    const { form, enableModule } = this.props;
    const { getFieldsValue } = form;
    const formData = getFieldsValue();
    const publishedFormData = _.get(formData, 'published');
    return (
      <div className="right-bar">
        {_.get(enableModule, 'publish') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'published',
                label: 'Publish',
                inputType: 'switch',
                multiLanguage: false,
                defaultValue: _.get(item, 'published', true),
              },
            ]}
          />
        )}
        {_.get(enableModule, 'publish') === true
          && _.get(enableModule, 'publishTime') === true
          && (!_.has(formData, 'published') || (_.has(formData, 'published') && publishedFormData === true))
          && this.renderPublishTime()}
        {_.get(enableModule, 'tags') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'tags',
                label: 'Tags',
                inputType: 'select',
                inputOption: {
                  mode: 'tags',
                },
                multiLanguage: false,
                defaultValue: _.isEmpty(_.get(item, 'tags')) ? [] : _.get(item, 'tags', []),
              },
            ]}
          />
        )}
        {_.get(enableModule, 'recommend') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'recommended',
                label: 'Recommended',
                inputType: 'switch',
                multiLanguage: false,
                defaultValue: _.get(item, 'recommended', false),
              },
            ]}
          />
        )}
        {_.get(enableModule, 'sort') === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: 'sort',
                label: 'Sort',
                inputType: 'number',
                multiLanguage: false,
                defaultValue: _.get(item, 'sort', 0),
              },
            ]}
          />
        )}
      </div>
    );
  };

  render () {
    return (
      <div className="layout-save-page">
        <Form onSubmit={this.handleSubmit}>
          <div className="page-header-container">
            <Affix>{this.renderPageHeader()}</Affix>
          </div>
          <Row>
            <Col md={16}>
              <div className="main-content-container">{this.renderMainContent()}</div>
            </Col>
            <Col md={8}>
              <div className="right-bar-container">
                <Affix offsetTop={73}>{this.renderRightBar()}</Affix>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create()(withRedux(mapStateToProps, actionToProps)(LayoutSave));
