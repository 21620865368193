import _ from 'lodash';
import { fetchApi, toObject } from '../utils';

const mapFieldMedia = (item) => ({
  ...item,
});

const getMediasApi = () => fetchApi('medias')
  .then((res) => _.get(res, 'data'))
  .then((items) => _.map(items, mapFieldMedia))
  .then(toObject);

const getMediasByIdApi = (id) => fetchApi(`medias/${id}`);

const createMediasApi = (file) => {
  const data = new FormData();
  data.append('file', file);
  return fetchApi('medias', 'POST', data);
};

const updateMediasApi = (id, data) => fetchApi(`medias/${id}`, 'PUT', JSON.stringify(data));

const deleteMediasApi = (id) => fetchApi(`medias/${id}`, 'DELETE');

export {
  getMediasApi,
  getMediasByIdApi,
  createMediasApi,
  updateMediasApi,
  deleteMediasApi,
};
